import { useParams } from 'react-router'
import { useSelector } from 'react-redux'
import { selectSiteEntity, FTState as SiteType } from '../../../ducks/sites'
import { isValueSet } from '../../../utils'
import numeral from 'numeral'
import VerticalTable from '../../VerticalTable'

const Billing = () => {
  const { siteId } = useParams<{ siteId: string }>()

  const siteEntity = useSelector((state: SiteType) =>
    selectSiteEntity(state, siteId),
  )
  const { item: site } = siteEntity
  let fields: {
    label: string
    value: string | number
    description: string
    editable: boolean
  }[] = []

  if (site) {
    const { contracts = [] } = site
    const contract = contracts[0] || {}
    fields = [
      {
        label: 'Energy Commitment',
        value:
          isValueSet(contract.energyCommitment) ?
            numeral(contract.energyCommitment).format('0,0')
          : '',
        description: 'The value of the contract commitment, in kWh',
        editable: false,
      },
      {
        label: 'Monthly Block',
        value:
          isValueSet(contract.monthlyBlock) ?
            numeral(contract.monthlyBlock).format('0,0')
          : '',
        description: 'Expected monthly savings, in kWh',
        editable: false,
      },
      {
        label: 'Energy Rate',
        value: isValueSet(contract.energyRate) ? contract.energyRate : '',
        description: 'Redaptive invoice rate, in $/kWh',
        editable: false,
      },
      {
        label: 'Utility Rate',
        value: isValueSet(contract.utilityRate) ? contract.utilityRate : '',
        description: 'Rate charged by the utility, in $/kWh',
        editable: false,
      },
      {
        label: 'Site Square Footage',
        description: 'Total area of site, in square feet',
        value:
          isValueSet(site.squareFootage) ?
            `${numeral(site.squareFootage).format('0,0')} SqFt`
          : '',
        editable: false,
      },
    ]
  }

  return <VerticalTable.Basic fields={fields} labelsInline={false} />
}

export default Billing
