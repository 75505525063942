import styled from 'styled-components'
import { InputComponent } from '../Input'

export const ButtonGroup = styled.div`
  margin-top: 20px;
  height: 150px;
`
export const FormMeta = styled.div`
  height: 18px;
  line-height: 18px;
  font-size: 12px;
  margin-bottom: 0px;
`
export const PlainCellStyles = styled.div`
  padding: 0 10px;
`
export const ExpandedMeterChannelStyles = styled(PlainCellStyles)`
  font-size: 16px;
  padding: 0 10px 0 20px;
`
export const ExpandedMeterChannelLabelStyles = styled.span`
  display: inline-block;
  font-weight: 600;
  padding-right: 10px;
`
export const TableStyles = styled.div`
  .ReactTable {
    .rt-tbody .rt-tr-group.rt-tr-group-expanded {
      margin: 12px 0;

      &:first-child {
        margin-top: 0;
      }

      + .rt-tr-group-expanded {
        margin-top: 0;
      }
    }

    &.-highlight .rt-tbody {
      .rt-tr-group-expanded {
        > .rt-tr,
        > .rt-tr:not(.-padRow):hover {
          background: #f7f7f7;
        }
      }
    }

    .rt-tbody,
    .rt-table,
    .rt-td {
      overflow: visible;
    }

    .rt-td {
      align-self: center;
      padding: 0;
    }
  }

  .rt-td {
    ${InputComponent} {
      width: 100%;
    }
  }

  .rt-tr-group-expanded {
    > .rt-tr > .rt-td {
      display: flex;
      align-items: center;
      height: 55px;

      &.rt-expandable {
        justify-content: center;
      }
    }
  }
`
export const MeteredUpstreamSelectorStyled = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #337ab7;

  span {
    cursor: pointer;
  }
`
export const MeteredUpstreamInfoStyled = styled.span`
  display: flex;
  align-items: center;
`
export const MeteredUpstreamHelpContentStyled = styled.span`
  display: flex;
  flex-direction: column;
  padding: 10px;
  div {
    text-align: center;
  }
`
export const InputWrapper = styled.div`
  position: relative;
`

export const SpinnerWrapper = styled.span`
  position: absolute;
  right: 0;
  top: 10px;
`
