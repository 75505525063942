import axios from 'axios'
import { consoleBaseUrl, defaultHeaders } from '../../api'
import { SubmissionRequestPayload, SubmitSubmissionsPayload } from './types'
import { handleAxiosError } from '../../api/utils'
import { TSEnergyStarSubmissions } from '../../ducks/energyStarIntegration/types'

const axiosControllers: Record<string, AbortController> = {}

const cancelAndCreateNewController = (methodName: string) => {
  if (axiosControllers[methodName]) {
    axiosControllers[methodName].abort()
    delete axiosControllers[methodName]
  }
  const controller = new AbortController()
  axiosControllers[methodName] = controller
  return axiosControllers[methodName]
}

const energyStarApis = {
  fetchSubmissions: (reqData: SubmissionRequestPayload) => {
    const controller = cancelAndCreateNewController('fetchSubmissions')
    const url = `${consoleBaseUrl()}/api/energy-star/submissions/v2`
    return axios
      .get<TSEnergyStarSubmissions[]>(url, {
        headers: defaultHeaders(),
        signal: controller.signal,
        params: reqData,
      })
      .then(({ data }) => data)
      .catch(handleAxiosError)
  },
  uploadSubmissions: (reqData: SubmitSubmissionsPayload): Promise<void> => {
    const url = `${consoleBaseUrl()}/api/energy-star/submissions/upload`
    return axios
      .post<void>(url, reqData, {
        headers: defaultHeaders(),
      })
      .then(({ data }) => data)
      .catch(handleAxiosError)
  },
  reSyncSubmissions: (reqData: SubmitSubmissionsPayload): Promise<void> => {
    const url = `${consoleBaseUrl()}/api/energy-star/submissions/resync`
    return axios
      .post<void>(url, reqData, {
        headers: defaultHeaders(),
      })
      .then(({ data }) => data)
      .catch(handleAxiosError)
  },
}

export default energyStarApis
