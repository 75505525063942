/* eslint-disable no-unused-vars */

/* eslint-disable react/jsx-props-no-spreading */
import Tippy from '@tippyjs/react'
import moment from 'moment'
import React, { useCallback, useMemo } from 'react'
import { BiGroup } from 'react-icons/bi'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { utils, writeFile } from 'xlsx'

import MultiSelectFilter from './components/MultiSelectFilter'
import {
  ActionButtonStyled,
  ActionIconStyled,
  ActionsCellWrapperStyled,
  ActionsColumnStyles,
  ActionsContainerStyled,
  ActionsPopupFullScreenBgAlphaLayer,
  ActionsPopupMenuStyled,
  ActivityHeaderStyled,
  BillingThisMonthMainPageStyled,
  CellWrapperStyled,
  CheckCircleIconStyled,
  CircleOutlineIconStyled,
  CommentsIconGrayStyled,
  CommentsIconStyled,
  ConfirmationScreen,
  ConfirmationTitleStyled,
  CustomerHeaderTopStyles,
  DownloadButton,
  DownloadDropDownWrapper,
  ExpanderCellStyled,
  FilterCardResultsWrapperStyled,
  FilterCardStyled,
  FilterCardsWrapperStyled,
  FilterCardTotalStyled,
  FilterCardTotalTextStyled,
  GradingIconStyled,
  GradingRedIconStyled,
  HeaderStyled,
  LoadingSpinnerWrapperStyled,
  MainContentStyled,
  MonthPickerWrapperStyles,
  NoDataTextWrapper,
  OpportunityNameCellStyled,
  PageHeaderDropdownActionsStyled,
  PercentageWrapperStyled,
  PerformaceVerifiedTitleStyled,
  PerformancePercentageStyled,
  PerformanceWrapperStyled,
  QuantityStyles,
  RadioButtonCheckedIconStyled,
  RefreshIconStyled,
  SendIconStyled,
  TabCheckedStyled,
  TabCountStyled,
  TableWrapperStyled,
  TdStyledStyled,
  VerticalEllipsisStyled,
} from './styles'
import { FTFilterCard, FTProps, FTTabInfo } from './types'
import FeatureValidator from '../../../authorization/components/FeatureValidator'
import AuthorizedFeatures from '../../../authorization/features'
import ActionPaneView from '../../../components/ActionPaneView'
import Button2 from '../../../components/Button2'
import Checkbox from '../../../components/Checkbox'
import CommentsPopup from '../../../components/CommentsPopup'
import DropdownMenuNew from '../../../components/DropdownMenuNew'
import CheckIcon from '../../../components/Icons/CheckIcon'
import FileDownloadIcon from '../../../components/Icons/FileDownloadIcon'
import InsertDriveFileIcon from '../../../components/Icons/InsertDriveFileIcon'
import OutlinedFlagIcon from '../../../components/Icons/OutlinedFlagIcon'
import RefreshIcon from '../../../components/Icons/RefreshIcon'
import ChevronDown from '../../../components/Icons/svg/ChevronDown'
import ChevronUp from '../../../components/Icons/svg/ChevronUp'
import IndicatorCard from '../../../components/IndicatorCard'
import MonthPicker from '../../../components/MonthPicker'
import PageHeader from '../../../components/PageHeader'
import RedaptiveReactTable7, {
  DefaultColumnFilter,
} from '../../../components/RedaptiveReactTable7'
import type { FTTableHeaderRow } from '../../../components/RedaptiveReactTable7/TableHead'
import TableHead from '../../../components/RedaptiveReactTable7/TableHead'
import Spinner from '../../../components/Spinner'
import type { FTTab } from '../../../components/Tabs/TabPane2'
import TabPane2 from '../../../components/Tabs/TabPane2'
import type { FTBillingItemAction } from '../../../ducks/billing/billingThisMonth/billingItems'
import {
  actions as billingItemsActions,
  selectBillingThisMonthItemsEntity,
} from '../../../ducks/billing/billingThisMonth/billingItems'
import {
  actions as itemCommentsActions,
  selectBillingThisMonthItemComments,
} from '../../../ducks/billing/billingThisMonth/comments'
import { actions as messagesActions } from '../../../ducks/messages'
import { actions as modalActions } from '../../../ducks/modal'
import { naturallySortEmptyLastCaseInsensitive } from '../../../utils'
import { colors } from '../../../utils/themes'
// eslint-disable-next-line no-unused-vars
import { BILLING_COMMENTS_SHEET_HEADERS } from '../constants/fields'
import {
  BILLING_METRIC,
  CALCULATED_ACTUAL,
  CONTRACT_ESTIMATE,
} from '../constants/labels'
import defaultMappingsForTabs from '../helpers/defaultFieldMappingsForTabs'
import {
  alwaysLockedColumns,
  approvalTabColumns,
  filterCards,
  getPercentageTextColor,
  initialHiddenColumns as defaultHiddenColumns,
  initialSort,
  investigationTabColumns,
  investigationVerificationDefaultSort,
  isSubset,
  opportunitySummaryTabColumns,
  sentToNetsuiteTabColumns,
  skipCSVColumns,
  tabs,
  verificationTabColumns,
} from '../helpers/utils'

const CommentsHeader = () => (
  <ActivityHeaderStyled>
    <CommentsIconGrayStyled />
    Activity
  </ActivityHeaderStyled>
)

const toCamelCase = (str = '') =>
  str
    .replace(/[^a-z0-9]/gi, ' ')
    .toLowerCase()
    .split(' ')
    .map((el, ind) =>
      ind === 0 ? el : el[0].toUpperCase() + el.substring(1, el.length),
    )
    .join('')

const BillingThisMonthMainPage = (props: FTProps) => {
  const {
    actions,
    billingItems,
    billingItemsLoading,
    billingItemsError,
    billingItemsSuccess,
    billingItemComments,
    billingItemCommentsLoading,
    reCalculateSavingsLoading,
    reCalculateSavingsError,
    sendToInvestigationLoading,
    sendToInvestigationError,
    sendToVerificationLoading,
    sendToVerificationError,
    sendToReviewBulkLoading,
    sendToReviewBulkError,
    sendToReviewLoading,
    sendToReviewError,
    sendToNetsuiteLoading,
    sendToNetsuiteError,
    resendToVerificationLoading,
    resendToVerificationError,
    history,
    location: { pathname },
    match: { path, url },
    permissions,
    billingComments,
  } = props
  const activeTab = pathname.split('/')[3] || 'opportunity-summary'
  const activeTabInCamelCase = React.useMemo(
    () => toCamelCase(activeTab),
    [activeTab],
  )
  const [fieldMappings, setFieldMappings] = React.useState({
    opportuitySummary: [],
    underInvestigation: [],
    performanceVerification: [],
    invoiceApproval: [],
    sentToNetsuite: [],
  })
  const initialLockedColumns = React.useMemo(
    () =>
      (
        fieldMappings && fieldMappings[activeTabInCamelCase]?.length !== 0 // $FlowFixMe
      ) ?
        fieldMappings[activeTabInCamelCase]
          ?.filter((column) => column.isLocked === true)
          .map((column) => column.fieldName)
      : alwaysLockedColumns,
    [fieldMappings, activeTab],
  )
  const initialHiddenColumns = React.useMemo(
    () =>
      (
        fieldMappings && fieldMappings[activeTabInCamelCase]?.length !== 0 // $FlowFixMe
      ) ?
        fieldMappings[activeTabInCamelCase]
          ?.filter((column) => column.isVisible !== true)
          .map((column) => column.fieldName)
      : defaultHiddenColumns,
    [fieldMappings, activeTab],
  )
  const activeTabText =
    tabs.find((tab: FTTabInfo) => tab.tabId === activeTab)?.text || ''
  const isInvestigationVerificationTab =
    activeTab === 'under-investigation' ||
    activeTab === 'performance-verification'
  const isSummaryTab = activeTab === 'opportunity-summary'
  const [selectedCategories, setSelectedCategories] = React.useState([])
  const [selectedIssueOwners, setSelectedIssueOwners] = React.useState([])
  const [selectedInvoiceStatus, setSelectedInvoiceStatus] = React.useState([])
  const [selectedBillingMetric, setSelectedBillingMetric] = React.useState([])
  const [reRunSavingsSubmitted, setReRunSavingsSubmitted] =
    React.useState(false)
  const [sendToInvestigationSubmitted, setSendToInvestigationSubmitted] =
    React.useState(false)
  const [sendToVerificationSubmitted, setSendToVerificationSubmitted] =
    React.useState(false)
  const [sendToReviewSubmitted, setSendToReviewSubmitted] =
    React.useState(false)
  const [sendToReviewBulkSubmitted, setSendToReviewBulkSubmitted] =
    React.useState(false)
  const [sendToNetsuiteSubmitted, setSendToNetsuiteSubmitted] =
    React.useState(false)
  const [resendToVerificationSubmitted, setResendToVerificationSubmitted] =
    React.useState(false)
  const [
    billOnRemainingQuantitySubmitted,
    setBillOnRemainingQuantitySubmitted,
  ] = React.useState(false)
  const [toggleHeaderActionDropdown, setToggleHeaderActionDropdown] =
    React.useState(false)
  const [actionsPopupId, setActionsPopupId] = React.useState('')
  const [commentsPopupId, setCommentsPopupId] = React.useState('')
  const [selectedRows, setSelectedRows] = React.useState({})
  const [sortCustomerDesc, setSortCustomerDesc] = React.useState(false)
  const [sort, setSort] = React.useState(initialSort)
  const [customerFilter, setCustomerFilter] = React.useState('')
  const [opportunityNameFilter, setOpportunityNameFilter] = React.useState('')
  const [estimatedFilter, setEstimatedFilterName] = React.useState('')
  const [actualFilter, setActualFilterName] = React.useState('')
  const [opportunityIdFilter, setOpportunityIdFilter] = React.useState('')
  const [itemsChecked, setItemsChecked] = React.useState([])
  const [hiddenColumns, setHiddenColumns] = React.useState(initialHiddenColumns)
  const [hiddenColumnsForSummaryTab, setHiddenColumnsForSummaryTab] =
    React.useState(initialHiddenColumns)
  const [columnsOrder, setColumnsOrder] = React.useState([])
  const [currentActiveMonth, setCurrentActiveMonth] = React.useState('')
  const [selectedCustomerInternalId, setSelectedCustomerInternalId] =
    React.useState('')
  const [ellipsisClickedPosition, setEllipsisClickedPosition] = React.useState(
    {},
  )
  const [activityIconClickedPosition, setActivityIconClickedPosition] =
    React.useState({})
  const [lockedColumns, setLockedColumns] = React.useState([])
  const [alphaLayerOpen, setAlphaLayerOpen] = React.useState(false)
  const selectedRowKeys = Object.keys(selectedRows)
  const tableInstanceRef = React.useRef()
  const summarytableInstanceRef = React.useRef()
  const actionsPopupMenuRef = React.useRef()
  const [showMonthPickerDropDown, setShowMonthPickerDropDown] =
    React.useState(false)
  const [historicalBillingMonth, setHistoricalBillingMonth] = React.useState(
    moment().subtract(1, 'months'),
  )
  const [billingDataMonth, setBillingDataMonth] = React.useState('')
  const [isMVGroupSelected, setIsMVGroupSelected] = React.useState(false)
  const [isBillingRunningView, setIsBillingRunView] = React.useState(false)
  const [isWithinHistoricBillingLimit, setIsWithinHistoricBillingLimit] =
    React.useState(true)

  const fetchBillingData = (selectedMonth = moment().subtract(1, 'months')) => {
    const minLimitForAllowingBilling = moment().subtract(4, 'months')
    const maxLimitForAllowingBilling = moment().subtract(1, 'months')
    setHistoricalBillingMonth(selectedMonth)
    const loadDataForMonth = moment(selectedMonth).format('YYYY-MM')
    const loadCommentsDataForMonth = moment(selectedMonth)
      .add(1, 'M')
      .format('YYYY-MM')
    if (
      !moment(loadDataForMonth).isBetween(
        minLimitForAllowingBilling.format('YYYY-MM'),
        maxLimitForAllowingBilling.format('YYYY-MM'),
        undefined,
        '[]',
      )
    ) {
      setIsWithinHistoricBillingLimit(false)
    } else {
      setIsWithinHistoricBillingLimit(true)
    }
    setBillingDataMonth(loadDataForMonth)
    actions.fetchBillingItems({
      savingsMonth: loadDataForMonth,
    })
    actions.fetchBillingComments({
      savingsMonth: loadCommentsDataForMonth,
    })
  }

  const hideMonthPickerDropDown = () => {
    setShowMonthPickerDropDown(false)
  }

  const onCancelHistoricalBilling = () => {
    hideMonthPickerDropDown()
  }

  const onSubmitHistoricalBilling = (month) => {
    fetchBillingData(month)
    hideMonthPickerDropDown()
  }

  const MonthPickerComponent = (
    <MonthPickerWrapperStyles>
      <MonthPicker
        activeMonth={historicalBillingMonth}
        showMonthPickerDropDown={showMonthPickerDropDown}
        setShowMonthPickerDropDown={setShowMonthPickerDropDown}
        onCancel={onCancelHistoricalBilling}
        onSubmit={onSubmitHistoricalBilling}
      />
    </MonthPickerWrapperStyles>
  )
  React.useEffect(() => {
    fetchBillingData()
    return () => {
      // $FlowFixMe
      document.body.style.overflow = 'unset'
    }
  }, [])

  const getActionsPopupHeight = () => {
    switch (actionsPopupMenuRef.current?.childElementCount) {
      /* these values are the height of the menu in UI.
       * Out of the total children of Actions menu, one child is alpha background layer
       */
      case 2:
        return 76

      case 3:
        return 120

      case 4:
        return 164

      default:
        return 120
    }
  }

  const validateMappings = React.useCallback(
    (columns) => {
      const localMappings = fieldMappings[activeTabInCamelCase]
      // $FlowFixMe
      return columns // $FlowFixMe
        ?.filter(
          (column) =>
            localMappings?.map((e) => e.fieldName).includes(column.accessor),
        )
        ?.sort((a, b) => {
          const aIdx = localMappings.findIndex(
            (e) => e.fieldName === a.accessor,
          )
          const bIdx = localMappings.findIndex(
            (e) => e.fieldName === b.accessor,
          )
          if (
            localMappings[aIdx].sequenceNumber <
            localMappings[bIdx].sequenceNumber
          )
            return -1
          if (
            localMappings[aIdx].sequenceNumber >
            localMappings[bIdx].sequenceNumber
          )
            return 1
          return 0
        })
    },
    [activeTab, fieldMappings],
  )
  React.useEffect(() => {
    if (commentsPopupId) {
      actions.fetchComments({
        billingItemId: commentsPopupId,
      })
    }
  }, [commentsPopupId])
  React.useMemo(() => {
    const getMappings = localStorage.getItem(activeTabInCamelCase)
    const mappingsFromStorage = getMappings ? JSON.parse(getMappings) : ''

    if (mappingsFromStorage?.length > 0) {
      setFieldMappings({
        ...fieldMappings,
        [activeTabInCamelCase]: mappingsFromStorage,
      })
    } else {
      const opportunitySummaryNew = [
        ...defaultMappingsForTabs?.opportunitySummary,
        {
          fieldName: 'status',
          isVisible: true,
          isLocked: false,
          sequenceNumber: 9,
        },
      ]
      setFieldMappings({
        ...defaultMappingsForTabs,
        opportunitySummary: opportunitySummaryNew,
      })
    }
  }, [activeTab])
  const handleColumnSettingsClose = React.useCallback(
    ({
      columns: columnsNew,
      lockedColumns: lockedColumnsNew,
      columnsVisibleOnTable,
    }) => {
      const fieldMappingsNew = columnsNew.map((columnEntry, index) => ({
        fieldName: columnEntry,
        isLocked: lockedColumnsNew.includes(columnEntry),
        isVisible: columnsVisibleOnTable.includes(columnEntry),
        sequenceNumber: index,
      }))
      localStorage.setItem(
        activeTabInCamelCase,
        JSON.stringify(fieldMappingsNew),
      )
    },
    [activeTab],
  )
  React.useEffect(() => {
    setSelectedRows({})
    setToggleHeaderActionDropdown(false)
    setSelectedCategories([])
    setSelectedIssueOwners([])
    setSelectedInvoiceStatus([])
    setSelectedBillingMetric([])
    setSortCustomerDesc(false)
    setCustomerFilter('')
    setOpportunityNameFilter('')
    setActualFilterName('')
    setEstimatedFilterName('')
    setOpportunityIdFilter('')
    setItemsChecked([])
    setSort(initialSort)
    setCurrentActiveMonth(moment().format('YYYY-MM'))
    setSelectedCustomerInternalId('')

    if (isSummaryTab) {
      setHiddenColumnsForSummaryTab(hiddenColumnsForSummaryTab)
    }

    if (activeTab === 'sent-to-netsuite') {
      // $FlowFixMe
      const updateHiddenColumns =
        hiddenColumns ||
        hiddenColumns?.filter((column) => column !== 'billingStructure')
      setHiddenColumns(updateHiddenColumns)
    } else {
      setHiddenColumns(hiddenColumns)
    }
  }, [activeTab])
  React.useEffect(() => {
    if (reRunSavingsSubmitted) {
      if (!reCalculateSavingsLoading && !reCalculateSavingsError) {
        actions.hideModal()
        actions.showMessage({
          messageId: 'reRunSavingsSubmitted',
          title:
            isMVGroupSelected ?
              `Savings have been recalculated for the selected M&V Group/s.
           Refresh your screen in few moments to see the new savings values
          `
            : `Savings have been recalculated.
           Refresh your screen in few moments to see the new savings values`,
          type: 'success',
          position: 'fixed',
        })
        setReRunSavingsSubmitted(!reRunSavingsSubmitted)
        setTimeout(() => actions.hideMessage('reRunSavingsSubmitted'), 10000)
      }

      if (reCalculateSavingsError) {
        actions.hideModal()
        actions.showMessage({
          messageId: 'reRunSavingsError',
          title: 'Error in recalculating savings.',
          type: 'error',
          position: 'fixed',
        })
        setReRunSavingsSubmitted(!reRunSavingsSubmitted)
        setTimeout(() => actions.hideMessage('reRunSavingsError'), 10000)
      }
    }
  }, [
    reRunSavingsSubmitted,
    reCalculateSavingsLoading,
    reCalculateSavingsError,
  ])
  React.useEffect(() => {
    if (sendToVerificationSubmitted) {
      if (!sendToVerificationLoading && !sendToVerificationError) {
        actions.hideModal()
        setSelectedRows({})
        actions.showMessage({
          messageId: 'sendToVerificationSubmitted',
          title:
            'Opportunity was successfully  moved to Performance Verification',
          type: 'success',
          position: 'fixed',
        })
        setTimeout(
          () => actions.hideMessage('sendToVerificationSubmitted'),
          10000,
        )
        setSendToVerificationSubmitted(false)
      }

      if (sendToVerificationError) {
        actions.hideModal()
        actions.showMessage({
          messageId: 'sendToVerificationError',
          title: 'Error moving site to Verification.',
          type: 'error',
          position: 'fixed',
        })
        setTimeout(() => actions.hideMessage('sendToVerificationError'), 10000)
        setSendToVerificationSubmitted(false)
      }
    }
  }, [
    sendToVerificationSubmitted,
    sendToVerificationLoading,
    sendToVerificationError,
  ])
  React.useEffect(() => {
    if (sendToInvestigationSubmitted) {
      if (!sendToInvestigationLoading && !sendToInvestigationError) {
        actions.hideModal()
        history.push(`${path}/under-investigation`)
        fetchBillingData()
        actions.showMessage({
          messageId: 'sendToInvestigationSubmitted',
          title: 'Site has been moved to Investigation',
          type: 'success',
          position: 'fixed',
        })
        setTimeout(
          () => actions.hideMessage('sendToInvestigationSubmitted'),
          10000,
        )
        setSendToInvestigationSubmitted(false)
      }

      if (sendToInvestigationError) {
        actions.hideModal()
        actions.showMessage({
          messageId: 'sendToInvestigationError',
          title: 'Error moving site to Investigation.',
          type: 'error',
          position: 'fixed',
        })
        setTimeout(() => actions.hideMessage('sendToInvestigationError'), 10000)
        setSendToInvestigationSubmitted(false)
      }
    }
  }, [
    sendToInvestigationSubmitted,
    sendToInvestigationLoading,
    sendToInvestigationError,
  ])
  React.useEffect(() => {
    if (sendToReviewSubmitted) {
      if (!sendToReviewLoading && !sendToReviewError) {
        actions.hideModal()
        history.push(`${path}/invoice-approval`)
        actions.showMessage({
          messageId: 'sendToReviewSubmitted',
          title:
            'Opportunity was successfully billed and moved to Invoice Approval',
          type: 'success',
          position: 'fixed',
        })
        setTimeout(() => actions.hideMessage('sendToReviewSubmitted'), 10000)
        setSendToReviewSubmitted(false)
      }

      if (sendToReviewError) {
        actions.hideModal()
        actions.showMessage({
          messageId: 'sendToReviewError',
          title: 'Error moving site to Approval.',
          type: 'error',
          position: 'fixed',
        })
        setTimeout(() => actions.hideMessage('sendToReviewError'), 10000)
        setSendToReviewSubmitted(false)
      }
    }
  }, [sendToReviewSubmitted, sendToReviewLoading, sendToReviewError])
  React.useEffect(() => {
    if (billOnRemainingQuantitySubmitted) {
      setBillOnRemainingQuantitySubmitted(false)
    }

    actions.hideModal()
  }, [billOnRemainingQuantitySubmitted])
  React.useEffect(() => {
    if (sendToReviewBulkSubmitted) {
      if (!sendToReviewBulkLoading && !sendToReviewBulkError) {
        actions.hideModal()
        history.push(`${path}/invoice-approval`)
        actions.showMessage({
          messageId: 'sendToReviewBulkSubmitted',
          title:
            'Opportunities were successfully billed and moved to Invoice Approval',
          type: 'success',
          position: 'fixed',
        })
        setTimeout(
          () => actions.hideMessage('sendToReviewBulkSubmitted'),
          10000,
        )
        setSendToReviewBulkSubmitted(false)
      }

      if (sendToReviewBulkError) {
        actions.hideModal()
        actions.showMessage({
          messageId: 'sendToReviewBulkError',
          title: 'Error moving site to Approval.',
          type: 'error',
          position: 'fixed',
        })
        setTimeout(() => actions.hideMessage('sendToReviewBulkError'), 10000)
        setSendToReviewBulkSubmitted(false)
      }
    }
  }, [
    sendToReviewBulkSubmitted,
    sendToReviewBulkLoading,
    sendToReviewBulkError,
  ])
  React.useEffect(() => {
    if (sendToNetsuiteSubmitted) {
      if (!sendToNetsuiteLoading && !sendToNetsuiteError) {
        actions.hideModal()
        history.push(`${path}/sent-to-netsuite`)
        actions.showMessage({
          messageId: 'sendToNetsuiteSubmitted',
          title: 'Opportunity(ies) successfully sent to Netsuite',
          type: 'success',
          position: 'fixed',
        })
        setTimeout(() => actions.hideMessage('sendToNetsuiteSubmitted'), 10000)
        setSendToNetsuiteSubmitted(false)
      }

      if (sendToNetsuiteError) {
        actions.hideModal()
        actions.showMessage({
          messageId: 'sendToNetsuiteError',
          title: 'Error sending site(s) to Netsuite.',
          type: 'error',
          position: 'fixed',
        })
        setTimeout(() => actions.hideMessage('sendToNetsuiteError'), 10000)
        setSendToNetsuiteSubmitted(false)
      }
    }
  }, [sendToNetsuiteSubmitted, sendToNetsuiteError, sendToNetsuiteLoading])
  React.useEffect(() => {
    if (resendToVerificationSubmitted) {
      if (!resendToVerificationLoading && !resendToVerificationError) {
        actions.hideModal()
        history.push(`${path}/performance-verification`)
        actions.showMessage({
          messageId: 'resendToVerificationSubmitted',
          title: 'Opportunity(ies) successfully moved back to Verification',
          type: 'success',
          position: 'fixed',
        })
        setTimeout(
          () => actions.hideMessage('resendToVerificationSubmitted'),
          10000,
        )
        setResendToVerificationSubmitted(false)
      }

      if (resendToVerificationError) {
        actions.hideModal()
        actions.showMessage({
          messageId: 'resendToVerificationError',
          title: 'Error sending site(s) back to Verification.',
          type: 'error',
          position: 'fixed',
        })
        setTimeout(
          () => actions.hideMessage('resendToVerificationError'),
          10000,
        )
        setResendToVerificationSubmitted(false)
      }
    }
  }, [
    resendToVerificationSubmitted,
    resendToVerificationLoading,
    resendToVerificationError,
  ])

  const hideActionsPopup = () => {
    setActionsPopupId('')
    setAlphaLayerOpen(false)
    // $FlowFixMe
    document.body.style.overflow = 'unset'
  }

  const onClickActionsPopupBg = () => {
    hideActionsPopup()
    // $FlowFixMe
    document.body.style.overflow = 'unset'
  }

  const onClickActivityIcon = (evt, id) => {
    setActivityIconClickedPosition({
      x: evt.clientX,
      y: evt.clientY,
      height: window.innerHeight,
    })
    setCommentsPopupId(commentsPopupId !== id ? id : '')
    setAlphaLayerOpen(true)
    // $FlowFixMe
    document.body.style.overflow = 'hidden'
  }

  const hideActivityLogPopup = () => {
    setCommentsPopupId('')
    setAlphaLayerOpen(false)
    // $FlowFixMe
    document.body.style.overflow = 'unset'
  }

  const onClickEllipsisMenu = (evt, id) => {
    setEllipsisClickedPosition({
      x: evt.clientX,
      y: evt.clientY,
      innerHeight: window.innerHeight,
    })
    setActionsPopupId(actionsPopupId !== id ? id : '')
    setAlphaLayerOpen(true)
    // $FlowFixMe
    document.body.style.overflow = 'hidden'
  }

  const filteredByTab = React.useMemo(() => {
    const tabsResults = {
      'opportunity-summary': {
        total: 0,
        items: [],
      },
      'under-investigation': {
        total: 0,
        items: [],
      },
      'performance-verification': {
        total: 0,
        items: [],
      },
      'invoice-approval': {
        total: 0,
        items: [],
      },
      'sent-to-netsuite': {
        total: 0,
        items: [],
      },
    }
    billingItems.forEach((item) => {
      const isUnderInvestigation = item.pendingInvestigation
      const isPerformanceVerification = !isUnderInvestigation && !item.reviewed
      const isInvoiceApproval = item.reviewed && !item.approved
      const isSentToNetsuite =
        !isUnderInvestigation &&
        !isPerformanceVerification &&
        !isInvoiceApproval
      let localStatus = ''

      if (isUnderInvestigation) {
        localStatus = 'Under Investigation'
        tabsResults['under-investigation'].total += 1
        tabsResults['under-investigation'].items.push(item)
      }

      if (isPerformanceVerification) {
        localStatus = 'Performance Verification'
        tabsResults['performance-verification'].total += 1
        tabsResults['performance-verification'].items.push(item)
      }

      if (isInvoiceApproval) {
        localStatus = 'Invoice Approval'
        tabsResults['invoice-approval'].total += 1
        tabsResults['invoice-approval'].items.push(item)
      }

      if (isSentToNetsuite) {
        localStatus = 'Sent to Netsuite'
        tabsResults['sent-to-netsuite'].total += 1
        tabsResults['sent-to-netsuite'].items.push(item)
      }

      tabsResults['opportunity-summary'].total += 1
      tabsResults['opportunity-summary'].items.push({
        ...item,
        status: `${localStatus}`,
      })
    })
    return tabsResults
  }, [billingItems])
  const tableData = React.useMemo(
    () => filteredByTab[activeTab].items,
    [activeTab, filteredByTab],
  )
  const getMandVGroupsAndSites = React.useCallback(
    ({ targetIds }: { targetIds: Array<string> }) => {
      const IdWithNoGroup = []
      const MAndVGroups = targetIds
        .map((key) => {
          const currentMVGroup = tableData.find((entry) => entry.id === key)
            ?.opportunityMAndVGroupName

          if (!currentMVGroup) {
            IdWithNoGroup.push(key)
          }

          return currentMVGroup
        })
        .filter((e) => e)
      setIsMVGroupSelected(MAndVGroups.length > 0)
      const sites =
        MAndVGroups.length > 0 ?
          tableData.filter((record) =>
            MAndVGroups.includes(record.opportunityMAndVGroupName),
          )
        : tableData.filter((record) => IdWithNoGroup.includes(record.id))
      return {
        MAndVGroups,
        sites,
      }
    },
    [
      tableData,
      activeTab,
      filteredByTab,
      reCalculateSavingsLoading,
      sendToInvestigationLoading,
      sendToVerificationLoading,
      sendToReviewBulkLoading,
    ],
  )
  const filteredByCategoryCount = React.useMemo(() => {
    const approvedItems = [
      ...filteredByTab['invoice-approval'].items,
      ...filteredByTab['sent-to-netsuite'].items,
    ]

    const sitesVerified = (category) =>
      approvedItems.filter((item) => item.category === category).length

    const resultsByCategory = {
      'Insufficient Inputs': {
        total: 0,
        sitesVerifiedTotal: sitesVerified('Insufficient Inputs'),
      },
      'Performance In Band': {
        total: 0,
        sitesVerifiedTotal: sitesVerified('Performance In Band'),
      },
      'Performance For Notification Band': {
        total: 0,
        sitesVerifiedTotal: sitesVerified('Performance For Notification Band'),
      },
      'Performance Not In Band': {
        total: 0,
        sitesVerifiedTotal: sitesVerified('Performance Not In Band'),
      },
      'Greater of Estimates': {
        total: 0,
        sitesVerifiedTotal: sitesVerified('Greater of Estimates'),
      },
      'Greater of Actuals': {
        total: 0,
        sitesVerifiedTotal: sitesVerified('Greater of Actuals'),
      },
    }
    billingItems.forEach((item) => {
      if (resultsByCategory[item.category]) {
        resultsByCategory[item.category].total += 1
      }
    })
    return resultsByCategory
  }, [billingItems])
  const enhancedCategoriesCard = React.useMemo(() => {
    const filteredCategories = filteredByCategoryCount
    return filterCards.map((card) => ({
      ...card,
      selected: selectedCategories && selectedCategories.includes(card.id),
      total: filteredCategories[card.id].total,
      sitesVerifiedTotal: filteredCategories[card.id].sitesVerifiedTotal,
    }))
  }, [filteredByCategoryCount, selectedCategories])

  const renderTabPane = () => {
    const TabTextSuffix = ({ tab }: { tab: FTTab }) => {
      const { total } = filteredByTab[tab.tabId]
      const checked = total === 0

      if (checked) {
        return (
          tab.tabId !== 'opportunity-summary' && (
            <TabCheckedStyled>
              <CheckIcon />
            </TabCheckedStyled>
          )
        )
      }

      return <TabCountStyled>{total}</TabCountStyled>
    }

    const allTabs = tabs.map((tabItem: FTTabInfo) => ({
      ...tabItem,
      TextSuffix: TabTextSuffix,
    }))
    return <TabPane2 tabs={allTabs} />
  }

  const handleReRunSavings = React.useCallback(
    ({
      ids,
      name,
      savingsMonth,
    }: {
      ids: Array<string>
      name: string
      savingsMonth: string
    }) =>
      () => {
        const { MAndVGroups, sites } = getMandVGroupsAndSites({
          targetIds: ids,
        })
        const runningSavingsFor =
          sites.length > 1 ? `${sites.length} sites` : name
        hideActionsPopup()

        if (MAndVGroups.every((el) => el === null)) {
          actions.showModalConfirm2({
            primaryText: 'Re-run',
            handlePrimaryAction: () => {
              actions.reCalculateSavings({
                billIds: ids,
                savingsYearMonth: moment(savingsMonth).format('YYYY-MM'),
              })
              setReRunSavingsSubmitted(true)
            },
            renderBody: () =>
              "New Savings values will overwrite the current month's Savings values.",
            handleSecondaryAction: () => {
              actions.hideModal()
            },
            renderTitle: () =>
              `Are you sure you want to re-run the Actual Savings calculation for ${runningSavingsFor}?`,
            loading: reCalculateSavingsLoading,
          })
        } else {
          actions.showBillingReRunSavingsModal({
            title:
              'Savings re-run will be performed for the M&V Group as a whole.' +
              ' Are you sure you want to proceed with re-running savings' +
              ' for all opportunities in the M&V Group?',
            subTitle:
              "New Savings values will overwrite the current month's Savings values",
            isBulkProcess: false,
            billingSites: sites,
            savingsMonth: moment(savingsMonth).format('YYYY-MM'),
            setReRunSavingsSubmitted,
          })
        }
      },
    [tableData, activeTab, filteredByTab, reCalculateSavingsLoading],
  )
  const handleSendToVerification = React.useCallback(
    ({ billIds }: { billIds: Array<string> }) =>
      () => {
        const { MAndVGroups, sites } = getMandVGroupsAndSites({
          targetIds: billIds,
        })

        if (MAndVGroups.every((el) => el === null)) {
          actions.showBillingSendToVerificationModal({
            billId: billIds,
            billingSites: null,
            setSendToVerificationSubmitted,
          })
        } else {
          actions.showBillingSendToVerificationModal({
            billId: sites.map((el) => el.id),
            billingSites: sites,
            setSendToVerificationSubmitted,
          })
        }
      },
    [tableData, activeTab, filteredByTab, sendToVerificationLoading],
  )
  const handleSendToInvestigation = React.useCallback(
    ({
      id,
      reassigned,
      issueOwners,
    }: {
      id: string
      reassigned: boolean
      issueOwners: string
    }) =>
      () => {
        hideActionsPopup()
        const { MAndVGroups, sites } = getMandVGroupsAndSites({
          targetIds: [id],
        })

        if (MAndVGroups.every((el) => el === null)) {
          actions.showBillingSendToInvestigationModal({
            billIds: [id],
            reassigned,
            issueOwners,
            isMandV: false,
            billingSites: sites,
            setSendToInvestigationSubmitted,
          })
        } else {
          actions.showBillingSendToInvestigationModal({
            billIds: sites.map((el) => el.id),
            reassigned,
            billingSites: sites,
            issueOwners,
            isMandV: true,
            setSendToInvestigationSubmitted,
          })
        }
      },
    [tableData, activeTab, filteredByTab, sendToInvestigationLoading],
  )

  const handleSendToReview = ({
    billId,
    selectedAction,
    isSuggestedAction,
    isValid,
    remainingQuantity,
  }: {
    billId: string
    selectedAction: FTBillingItemAction
    isSuggestedAction: boolean
    isValid: boolean
    remainingQuantity?: number
  }) => {
    if (isValid && isSuggestedAction && remainingQuantity > 0) {
      actions.sendToReview({
        billId,
        selectedAction,
      })
      setSendToReviewSubmitted(true)
    } else if (isValid && remainingQuantity > 0) {
      actions.showBillingSendToReviewModal({
        billId,
        selectedAction,
        setSendToReviewSubmitted,
      })
    } else {
      const renderTitleText = `An invoice cannot be created with a resource value of 0. 
          Please review and reach out to the Technology team if you have any questions.`
      actions.hideModal()
      history.push(`${path}/performance-verification`)
      actions.showMessage({
        messageId: 'actionNotAllowedWarning',
        title: renderTitleText,
        type: 'error',
        position: 'fixed',
      })
      setTimeout(() => actions.hideMessage('actionNotAllowedWarning'), 10000)
      setSendToVerificationSubmitted(false)
    }

    hideActionsPopup()
  }

  const handleSendToNetsuite = React.useCallback(
    ({
      target: {
        dataset: { month, opportunityid },
      },
    }: any) => {
      actions.showBillingSendToNetsuiteModal({
        savingsMonth: moment(month).format('YYYY-MM'),
        opportunityList: JSON.parse(opportunityid),
        setSendToNetsuiteSubmitted,
      })
    },
    [setSendToNetsuiteSubmitted],
  )
  const handleReSendToVerification = React.useCallback(
    ({
      savingsMonth,
      opportunityList,
    }: {
      savingsMonth: string
      opportunityList: Array<string>
    }) =>
      () => {
        actions.showBillingReSendToVerificationModal({
          savingsMonth: moment(savingsMonth).format('YYYY-MM'),
          opportunityIds: opportunityList,
          setResendToVerificationSubmitted,
        })
      },
    [],
  )

  const handleBillOverRemainingQuantity = (
    billedOverRemainingQuantity: Array<Record<string, any>>,
    billId,
    selectedAction,
    isSuggestedAction,
    isValid,
  ) => {
    actions.showBillingRemainingQuantityModal({
      billedOverRemainingQuantity,
      onPrimaryAction: () => {
        setBillOnRemainingQuantitySubmitted(true)
        setTimeout(() => {
          handleSendToReview({
            billId,
            selectedAction,
            isSuggestedAction,
            isValid,
            remainingQuantity: billedOverRemainingQuantity[0].remainingQuantity,
          })
        }, 100) // added a delay between two different modal screens for smooth transition
      },
      onSecondaryAction: () => {
        setBillOnRemainingQuantitySubmitted(true)
      },
    })
  }

  const handleActionBillOn = useCallback(
    ({
      billId,
      selectedAction,
      isSuggestedAction,
      isValid,
      name,
      opportunityId,
      suggestedAction,
      contractEstimate,
      calculatedActual,
      salesOrderBilledQuantity,
      salesOrderTotalQuantity,
    }) => {
      const billedOverRemainingQuantity = []
      const suggestedActionDefaultValue =
        suggestedAction === null ? 'ESTIMATE' : suggestedAction
      const remainingQuantity =
        salesOrderTotalQuantity - salesOrderBilledQuantity

      if (
        (suggestedActionDefaultValue === 'ESTIMATE' &&
          remainingQuantity < contractEstimate) ||
        (suggestedActionDefaultValue === 'ACTUAL' &&
          remainingQuantity < calculatedActual)
      ) {
        billedOverRemainingQuantity.push({
          opportunityId,
          name,
          remainingQuantity,
        })
      }

      if (billedOverRemainingQuantity.length) {
        handleBillOverRemainingQuantity(
          billedOverRemainingQuantity,
          billId,
          selectedAction,
          isSuggestedAction,
          isValid,
        )
      } else {
        handleSendToReview({
          billId,
          selectedAction,
          isSuggestedAction,
          isValid,
          remainingQuantity,
        })
      }
    },
  )
  const postComment = React.useCallback(
    (comment: string) => {
      actions.postComment({
        billingItemId: commentsPopupId,
        comment,
      })
    },
    [commentsPopupId],
  )
  const FilterCards = React.useCallback(() => {
    const [optionsCards, setSelectedOptionsCards] = React.useState(
      enhancedCategoriesCard,
    )

    const setSelectedOptions = (selectedCards: Array<FTFilterCard>) => {
      const newSelectedCategories = selectedCards
        .map((card) => (card && card.selected ? card.id : false))
        .filter((item) => item)
      setSelectedCategories(newSelectedCategories)
    }

    const handleChange = ({
      currentTarget: {
        dataset: { id, isfullfilled },
      },
    }: any) => {
      if (isfullfilled === 'false') {
        const nextState = optionsCards.map((option) => {
          if (option && option.id === id) {
            return { ...option, selected: !option.selected }
          }

          return option
        })
        setSelectedOptionsCards(nextState)
        setSelectedOptions(nextState)
      }
    }

    const getIndicator = React.useCallback(
      (isFullfilled, selected) => () => (
        <>
          {isFullfilled && <CheckCircleIconStyled />}
          {selected && !isFullfilled && <RadioButtonCheckedIconStyled />}
          {!selected && !isFullfilled && <CircleOutlineIconStyled />}
        </>
      ),
      [optionsCards],
    )
    return (
      <FilterCardsWrapperStyled>
        {optionsCards.map((card) => {
          const { id, title, selected, total, sitesVerifiedTotal } = card
          const isFullfilled = total === sitesVerifiedTotal
          return (
            <FilterCardStyled
              key={id}
              onClick={handleChange}
              data-isfullfilled={isFullfilled}
              selected={selected}
              data-id={id}
            >
              <IndicatorCard
                title={title}
                Indicator={getIndicator(isFullfilled, selected)}
                Body={() => (
                  <FilterCardResultsWrapperStyled selected={selected}>
                    <FilterCardTotalStyled>
                      {`${sitesVerifiedTotal}/${total}`}
                    </FilterCardTotalStyled>
                    <FilterCardTotalTextStyled>
                      sites verified
                    </FilterCardTotalTextStyled>
                  </FilterCardResultsWrapperStyled>
                )}
              />
            </FilterCardStyled>
          )
        })}
      </FilterCardsWrapperStyled>
    )
  }, [billingItems, selectedCategories])
  const isValidAction = React.useCallback(
    ({
      suggestedAction,
      contractEstimate,
      calculatedActual,
    }: {
      suggestedAction: string
      contractEstimate: number
      calculatedActual: number
    }) => {
      if (suggestedAction === 'ESTIMATE' && contractEstimate > 0) {
        return true
      }

      if (suggestedAction === 'ACTUAL' && calculatedActual > 0) {
        return true
      }

      return false
    },
    [],
  )
  const ActionsPopup = useMemo(
    () =>
      ({
        open,
        cellProps: {
          row: {
            original: {
              id,
              opportunityId,
              name,
              savingsMonth,
              suggestedAction,
              calculatedActual,
              contractEstimate,
              issueOwners,
              salesOrderBilledQuantity,
              salesOrderTotalQuantity,
            },
          },
        },
      }: {
        open: boolean
        cellProps: Record<string, any>
      }) => {
        const suggestedActionValue =
          suggestedAction === null ? 'ESTIMATE' : suggestedAction
        const secondaryAction =
          suggestedActionValue === 'ESTIMATE' ? 'ACTUAL' : 'ESTIMATE'
        return (
          <ActionsPopupMenuStyled
            show={open}
            ellipsisClickedPosition={ellipsisClickedPosition}
            ref={actionsPopupMenuRef}
            popupHeight={getActionsPopupHeight()}
            className='actions-popup-wrapper'
          >
            <ActionsPopupFullScreenBgAlphaLayer
              onClick={onClickActionsPopupBg}
            />
            {activeTab === 'performance-verification' &&
              ((calculatedActual !== null &&
                calculatedActual > 0 &&
                secondaryAction === 'ACTUAL') ||
                (contractEstimate !== null &&
                  contractEstimate > 0 &&
                  secondaryAction === 'ESTIMATE')) && (
                <FeatureValidator
                  requireAll
                  feature={
                    AuthorizedFeatures.billingThisMonthPerformanceReviewer
                  }
                >
                  <ActionsContainerStyled
                    flagged={false}
                    onClick={() =>
                      handleActionBillOn({
                        billId: id,
                        selectedAction: secondaryAction,
                        isSuggestedAction: false,
                        isValid: isValidAction({
                          suggestedAction: secondaryAction,
                          contractEstimate,
                          calculatedActual,
                        }),
                        name,
                        opportunityId,
                        suggestedAction,
                        contractEstimate,
                        calculatedActual,
                        salesOrderBilledQuantity,
                        salesOrderTotalQuantity,
                      })
                    }
                  >
                    <InsertDriveFileIcon />
                    {`Bill on ${secondaryAction.toLowerCase()}`}
                  </ActionsContainerStyled>
                </FeatureValidator>
              )}
            {activeTab === 'performance-verification' && (
              <FeatureValidator
                requireAll
                feature={AuthorizedFeatures.billingThisMonthIssueInvestigator}
              >
                <ActionsContainerStyled
                  flagged={false}
                  onClick={handleReRunSavings({
                    ids: [id],
                    name,
                    savingsMonth,
                  })}
                >
                  <RefreshIcon />
                  Re-run Savings
                </ActionsContainerStyled>
              </FeatureValidator>
            )}
            {activeTab === 'performance-verification' && (
              <FeatureValidator
                requireAll
                feature={AuthorizedFeatures.billingThisMonthIssueInvestigator}
              >
                <ActionsContainerStyled
                  flagged
                  onClick={handleSendToInvestigation({
                    id,
                    reassigned: false,
                    issueOwners,
                  })}
                >
                  <OutlinedFlagIcon />
                  Flag for Investigation
                </ActionsContainerStyled>
              </FeatureValidator>
            )}
            {activeTab === 'under-investigation' && (
              <FeatureValidator
                requireAll
                feature={AuthorizedFeatures.billingThisMonthIssueInvestigator}
              >
                <ActionsContainerStyled
                  flagged={false}
                  onClick={handleSendToInvestigation({
                    id,
                    reassigned: true,
                    issueOwners,
                  })}
                >
                  <BiGroup size={22} />
                  Reassign Issue Owner/s
                </ActionsContainerStyled>
              </FeatureValidator>
            )}
          </ActionsPopupMenuStyled>
        )
      },
    [actionsPopupId],
  )
  const UnderInvestigationActions = React.useCallback(
    (actionsProps) => {
      const {
        cellProps: {
          row: {
            original: { id, name, savingsMonth },
          },
        },
      } = actionsProps
      return (
        <ActionsCellWrapperStyled>
          {isWithinHistoricBillingLimit ?
            <>
              <FeatureValidator
                requireAll
                feature={AuthorizedFeatures.billingThisMonthIssueInvestigator}
              >
                <Tippy content='Re-run Savings' delay={500}>
                  <ActionIconStyled>
                    <RefreshIconStyled
                      onClick={handleReRunSavings({
                        ids: [id],
                        name,
                        savingsMonth,
                      })}
                    />
                  </ActionIconStyled>
                </Tippy>
              </FeatureValidator>
              <FeatureValidator
                requireAll
                feature={AuthorizedFeatures.billingThisMonthIssueInvestigator}
              >
                <Tippy content='Send to Verification' delay={500}>
                  <ActionIconStyled>
                    <GradingIconStyled
                      onClick={handleSendToVerification({
                        billIds: [id],
                      })}
                    />
                  </ActionIconStyled>
                </Tippy>
              </FeatureValidator>
              <ActionIconStyled>
                <CommentsIconStyled
                  onClick={(evt) => onClickActivityIcon(evt, id)}
                />
              </ActionIconStyled>
              <FeatureValidator
                requireAll
                feature={AuthorizedFeatures.billingThisMonthIssueInvestigator}
              >
                <VerticalEllipsisStyled
                  onClick={(evt) => onClickEllipsisMenu(evt, id)}
                  active={actionsPopupId === id}
                />
              </FeatureValidator>
            </>
          : <ActionIconStyled>
              <CommentsIconStyled
                onClick={(evt) => onClickActivityIcon(evt, id)}
              />
            </ActionIconStyled>
          }
        </ActionsCellWrapperStyled>
      )
    },
    [
      actionsPopupId,
      commentsPopupId,
      tableData,
      activeTab,
      filteredByTab,
      isWithinHistoricBillingLimit,
    ],
  )
  const PerformanceVerificationActions = React.useCallback(
    (actionsProps) => {
      const {
        cellProps: {
          row: {
            original: {
              id,
              suggestedAction,
              calculatedActual,
              contractEstimate,
              name,
              opportunityId,
              salesOrderBilledQuantity,
              salesOrderTotalQuantity,
            },
          },
        },
      } = actionsProps
      const action = suggestedAction === null ? 'ESTIMATE' : suggestedAction
      return (
        <>
          {isWithinHistoricBillingLimit ?
            <>
              <FeatureValidator
                requireAll
                feature={AuthorizedFeatures.billingThisMonthPerformanceReviewer}
              >
                <ActionButtonStyled
                  onClick={() =>
                    handleActionBillOn({
                      billId: id,
                      selectedAction: action,
                      isSuggestedAction: true,
                      isValid: isValidAction({
                        suggestedAction,
                        contractEstimate,
                        calculatedActual,
                      }),
                      name,
                      opportunityId,
                      suggestedAction,
                      contractEstimate,
                      calculatedActual,
                      salesOrderBilledQuantity,
                      salesOrderTotalQuantity,
                    })
                  }
                  action={action}
                  type='button'
                >
                  {`Bill on ${action.toLowerCase()}`}
                </ActionButtonStyled>
              </FeatureValidator>
              <CommentsIconStyled
                onClick={(evt) => onClickActivityIcon(evt, id)}
              />
              <FeatureValidator
                requireAll
                feature={AuthorizedFeatures.billingThisMonthIssueInvestigator}
              >
                <VerticalEllipsisStyled
                  onClick={(evt) => onClickEllipsisMenu(evt, id)}
                  active={actionsPopupId === id}
                />
              </FeatureValidator>
            </>
          : <ActionIconStyled>
              <CommentsIconStyled
                onClick={(evt) => onClickActivityIcon(evt, id)}
              />
            </ActionIconStyled>
          }
        </>
      )
    },
    [actionsPopupId, commentsPopupId, isWithinHistoricBillingLimit],
  )
  const InvoiceApprovalActions = React.useCallback(
    (actionsProps) => {
      const {
        cellProps: {
          row: {
            original: { id, savingsMonth, opportunityId },
          },
        },
      } = actionsProps
      return (
        <ActionsCellWrapperStyled>
          {isWithinHistoricBillingLimit ?
            <>
              <FeatureValidator
                requireAll
                feature={AuthorizedFeatures.billingThisMonthInvoiceApprover}
              >
                <SendIconStyled
                  data-month={savingsMonth}
                  data-opportunityid={JSON.stringify([opportunityId])}
                  onClick={handleSendToNetsuite}
                />
              </FeatureValidator>
              <FeatureValidator
                requireAll
                feature={AuthorizedFeatures.billingThisMonthPerformanceReviewer}
              >
                <GradingRedIconStyled
                  onClick={handleReSendToVerification({
                    savingsMonth: moment(savingsMonth).format('YYYY-MM'),
                    opportunityList: [opportunityId],
                  })}
                />
              </FeatureValidator>
              <CommentsIconStyled
                onClick={(evt) => onClickActivityIcon(evt, id)}
              />
            </>
          : <>
              {' '}
              <ActionIconStyled>
                <CommentsIconStyled
                  onClick={(evt) => onClickActivityIcon(evt, id)}
                />
              </ActionIconStyled>
            </>
          }
        </ActionsCellWrapperStyled>
      )
    },
    [commentsPopupId, isWithinHistoricBillingLimit],
  )
  const SentToNetsuiteActions = React.useCallback(
    (actionsProps) => {
      const {
        cellProps: {
          row: {
            original: { id },
          },
        },
      } = actionsProps
      return (
        <ActionsCellWrapperStyled>
          <CommentsIconStyled onClick={(evt) => onClickActivityIcon(evt, id)} />
        </ActionsCellWrapperStyled>
      )
    },
    [commentsPopupId],
  )
  const OpportunitySummaryActions = React.useCallback(
    (actionsProps) => {
      const {
        cellProps: {
          row: {
            original: { id },
          },
        },
      } = actionsProps
      return (
        <ActionsCellWrapperStyled>
          <CommentsIconStyled onClick={(evt) => onClickActivityIcon(evt, id)} />
        </ActionsCellWrapperStyled>
      )
    },
    [commentsPopupId],
  )
  const getActionsCell = React.useCallback(
    (cellProps) => {
      const {
        row: {
          isExpanded,
          depth,
          original: { id },
        },
      } = cellProps
      const isUnderInvestigationTab = activeTab === 'under-investigation'
      const isPerformanceVerificationTab =
        activeTab === 'performance-verification'
      const isInvoiceApprovalTab = activeTab === 'invoice-approval'
      const isNetsuiteTab = activeTab === 'sent-to-netsuite'
      const isOpportunitySummaryTab = activeTab === 'opportunity-summary'
      const canUserAddComments =
        AuthorizedFeatures.billingThisMonthIssueInvestigator.allMatchWithPermissions(
          permissions,
        )
      return (
        <>
          <>
            {isUnderInvestigationTab && (
              <UnderInvestigationActions cellProps={cellProps} />
            )}
            {isPerformanceVerificationTab && (
              <PerformanceVerificationActions cellProps={cellProps} />
            )}
            {isInvoiceApprovalTab && (isExpanded || depth) ?
              <InvoiceApprovalActions cellProps={cellProps} />
            : ''}
            {isNetsuiteTab && (isExpanded || depth) ?
              <SentToNetsuiteActions cellProps={cellProps} />
            : ''}
            {isOpportunitySummaryTab ?
              <OpportunitySummaryActions cellProps={cellProps} />
            : ''}
            <ActionsPopup open={actionsPopupId === id} cellProps={cellProps} />
          </>

          {commentsPopupId === id && (
            <CommentsPopup
              loading={billingItemCommentsLoading}
              comments={billingItemComments}
              handleCommentSubmit={postComment}
              Header={CommentsHeader}
              isReadOnly={isNetsuiteTab || !canUserAddComments}
              onClickOutside={hideActivityLogPopup}
              activityIconClickedPosition={activityIconClickedPosition}
            />
          )}
        </>
      )
    },
    [
      actionsPopupId,
      activeTab,
      billingItemComments,
      billingItemCommentsLoading,
      commentsPopupId,
      postComment,
      filteredByTab,
      tableData,
      isWithinHistoricBillingLimit,
    ],
  )
  const getStatusCell = React.useCallback(
    (cellProps) => {
      const {
        row: {
          original: { status },
        },
      } = cellProps
      return (
        <NavLink
          style={{
            color: '#485DA0',
          }}
          to={`${path}/${status.toLowerCase().replaceAll(' ', '-')}`}
        >
          {status}
        </NavLink>
      )
    },
    [
      actionsPopupId,
      activeTab,
      billingItemComments,
      billingItemCommentsLoading,
      commentsPopupId,
      postComment,
    ],
  )
  const handleCheckboxChange = React.useCallback(
    (event) => {
      const {
        value,
        checked,
        month,
        customerid,
        customer = '',
        isSelectAll = false,
      } = event.target
      const sitesByCustomerIds = tableData
        .filter((site) => site.customer === customer)
        .map((customerInfo) => customerInfo.id)

      if (isSelectAll && checked) {
        const currList = [...itemsChecked, ...sitesByCustomerIds]
        setItemsChecked(currList)
      } else if (isSelectAll && !checked) {
        const currList = itemsChecked.filter(
          (id) => !sitesByCustomerIds.includes(id),
        )
        setItemsChecked(currList)
      } else if (!checked) {
        setItemsChecked(itemsChecked.filter((id) => id !== value))
      } else {
        setItemsChecked([...itemsChecked, value])
      }

      setCurrentActiveMonth(moment(month).format('YYYY-MM'))
      setSelectedCustomerInternalId(customerid)
    },
    [itemsChecked, setItemsChecked, tableData],
  )
  const CustomerCell = React.useCallback(
    ({
      row: {
        getToggleRowExpandedProps,
        isExpanded,
        depth,
        original: {
          sitesVerified,
          verifiedPerformance,
          name,
          savingsMonth,
          customerInternalId,
        },
      },
      value,
    }: any) => {
      const sitesByCustomerIds = tableData
        .filter((site) => site.customerInternalId === customerInternalId)
        .map((customerInfo) => customerInfo.id)
      // const isAllChecked = areEqual(itemsChecked, sitesByCustomerIds);
      const isAllChecked = isSubset(sitesByCustomerIds, itemsChecked)

      if (!depth) {
        return (
          <div>
            <ExpanderCellStyled>
              {activeTab === 'invoice-approval' && (
                <Checkbox
                  name={name}
                  value={isAllChecked}
                  month={savingsMonth}
                  customerid={customerInternalId}
                  checked={isAllChecked}
                  customer={value}
                  isSelectAll
                  onChange={handleCheckboxChange}
                />
              )}
              <span>{value}</span>
              {/* eslint-disable react/jsx-props-no-spreading */}
              <div {...getToggleRowExpandedProps()}>
                {isExpanded && <ChevronUp />}
                {!isExpanded && <ChevronDown />}
              </div>
            </ExpanderCellStyled>
            {isExpanded && (
              <PercentageWrapperStyled>
                <div>Sites {sitesVerified}%</div>
                <div>Performance {verifiedPerformance}%</div>
              </PercentageWrapperStyled>
            )}
          </div>
        )
      }

      return depth ? '' : value
    },
    [handleCheckboxChange, itemsChecked, setItemsChecked, tableData, activeTab],
  )
  const OpportunityNameCell = React.useCallback(
    ({
      row: {
        isExpanded,
        depth,
        original: {
          id,
          sitesVerified,
          name,
          savingsMonth,
          customerInternalId,
          customer,
        },
      },
      value,
    }: any) =>
      isExpanded || depth ?
        <CellWrapperStyled>
          {activeTab === 'invoice-approval' && (
            <Checkbox
              name={name}
              value={id}
              month={savingsMonth}
              customer={customer}
              customerid={customerInternalId}
              checked={itemsChecked.includes(id)}
              onChange={handleCheckboxChange}
            />
          )}
          <OpportunityNameCellStyled>{value}</OpportunityNameCellStyled>
        </CellWrapperStyled>
      : <>
          {!isExpanded && (
            <PerformanceWrapperStyled border={false}>
              <PerformaceVerifiedTitleStyled>
                Sites
                <br />
                Verified
              </PerformaceVerifiedTitleStyled>
              <PerformancePercentageStyled
                color={getPercentageTextColor(sitesVerified)}
              >
                {sitesVerified}
                <span>%</span>
              </PerformancePercentageStyled>
            </PerformanceWrapperStyled>
          )}
        </>,
    [handleCheckboxChange, itemsChecked, setItemsChecked, activeTab],
  )
  const tableColumns = React.useMemo(() => {
    if (tableInstanceRef.current && isInvestigationVerificationTab) {
      // eslint-disable-next-line no-shadow
      const { hiddenColumns } = tableInstanceRef.current.state
      setHiddenColumns(hiddenColumns)
    }

    const enhancedColumns = (allColumns) =>
      allColumns.map((column) => {
        if (column.Header === 'Category') {
          return {
            ...column,
            filter: 'includesSome',
            Filter: (data) => (
              <MultiSelectFilter
                {...data}
                setSelectedFilter={setSelectedCategories}
                selectedFilter={selectedCategories}
              />
            ),
            maxWidth: 400,
            minWidth: 200,
          }
        }

        if (
          activeTab === 'under-investigation' &&
          column.Header === 'Issue Owner'
        ) {
          return {
            ...column,
            filter: 'includesSome',
            Filter: (data) => (
              <MultiSelectFilter
                {...data}
                setSelectedFilter={setSelectedIssueOwners}
                selectedFilter={selectedIssueOwners}
              />
            ),
            maxWidth: 400,
            minWidth: 200,
          }
        }

        if (column.Header === 'Invoice Status') {
          return {
            ...column,
            filter: 'includesSome',
            maxWidth: 400,
            minWidth: 200,
            Filter: (data) => (
              <MultiSelectFilter
                {...data}
                setSelectedFilter={setSelectedInvoiceStatus}
                selectedFilter={selectedInvoiceStatus}
              />
            ),
          }
        }

        if (column.Header === BILLING_METRIC) {
          return {
            ...column,
            filter: 'includesSome',
            Filter: (data) => (
              <MultiSelectFilter
                {...data}
                setSelectedFilter={setSelectedBillingMetric}
                selectedFilter={selectedBillingMetric}
                filterKey='billingMetric'
              />
            ),
            maxWidth: 400,
            minWidth: 180,
          }
        }

        if (!isInvestigationVerificationTab && !isSummaryTab) {
          if (column.Header === 'Customer') {
            return { ...column, Cell: CustomerCell }
          }

          if (column.Header === 'Opportunity Name') {
            return { ...column, Cell: OpportunityNameCell }
          }
        }

        return column
      })

    const investigationAllColumns = enhancedColumns(investigationTabColumns)
    const verificationAllColumns = enhancedColumns(verificationTabColumns)
    const sentToNetsuiteAllColumns = enhancedColumns(sentToNetsuiteTabColumns)
    const approvalAllColumns = enhancedColumns(approvalTabColumns)
    const opportuitySummaryColumns = enhancedColumns(
      opportunitySummaryTabColumns,
    )
    const actionsColumn = [
      {
        id: 'actions',
        Header: 'Actions',
        disableSortBy: true,
        hideSettings: true,
        Cell: getActionsCell,
        minWidth: 50,
        accessor: '',
        disableFilters: true,
        filter: '',
        zIndex: 1,
        position: 'sticky',
        right: '0px',
      },
    ]
    const statusColumn = [
      {
        id: 'status',
        Header: 'Status',
        hideSettings: false,
        Cell: getStatusCell,
        minWidth: 150,
        accessor: 'status',
        disableFilters: true,
      },
    ]

    switch (activeTab) {
      case 'under-investigation':
        return [...validateMappings(investigationAllColumns), ...actionsColumn]

      case 'invoice-approval':
        return [...validateMappings(approvalAllColumns), ...actionsColumn]

      case 'performance-verification':
        return [
          ...validateMappings(verificationAllColumns).filter(
            (column) => column.id && column.id !== 'issueOwners',
          ),
          ...actionsColumn,
        ]

      case 'opportunity-summary':
        return [
          ...validateMappings(opportuitySummaryColumns),
          ...actionsColumn,
          ...statusColumn,
        ]

      default:
        return [...validateMappings(sentToNetsuiteAllColumns), ...actionsColumn]
    }
  }, [
    actionsPopupId,
    commentsPopupId,
    activeTab,
    activeTabInCamelCase,
    selectedCategories,
    selectedIssueOwners,
    selectedInvoiceStatus,
    selectedBillingMetric,
    billingItemCommentsLoading,
    billingItemComments,
    postComment,
    itemsChecked,
    tableData,
    fieldMappings,
  ])
  const groupedBillingItemsByCustomer = React.useMemo(() => {
    if (!isInvestigationVerificationTab) {
      const uniqueCustomersList = [
        ...new Set(tableData.map((item) => item.customer)),
      ]

      const sitesVerifiedByCustomer = (customer) =>
        [
          ...filteredByTab['invoice-approval'].items,
          ...filteredByTab['sent-to-netsuite'].items,
        ].filter((site) => site.customer === customer)

      const getCustomerSites = (customer) =>
        billingItems.filter((item) => item.customer === customer)

      return uniqueCustomersList.map((customer) => {
        const overallCustomerSites = getCustomerSites(customer)
        const verifiedCustomerSites = sitesVerifiedByCustomer(customer)
        const actualPerformancePerVerifiedSite = verifiedCustomerSites.map(
          (site) => {
            const { actualPerformance } = site
            return actualPerformance
          },
        )
        const performanceOverallAverage =
          actualPerformancePerVerifiedSite.reduce(
            (previousValue, currentValue) => previousValue + currentValue,
            0,
          ) / actualPerformancePerVerifiedSite.length
        const sitesVerified = Math.round(
          (verifiedCustomerSites.length / overallCustomerSites.length) * 100,
        )
        const verifiedPerformance = Math.round(performanceOverallAverage)
        const firstRow = overallCustomerSites.shift()
        const customerObj = {
          ...firstRow,
          subRows: overallCustomerSites.map((item) => ({
            ...item,
            sitesVerified,
            verifiedPerformance,
          })),
          sitesVerified,
          verifiedPerformance,
        }
        return customerObj
      })
    }

    return []
  }, [billingItems, activeTab])
  const handleBulkReRunSavings = React.useCallback(
    () => () => {
      // $FlowFixMe
      const selectedIds = selectedRowKeys.map((key) => tableData[key].id)
      const { sites } = getMandVGroupsAndSites({
        targetIds: selectedIds,
      })
      const sitesIds = sites.map((e) => e.id)
      const savingsMonth = tableData.length ? tableData[0].savingsMonth : ''
      // $FlowFixMe
      const siteName =
        selectedRowKeys.length === 1 ? tableData[selectedRowKeys[0]].name : ''
      setToggleHeaderActionDropdown(!toggleHeaderActionDropdown)
      handleReRunSavings({
        ids: sitesIds,
        name: siteName,
        savingsMonth,
      })()
    },
    [selectedRowKeys],
  )
  const handleBulkSendToVerification = React.useCallback(() => {
    const selectedIds = []
    // $FlowFixMe
    const sitesSelected = selectedRowKeys.map((key) => tableData[key])
    sitesSelected.forEach((site) => {
      const { id } = site
      return selectedIds.push(id)
    })
    handleSendToVerification({
      billIds: [...selectedIds],
    })()
  }, [selectedRowKeys])

  const checkForSendToReviewBulkModal = (
    notAllowOpportunities,
    actionNotAllowedCount,
    allowOpportunities,
    action,
  ) => {
    // actionNotAllowedCount=0 && notAllowOpportunities.length then show old modal
    if (notAllowOpportunities.length && actionNotAllowedCount === 0) {
      actions.showBillingSendToReviewBulkModal({
        selectedAction: action,
        allowOpportunities,
        isSelectedActionValid: true,
        notAllowOpportunities,
        setSendToReviewBulkSubmitted,
      })
    }

    // actionNotAllowedCount>0 && notAllowOpportunities.length then show new modal
    if (notAllowOpportunities.length && actionNotAllowedCount > 0) {
      actions.showBillingSendToReviewBulkModal({
        selectedAction: action,
        allowOpportunities,
        isSelectedActionValid: false,
        notAllowOpportunities,
        setSendToReviewBulkSubmitted,
      })
    }

    if (allowOpportunities.length && !notAllowOpportunities.length) {
      actions.sendToReviewBulk({
        billingItems: allowOpportunities,
      })
      setSendToReviewBulkSubmitted(true)
    }
  }

  const handleBulkSendToReview = React.useCallback(
    (action) => {
      const allowOpportunities = []
      const notAllowOpportunities = []
      let actionNotAllowedCount = 0
      // $FlowFixMe
      const sitesSelected = selectedRowKeys.map((key) => tableData[key])
      sitesSelected.forEach((site) => {
        const {
          id,
          opportunityId,
          name,
          suggestedAction,
          contractEstimate,
          calculatedActual,
        } = site
        const suggestedActionDefaultValue =
          suggestedAction === null ? 'ESTIMATE' : suggestedAction
        const isOperationAllowed = isValidAction({
          suggestedAction: suggestedActionDefaultValue,
          contractEstimate,
          calculatedActual,
        })
        if (isOperationAllowed === false) actionNotAllowedCount += 1

        if (suggestedActionDefaultValue === action && isOperationAllowed) {
          return allowOpportunities.push({
            id,
            selectedAction: action,
            comment: '',
            reasons: [],
          })
        }

        return notAllowOpportunities.push({
          id,
          opportunityId,
          name,
        })
      })
      checkForSendToReviewBulkModal(
        notAllowOpportunities,
        actionNotAllowedCount,
        allowOpportunities,
        action,
      )
    },
    [selectedRowKeys],
  )

  const getBilledOverRemainingQuantityData = () => {
    const billedOverRemainingQuantity = []
    // $FlowFixMe
    const sitesSelected = selectedRowKeys.map((key) => tableData[key])
    sitesSelected.forEach((site) => {
      const {
        opportunityId,
        name,
        suggestedAction,
        contractEstimate,
        calculatedActual,
        salesOrderBilledQuantity,
        salesOrderTotalQuantity,
      } = site
      const suggestedActionDefaultValue =
        suggestedAction === null ? 'ESTIMATE' : suggestedAction
      const remainingQuantity =
        salesOrderTotalQuantity - salesOrderBilledQuantity

      if (
        (suggestedActionDefaultValue === 'ESTIMATE' &&
          remainingQuantity < contractEstimate) ||
        (suggestedActionDefaultValue === 'ACTUAL' &&
          remainingQuantity < calculatedActual)
      ) {
        billedOverRemainingQuantity.push({
          opportunityId,
          name,
          remainingQuantity,
        })
      }
    })
    return billedOverRemainingQuantity
  }

  const handleBillOverRemainingQuantityBulk = (
    billedOverRemainingQuantity: Array<Record<string, any>>,
    action: any,
  ) => {
    actions.showBillingRemainingQuantityModal({
      billedOverRemainingQuantity,
      onPrimaryAction: () => {
        setBillOnRemainingQuantitySubmitted(true)
        setTimeout(() => {
          handleBulkSendToReview(action)
        }, 100) // added a delay between two different modal screens for smooth transition
      },
      onSecondaryAction: () => {
        setBillOnRemainingQuantitySubmitted(true)
      },
    })
  }

  const handleBulkActionBillOn = React.useCallback(
    ({
      target: {
        dataset: { action },
      },
    }: any) => {
      const billedOverRemainingQuantity = getBilledOverRemainingQuantityData()

      if (billedOverRemainingQuantity?.length) {
        handleBillOverRemainingQuantityBulk(billedOverRemainingQuantity, action)
      } else {
        handleBulkSendToReview(action)
      }
    },
    [selectedRowKeys],
  )
  const PageHeaderActions = React.useMemo(() => {
    const opportunitiesIds = tableData
      .map((item) => (itemsChecked.includes(item.id) ? item.opportunityId : ''))
      .filter((x) => x)

    if (selectedRowKeys.length > 1 && activeTab === 'under-investigation') {
      return () => (
        <>
          {MonthPickerComponent}
          {isWithinHistoricBillingLimit && (
            <FeatureValidator
              requireAll
              feature={AuthorizedFeatures.billingThisMonthIssueInvestigator}
            >
              <Button2 onClick={handleBulkSendToVerification} type='secondary'>
                Send to Verification
              </Button2>
            </FeatureValidator>
          )}
        </>
      )
    }

    if (selectedRowKeys.length && activeTab === 'performance-verification') {
      return () => (
        <>
          {MonthPickerComponent}
          {isWithinHistoricBillingLimit && (
            <FeatureValidator
              requireAll
              feature={AuthorizedFeatures.billingThisMonthPerformanceReviewer}
            >
              <Button2
                onClick={handleBulkActionBillOn}
                data-action='ESTIMATE'
                type='primary'
              >
                Bill on Estimates
              </Button2>
              <Button2
                onClick={handleBulkActionBillOn}
                data-action='ACTUAL'
                type='secondary'
              >
                Bill on Actuals
              </Button2>
            </FeatureValidator>
          )}
        </>
      )
    }

    if (itemsChecked.length && activeTab === 'invoice-approval') {
      return () => (
        <>
          {MonthPickerComponent}
          {isWithinHistoricBillingLimit && (
            <>
              <FeatureValidator
                requireAll
                feature={AuthorizedFeatures.billingThisMonthInvoiceApprover}
              >
                <Button2
                  onClick={handleSendToNetsuite}
                  data-month={currentActiveMonth}
                  data-opportunityid={JSON.stringify(opportunitiesIds)}
                  type='primary'
                >
                  Send to Netsuite
                </Button2>
              </FeatureValidator>
              <FeatureValidator
                requireAll
                feature={AuthorizedFeatures.billingThisMonthPerformanceReviewer}
              >
                <Button2
                  onClick={handleReSendToVerification({
                    savingsMonth: currentActiveMonth,
                    opportunityList: opportunitiesIds,
                  })}
                  type='warning'
                >
                  Resend for Verification
                </Button2>
              </FeatureValidator>
            </>
          )}
        </>
      )
    }

    return () => MonthPickerComponent
  }, [
    selectedRowKeys,
    activeTab,
    itemsChecked,
    currentActiveMonth,
    selectedCustomerInternalId,
  ])
  const PageHeaderDropdownActions = React.useMemo(() => {
    const isUserEntitled =
      AuthorizedFeatures.billingThisMonthIssueInvestigator.allMatchWithPermissions(
        permissions,
      )

    if (selectedRowKeys.length && isUserEntitled) {
      return () =>
        isWithinHistoricBillingLimit && (
          <PageHeaderDropdownActionsStyled onClick={handleBulkReRunSavings()}>
            <RefreshIcon />
            <Button2 type='transparent'>Re-run Savings</Button2>
          </PageHeaderDropdownActionsStyled>
        )
    }

    return () => null
  }, [selectedRowKeys])
  const handleBackNavigation = React.useCallback(() => {
    history.push('/billing')
  }, [])
  const handleSortClick = React.useCallback(
    ({
      currentTarget: {
        dataset: { id },
      },
    }) => {
      if (sort.id !== id) {
        setSort({
          id,
          desc: false,
        })
      } else if (sort.desc) {
        setSort(initialSort)
      } else {
        setSort({
          id,
          desc: true,
        })
      }
    },
    [sort],
  )
  const data = React.useMemo(
    () =>
      groupedBillingItemsByCustomer
        .sort((customer1, customer2) =>
          sortCustomerDesc ?
            naturallySortEmptyLastCaseInsensitive(
              customer2.customer,
              customer1.customer,
              sortCustomerDesc,
            )
          : naturallySortEmptyLastCaseInsensitive(
              customer1.customer,
              customer2.customer,
              sortCustomerDesc,
            ),
        )
        .filter((customer) =>
          customer && customerFilter ?
            customer.customer
              .toUpperCase()
              .includes(customerFilter.toUpperCase())
          : true,
        )
        .reduce((acc, cur) => {
          const { sitesVerified, verifiedPerformance } = cur
          let rows = tableData.filter(
            ({ customer }) => customer === cur.customer,
          )

          if (sort.id) {
            if (
              [
                'codDateFormatted',
                'loaDateFormatted',
                'invoiceCreatedDateTimeFormatted',
              ].includes(sort.id)
            ) {
              rows.sort((rowA, rowB) =>
                sort.desc ?
                  moment(rowA[sort.id]).format('X') -
                  moment(rowB[sort.id]).format('X')
                : moment(rowB[sort.id]).format('X') -
                  moment(rowA[sort.id]).format('X'),
              )
            } else {
              rows.sort((rowA, rowB) =>
                sort.desc ?
                  naturallySortEmptyLastCaseInsensitive(
                    rowB[sort.id],
                    rowA[sort.id],
                    sort.desc,
                  )
                : naturallySortEmptyLastCaseInsensitive(
                    rowA[sort.id],
                    rowB[sort.id],
                    sort.desc,
                  ),
              )
            }
          }

          if (opportunityNameFilter) {
            rows = rows.filter(({ name }) =>
              name && opportunityNameFilter ?
                name.toLowerCase().includes(opportunityNameFilter.toLowerCase())
              : true,
            )
          }

          if (estimatedFilter) {
            rows = rows.filter(({ estimatedSavingsFormatted }) =>
              String(estimatedSavingsFormatted) && estimatedFilter ?
                String(estimatedSavingsFormatted)
                  .replace(/,/g, '')
                  .includes(String(estimatedFilter).replace(/,/g, ''))
              : true,
            )
          }

          if (actualFilter) {
            rows = rows.filter(({ actualSavingsFormatted }) =>
              String(actualSavingsFormatted) && actualFilter ?
                String(actualSavingsFormatted)
                  .replace(/,/g, '')
                  .includes(actualFilter.replace(/,/g, ''))
              : true,
            )
          }

          if (opportunityIdFilter) {
            rows = rows.filter(({ opportunityId }) =>
              opportunityId && opportunityIdFilter ?
                opportunityId
                  .toLowerCase()
                  .includes(opportunityIdFilter.toLowerCase())
              : true,
            )
          }

          if (selectedBillingMetric.length) {
            rows = rows.filter(({ billingMetric }) =>
              billingMetric ?
                selectedBillingMetric.includes(billingMetric)
              : true,
            )
          }

          if (selectedCategories.length) {
            rows = rows.filter(({ category }) =>
              category ? selectedCategories.includes(category) : true,
            )
          }

          if (selectedIssueOwners.length) {
            rows = rows.filter(({ issueOwner }) =>
              issueOwner ? selectedIssueOwners.includes(issueOwner) : true,
            )
          }

          if (selectedInvoiceStatus.length) {
            rows = rows.filter(({ invoiceCreationStatusFormatted }) =>
              invoiceCreationStatusFormatted ?
                selectedInvoiceStatus.includes(invoiceCreationStatusFormatted)
              : true,
            )
          }

          const firstRow = rows.shift()
          return [
            ...acc,
            {
              ...{ ...firstRow, sitesVerified, verifiedPerformance },
              subRows: rows,
            },
          ]
        }, [])
        .filter((bill) => bill.id),
    [
      customerFilter,
      sort,
      sortCustomerDesc,
      opportunityNameFilter,
      estimatedFilter,
      actualFilter,
      groupedBillingItemsByCustomer,
      tableData,
      opportunityIdFilter,
      selectedCategories,
      selectedBillingMetric,
      selectedIssueOwners,
      selectedInvoiceStatus,
    ],
  )

  const handleCustomerFilterChange = (
    filterValue: string | null | undefined,
  ) => {
    setCustomerFilter(filterValue)
  }

  const handleOpportunityNameChange = (
    filterValue: string | null | undefined,
  ) => {
    setOpportunityNameFilter(filterValue)
  }

  const handleEstimatedFilterChange = (
    filterValue: number | null | undefined,
  ) => {
    setEstimatedFilterName(String(filterValue || ''))
  }

  const handleActualFilterChange = (filterValue: number | null | undefined) => {
    setActualFilterName(String(filterValue || ''))
  }

  const handleOpportunityIdChange = (
    filterValue: string | null | undefined,
  ) => {
    setOpportunityIdFilter(filterValue)
  }

  const handleCategoryChange = (filterValue: Record<string, any>) => {
    setSelectedCategories(filterValue)
  }

  const handleIssueOwnerChange = (filterValue: Record<string, any>) => {
    setSelectedIssueOwners(filterValue)
  }

  const handleBillingMetricChange = (filterValue: Record<string, any>) => {
    setSelectedBillingMetric(filterValue)
  }

  const handleInvoiceStatusChange = (filterValue: Record<string, any>) => {
    setSelectedInvoiceStatus(filterValue)
  }

  const handleCustomerSortClick = React.useCallback(() => {
    setSortCustomerDesc(!sortCustomerDesc)
  }, [sortCustomerDesc])
  const customerFilterHeader = React.useMemo(
    () => ({
      handleSortClick: handleCustomerSortClick,
      id: 'customer',
      label: 'Customer',
      minWidth: 270,
      maxWidth: 270,
      sideMarginWidth: 32,
      sorted: true,
      sortDesc: sortCustomerDesc,
      thStyles: CustomerHeaderTopStyles,
      Filter: DefaultColumnFilter,
      filterValue: customerFilter,
      setFilter: handleCustomerFilterChange,
    }),
    [sortCustomerDesc, handleCustomerSortClick],
  )
  const opportunityNameFilterHeader = React.useMemo(
    () => ({
      handleSortClick,
      id: 'name',
      label: 'Opportunity Name',
      minWidth: 150,
      maxWidth: 250,
      sorted: sort.id === 'name',
      sortDesc: sort.desc,
      thStyles: QuantityStyles,
      Filter: DefaultColumnFilter,
      filterValue: opportunityNameFilter,
      setFilter: handleOpportunityNameChange,
    }),
    [sort, handleSortClick],
  )
  const estimatedFilterHeader = React.useMemo(
    () => ({
      handleSortClick,
      id: 'contractEstimateFormatted',
      label: CONTRACT_ESTIMATE,
      minWidth: 120,
      maxWidth: 120,
      sorted: sort.id === 'contractEstimateFormatted',
      sortDesc: sort.desc,
      thStyles: QuantityStyles,
      Filter: DefaultColumnFilter,
      filterValue: estimatedFilter,
      setFilter: handleEstimatedFilterChange,
    }),
    [sort, handleSortClick],
  )
  const actualFilterHeader = React.useMemo(
    () => ({
      handleSortClick,
      id: 'calculatedActualFormatted',
      label: CALCULATED_ACTUAL,
      minWidth: 120,
      maxWidth: 120,
      sorted: sort.id === 'calculatedActualFormatted',
      sortDesc: sort.desc,
      thStyles: QuantityStyles,
      Filter: DefaultColumnFilter,
      filterValue: actualFilter,
      setFilter: handleActualFilterChange,
    }),
    [sort, handleSortClick],
  )
  const opportunityIdFilterHeader = React.useMemo(
    () => ({
      handleSortClick,
      id: 'opportunityId',
      label: 'Opportunity ID',
      minWidth: 150,
      maxWidth: 180,
      sorted: sort.id === 'opportunityId',
      sortDesc: sort.desc,
      thStyles: QuantityStyles,
      Filter: DefaultColumnFilter,
      filterValue: opportunityIdFilter,
      setFilter: handleOpportunityIdChange,
    }),
    [sort, handleSortClick],
  )
  const categoriesFilterHeader = React.useMemo(
    () => ({
      handleSortClick,
      id: 'category',
      label: 'Category',
      minWidth: 200,
      maxWidth: 200,
      sorted: sort.id === 'category',
      sortDesc: sort.desc,
      thStyles: QuantityStyles,
      Filter: (filterProps) => (
        <MultiSelectFilter
          {...filterProps}
          tableData={tableData}
          setSelectedFilter={setSelectedCategories}
          selectedFilter={selectedCategories}
          filterKey='category'
        />
      ),
      filterValue: selectedCategories,
      setFilter: handleCategoryChange,
    }),
    [sort, handleSortClick, selectedCategories, handleCategoryChange],
  )
  const issueOwnerFilterHeader = React.useMemo(
    () => ({
      handleSortClick,
      id: 'issueOwners',
      label: 'Issue Owner',
      minWidth: 200,
      maxWidth: 200,
      sorted: sort.id === 'issueOwners',
      sortDesc: sort.desc,
      thStyles: QuantityStyles,
      Filter: (filterProps) => (
        <MultiSelectFilter
          {...filterProps}
          tableData={tableData}
          setSelectedFilter={setSelectedIssueOwners}
          selectedFilter={selectedIssueOwners}
          filterKey='issueOwners'
        />
      ),
      filterValue: selectedIssueOwners,
      setFilter: handleIssueOwnerChange,
    }),
    [sort, handleSortClick, selectedIssueOwners, handleIssueOwnerChange],
  )
  const invoiceStatusFilterHeader = React.useMemo(
    () => ({
      handleSortClick,
      id: 'invoiceCreationStatusFormatted',
      label: 'Invoice Status',
      minWidth: 200,
      maxWidth: 200,
      sorted: sort.id === 'invoiceCreationStatusFormatted',
      sortDesc: sort.desc,
      thStyles: QuantityStyles,
      Filter: (filterProps) => (
        <MultiSelectFilter
          {...filterProps}
          tableData={tableData}
          setSelectedFilter={setSelectedInvoiceStatus}
          selectedFilter={selectedInvoiceStatus}
          filterKey='invoiceCreationStatusFormatted'
        />
      ),
      filterValue: selectedInvoiceStatus,
      setFilter: handleInvoiceStatusChange,
    }),
    [sort, handleSortClick, selectedInvoiceStatus, handleInvoiceStatusChange],
  )
  React.useEffect(() => {
    customerFilterHeader.filterValue = customerFilter
  }, [customerFilter])
  React.useEffect(() => {
    opportunityNameFilterHeader.filterValue = opportunityNameFilter
  }, [opportunityNameFilter])
  React.useEffect(() => {
    estimatedFilterHeader.filterValue = estimatedFilter
  }, [estimatedFilter])
  React.useEffect(() => {
    actualFilterHeader.filterValue = actualFilter
  }, [actualFilter])
  React.useEffect(() => {
    opportunityIdFilterHeader.filterValue = opportunityIdFilter
  }, [opportunityIdFilter])
  React.useEffect(() => {
    categoriesFilterHeader.filterValue = selectedCategories
  }, [selectedCategories])
  React.useEffect(() => {
    issueOwnerFilterHeader.filterValue = selectedIssueOwners
  }, [selectedIssueOwners])
  React.useEffect(() => {
    invoiceStatusFilterHeader.filterValue = selectedInvoiceStatus
  }, [selectedInvoiceStatus])

  const getTableExcelData = () => {
    const verificationAllColumns = () =>
      verificationTabColumns.filter(
        // 'Header' used to filter as 'id' is not present in every column data and hence getting error
        (column) => !skipCSVColumns.includes(column.Header),
      )

    const approvalAllColumns = () =>
      approvalTabColumns.filter((column) => column.Header !== 'Actions')

    const opportunitySummaryColumns = () =>
      opportunitySummaryTabColumns.filter(
        (column) => !skipCSVColumns.includes(column.Header),
      )

    let columnToDownload

    if (isSummaryTab) {
      columnToDownload = opportunitySummaryColumns()
    } else if (isInvestigationVerificationTab) {
      columnToDownload = verificationAllColumns()
    } else {
      columnToDownload = approvalAllColumns()
    }
    const opportunityFields = columnToDownload.map((column) => ({
      label: column.Header,
      value: column.accessor,
    }))

    // Process tableData based on column accessors
    const mappedOpportunityData = tableData.map((billItem) => {
      const rowData = {}

      opportunityFields.forEach((field) => {
        rowData[field.label] = billItem[field.value]
      })
      return rowData
    })

    return {
      mappedOpportunityData,
      opportunityFields,
    }
  }

  const getCommentsExcelData = () => {
    const commentsFields = BILLING_COMMENTS_SHEET_HEADERS
    const mappedCommentsData = billingComments
      .filter((commentsItem) =>
        tableData.some(
          (tableItem) => tableItem.opportunityId === commentsItem.opportunityId,
        ),
      )
      .map((commentsItem) => {
        const rowData = {}
        commentsFields.forEach((field) => {
          if (field.key === 'postedTime') {
            rowData[field.label] = moment(commentsItem[field.key]).format(
              'DD/MM/YYYY',
            )
          } else rowData[field.label] = commentsItem[field.key]
        })
        return rowData
      })
    return {
      mappedCommentsData,
      commentsFields,
    }
  }

  const exportExcelFile = () => {
    const { mappedOpportunityData, opportunityFields } = getTableExcelData()
    const { mappedCommentsData, commentsFields } = getCommentsExcelData()
    const savingsMonth = tableData.length ? tableData[0].savingsMonth : ''
    /* generate worksheet from state */
    const opportunityWorkSheet = utils.json_to_sheet(mappedOpportunityData, {
      header: opportunityFields.map((h) => h.label),
      cellStyles: true,
    })
    const commentsWorkSheet = utils.json_to_sheet(mappedCommentsData, {
      header: commentsFields.map((h) => h.label),
    })
    opportunityWorkSheet.A1.s = {
      // set the style for target cell
      font: {
        bold: true,
      },
    }
    opportunityWorkSheet.A1.s.fill = {
      // background color
      patternType: 'solid',
      fgColor: { rgb: 'b2b2b2' },
      bgColor: { rgb: 'b2b2b2' },
    }
    const wb = utils.book_new()
    const tableDataTabName = `Billing This Month ${moment(savingsMonth).format(
      'YYYY-MM',
    )}`
    const commentTabName = `Comments ${moment(savingsMonth).format('MMM YYYY')}`

    utils.book_append_sheet(wb, opportunityWorkSheet, tableDataTabName)
    utils.book_append_sheet(wb, commentsWorkSheet, commentTabName)

    const fileName = `Billing This Month (${moment(savingsMonth).format(
      'YYYY-MM',
    )}) - ${activeTabText}.xlsx`
    writeFile(wb, fileName, { cellStyles: true })
  }

  const downloadAccrualsData = async () => {
    await actions.fetchBillingAccrualsExcel()

    if (!billingItemsLoading && billingItemsError) {
      actions.showMessage({
        messageId: 'accrualsNotAvailableError',
        title: `${billingItemsError || ''}`,
        type: 'error',
        position: 'fixed',
      })
      setTimeout(() => actions.hideMessage('accrualsNotAvailableError'), 10000)
    }
  }

  const tableHeaderRows: Array<FTTableHeaderRow> = React.useMemo(() => {
    const headerRows = [
      customerFilterHeader,
      opportunityNameFilterHeader,
      opportunityIdFilterHeader,
      {
        handleSortClick,
        id: 'codDateFormatted',
        label: 'COD Date',
        minWidth: 120,
        maxWidth: 120,
        sorted: sort.id === 'codDateFormatted',
        sortDesc: sort.desc,
        thStyles: QuantityStyles,
      },
      {
        handleSortClick,
        id: 'billingStructure',
        label: 'Billing Structure',
        minWidth: 120,
        maxWidth: 120,
        sorted: sort.id === 'billingStructure',
        sortDesc: sort.desc,
        thStyles: QuantityStyles,
      },
      {
        handleSortClick,
        id: 'billingMetric',
        label: BILLING_METRIC,
        minWidth: 200,
        maxWidth: 200,
        sortDesc: sort.desc,
        thStyles: QuantityStyles,
        filterValue: selectedBillingMetric,
        setFilter: handleBillingMetricChange,
        Filter: (filterProps) => (
          <MultiSelectFilter
            {...filterProps}
            tableData={tableData}
            setSelectedFilter={handleBillingMetricChange}
            selectedFilter={selectedBillingMetric}
            filterKey='billingMetric'
          />
        ),
      },
      categoriesFilterHeader,
      estimatedFilterHeader,
      actualFilterHeader,
      {
        handleSortClick,
        id: 'actualPerformance',
        label: 'Actual Performance (%)',
        minWidth: 120,
        maxWidth: 120,
        sorted: sort.id === 'actualPerformance',
        sortDesc: sort.desc,
        thStyles: QuantityStyles,
      },
      {
        handleSortClick,
        id: 'historical1Month',
        label: 'Actual Performance - last month (%)',
        minWidth: 120,
        maxWidth: 120,
        sorted: sort.id === 'historical1Month',
        sortDesc: sort.desc,
        thStyles: QuantityStyles,
      },
      {
        handleSortClick,
        id: 'historical2Month',
        label: 'Actual Performance - two months ago (%)',
        minWidth: 120,
        maxWidth: 120,
        sorted: sort.id === 'historical2Month',
        sortDesc: sort.desc,
        thStyles: QuantityStyles,
      },
      {
        handleSortClick,
        id: 'historical3Month',
        label: 'Actual Performance - three months ago (%)',
        minWidth: 120,
        maxWidth: 120,
        sorted: sort.id === 'historical3Month',
        sortDesc: sort.desc,
        thStyles: QuantityStyles,
      },
      {
        handleSortClick,
        id: 'firstActualBill',
        label: 'First Actual Bill',
        minWidth: 120,
        maxWidth: 120,
        sorted: sort.id === 'firstActualBill',
        sortDesc: sort.desc,
        thStyles: QuantityStyles,
      },
      issueOwnerFilterHeader,
      {
        handleSortClick,
        id: 'selectedAction',
        label: 'Billed On',
        minWidth: 120,
        maxWidth: 120,
        sorted: sort.id === 'selectedAction',
        sortDesc: sort.desc,
        thStyles: QuantityStyles,
      },
      {
        handleSortClick,
        id: 'totalBillableSavings',
        label: 'Total Billable Savings',
        minWidth: 120,
        maxWidth: 2400,
        sorted: sort.id === 'totalBillableSavings',
        sortDesc: sort.desc,
        thStyles: QuantityStyles,
      },
      {
        handleSortClick,
        id: 'loaDateFormatted',
        label: 'LOA Date',
        minWidth: 120,
        maxWidth: 120,
        sorted: sort.id === 'loaDateFormatted',
        sortDesc: sort.desc,
        thStyles: QuantityStyles,
      },
      {
        handleSortClick,
        id: 'opportunityOwner',
        label: 'Opportunity Owner',
        minWidth: 120,
        maxWidth: 120,
        sorted: sort.id === 'opportunityOwner',
        sortDesc: sort.desc,
        thStyles: QuantityStyles,
      },
      {
        handleSortClick,
        id: 'dataCapture',
        label: 'Data Capture (%)',
        minWidth: 120,
        maxWidth: 120,
        sorted: sort.id === 'dataCapture',
        sortDesc: sort.desc,
        thStyles: QuantityStyles,
      },
      {
        handleSortClick,
        id: 'invoiceNumber',
        label: 'Invoice Number',
        minWidth: 120,
        maxWidth: 120,
        sorted: sort.id === 'invoiceNumber',
        sortDesc: sort.desc,
        thStyles: QuantityStyles,
      },
      invoiceStatusFilterHeader,
      {
        handleSortClick,
        id: 'invoiceCreatedDateTimeFormatted',
        label: 'Invoice Creation Time',
        minWidth: 120,
        maxWidth: 120,
        sorted: sort.id === 'invoiceCreatedDateTimeFormatted',
        sortDesc: sort.desc,
        thStyles: QuantityStyles,
      },
      {
        handleSortClick,
        id: 'opportunityMAndVGroupName',
        label: 'M&V Group',
        minWidth: 120,
        maxWidth: 120,
        sorted: sort.id === 'opportunityMAndVGroupName',
        sortDesc: sort.desc,
        thStyles: QuantityStyles,
      },
    ]
    const actionsHeader = {
      align: 'center',
      id: 'actions',
      label: 'Actions',
      minWidth: 50,
      maxWidth: 50,
      sortable: false,
      thStyles: ActionsColumnStyles,
    }
    const sortedHeaderRows = []
    headerRows.forEach((header) => {
      const itemIndex = (itemName) => columnsOrder.indexOf(itemName)

      sortedHeaderRows[itemIndex(header.id)] = header
    })
    return [
      {
        id: 'row1',
        headers: [
          // $FlowFixMe
          ...sortedHeaderRows.filter((row) => !hiddenColumns?.includes(row.id)),
          actionsHeader,
        ],
      },
    ]
  }, [
    handleSortClick,
    sort,
    customerFilterHeader,
    opportunityNameFilterHeader,
    actualFilterHeader,
    estimatedFilterHeader,
    columnsOrder,
    activeTab,
    tableData,
    hiddenColumns,
    selectedCategories,
    selectedInvoiceStatus,
    selectedBillingMetric,
  ])
  const TableHeadComponent = React.useCallback(
    () => <TableHead rows={tableHeaderRows} lockedColumns={lockedColumns} />,
    [tableHeaderRows, columnsOrder, lockedColumns],
  )
  const handleSetHiddenColumns = React.useCallback(() => {
    if (tableInstanceRef.current) {
      // eslint-disable-next-line no-shadow
      const { hiddenColumns } = tableInstanceRef.current.state
      setHiddenColumns(hiddenColumns)
    }
  }, [hiddenColumns, tableInstanceRef])
  const handleColumnSettingsChange = React.useCallback(
    ({
      columns,
      lockedColumns: lockedColumnsOnTable,
    }: {
      columns: Array<string>
      lockedColumns: Array<string>
    }) => {
      setColumnsOrder(columns)
      setLockedColumns(lockedColumnsOnTable)
    },
    [columnsOrder],
  )

  const NoDataText = (
    <NoDataTextWrapper>
      Data is not yet available as the calculations are in progress. For more
      information please contact the Technology team.
    </NoDataTextWrapper>
  )

  const HeaderActionButtons = () => {
    const isBillingForThisMonth =
      billingDataMonth === moment().subtract(1, 'months').format('YYYY-MM')
    const validateActions = []
    const isUserHasPermissions =
      AuthorizedFeatures.billingThisMonthInvoiceApprover.allMatchWithPermissions(
        permissions,
      ) ||
      AuthorizedFeatures.billingThisMonthPerformanceReviewer.allMatchWithPermissions(
        permissions,
      )

    if (isUserHasPermissions && isBillingForThisMonth) {
      validateActions.push({
        label: 'Excel',
        onClick: exportExcelFile,
      })
      validateActions.push({
        label: 'Accrual',
        onClick: downloadAccrualsData,
      })
    } else {
      validateActions.push({
        label: 'Excel',
        onClick: exportExcelFile,
      })
    }

    return (
      <DownloadDropDownWrapper>
        <DropdownMenuNew
          title='Download'
          actions={validateActions}
          thisBorderColor='#000000'
          borderOnClose
          textColor={colors.blue2}
          fontSize='12px'
        />
      </DownloadDropDownWrapper>
    )
  }
  const billingRunningViewProps =
    isBillingRunningView ?
      {
        defaultTableTextNode: NoDataText,
      }
    : {}

  const showNoDataModal = () => {
    actions.showModalConfirm2({
      primaryText: 'Close',
      handlePrimaryAction: () => {
        const prevMonths = moment().subtract(2, 'months')
        setIsBillingRunView(true)
        fetchBillingData(prevMonths)
        actions.hideModal()
      },
      showSecondaryButton: false,
      renderTitle: () => `There are no billing items for ${moment(
        historicalBillingMonth,
      ).format('MMMM, YYYY')} yet.
      Please reach out to the technology team, if you need more details.`,
      renderBody: () => false,
    })
  }

  return (
    <>
      {billingItemsLoading ?
        <LoadingSpinnerWrapperStyled data-testid='page-loader'>
          <Spinner size='medium' />
          <div>{console.log(billingItemsLoading)}</div>
        </LoadingSpinnerWrapperStyled>
      : <BillingThisMonthMainPageStyled data-testid='billing-page'>
          <HeaderStyled>
            <PageHeader
              Actions={PageHeaderActions}
              DropdownActions={PageHeaderDropdownActions}
              Title='Billing This Month'
              handleBackNavigation={handleBackNavigation}
            />
          </HeaderStyled>
          {!!billingItems.length && !billingItemsLoading && (
            <>
              <ActionPaneView renderMain={renderTabPane} actions={[]} />
              {(isInvestigationVerificationTab || isSummaryTab) &&
                billingDataMonth ===
                  moment().subtract(1, 'months').format('YYYY-MM') && (
                  <FilterCards />
                )}
            </>
          )}
          {!billingItemsLoading && isSummaryTab && (
            <TableWrapperStyled data-testid='opportunity-summary-table-wrapper'>
              <RedaptiveReactTable7
                columns={tableColumns}
                data={tableData}
                HeaderActions={HeaderActionButtons}
                defaultSort={investigationVerificationDefaultSort}
                alwaysLockedColumns={alwaysLockedColumns}
                enableColumnHiding
                enableColumnSettings
                enablePagination
                filterable
                globalFilterable={false}
                initialHiddenColumns={initialHiddenColumns}
                initialLockedColumns={initialLockedColumns}
                handleColumnSettingsChange={handleColumnSettingsChange}
                handleColumnSettingsClose={handleColumnSettingsClose}
                tableInstanceRef={summarytableInstanceRef}
                alphaLayerOpen={alphaLayerOpen}
                fixedHeader
              />
            </TableWrapperStyled>
          )}
          {!billingItemsLoading && activeTab === 'under-investigation' && (
            <TableWrapperStyled data-testid='under-investigation-table-wrapper'>
              {isBillingRunningView || (
                <DownloadButton onClick={exportExcelFile} type='outlined'>
                  <FileDownloadIcon />
                  Download Excel
                </DownloadButton>
              )}
              <RedaptiveReactTable7
                alwaysLockedColumns={alwaysLockedColumns}
                columns={tableColumns}
                data={tableData}
                defaultSort={investigationVerificationDefaultSort}
                enableColumnHiding
                enableColumnSettings
                enablePagination
                enableRowSelection
                fixActionsToRight
                filterable
                globalFilterable={false}
                handleColumnSettingsChange={handleColumnSettingsChange}
                handleColumnSettingsClose={handleColumnSettingsClose}
                initialHiddenColumns={initialHiddenColumns}
                initialLockedColumns={initialLockedColumns}
                onSelectedRowsChange={setSelectedRows}
                selectedRows={selectedRows}
                tableInstanceRef={tableInstanceRef}
                alphaLayerOpen={alphaLayerOpen}
                fixedHeader
                {...billingRunningViewProps}
              />
            </TableWrapperStyled>
          )}
          {!billingItemsLoading && activeTab === 'performance-verification' && (
            <TableWrapperStyled>
              {isBillingRunningView || (
                <DownloadButton onClick={exportExcelFile} type='outlined'>
                  <FileDownloadIcon />
                  Download Excel
                </DownloadButton>
              )}
              <RedaptiveReactTable7
                alwaysLockedColumns={alwaysLockedColumns}
                columns={tableColumns}
                data={tableData}
                defaultSort={investigationVerificationDefaultSort}
                enableColumnHiding
                enableColumnSettings
                enablePagination
                enableRowSelection
                fixActionsToRight
                filterable
                globalFilterable={false}
                handleColumnSettingsChange={handleColumnSettingsChange}
                handleColumnSettingsClose={handleColumnSettingsClose}
                initialHiddenColumns={initialHiddenColumns}
                initialLockedColumns={initialLockedColumns}
                onSelectedRowsChange={setSelectedRows}
                selectedRows={selectedRows}
                tableInstanceRef={tableInstanceRef}
                alphaLayerOpen={alphaLayerOpen}
                fixedHeader
                {...billingRunningViewProps}
              />
            </TableWrapperStyled>
          )}
          {!billingItemsLoading &&
            !isInvestigationVerificationTab &&
            activeTab === 'invoice-approval' && (
              <MainContentStyled>
                <TableWrapperStyled>
                  {isBillingRunningView || (
                    <DownloadButton onClick={exportExcelFile} type='outlined'>
                      <FileDownloadIcon />
                      Download Excel
                    </DownloadButton>
                  )}
                  <RedaptiveReactTable7
                    alwaysLockedColumns={alwaysLockedColumns}
                    autoResetExpanded={false}
                    columns={tableColumns}
                    data={data}
                    enableColumnHiding
                    enableColumnSettings
                    enablePagination
                    fixActionsToRight
                    globalFilterable={false}
                    handleColumnSettingsChange={handleColumnSettingsChange}
                    handleColumnSettingsClose={handleColumnSettingsClose}
                    initialHiddenColumns={initialHiddenColumns}
                    initialLockedColumns={initialLockedColumns}
                    setHiddenColumns={handleSetHiddenColumns}
                    TableHead={TableHeadComponent}
                    tableInstanceRef={tableInstanceRef}
                    TdComponent={TdStyledStyled}
                    alphaLayerOpen={alphaLayerOpen}
                    fixedHeader
                    {...billingRunningViewProps}
                  />
                </TableWrapperStyled>
              </MainContentStyled>
            )}
          {!billingItemsLoading &&
            !isInvestigationVerificationTab &&
            activeTab === 'sent-to-netsuite' && (
              <MainContentStyled>
                <TableWrapperStyled>
                  {isBillingRunningView || (
                    <DownloadButton onClick={exportExcelFile} type='outlined'>
                      <FileDownloadIcon />
                      Download Excel
                    </DownloadButton>
                  )}
                  <RedaptiveReactTable7
                    alwaysLockedColumns={alwaysLockedColumns}
                    autoResetExpanded={false}
                    columns={tableColumns}
                    data={data}
                    enableColumnHiding
                    enableColumnSettings
                    enablePagination
                    fixActionsToRight
                    globalFilterable={false}
                    handleColumnSettingsChange={handleColumnSettingsChange}
                    handleColumnSettingsClose={handleColumnSettingsClose}
                    initialHiddenColumns={initialHiddenColumns}
                    initialLockedColumns={initialLockedColumns}
                    setHiddenColumns={handleSetHiddenColumns}
                    TableHead={TableHeadComponent}
                    tableInstanceRef={tableInstanceRef}
                    TdComponent={TdStyledStyled}
                    alphaLayerOpen={alphaLayerOpen}
                    fixedHeader
                    {...billingRunningViewProps}
                  />
                </TableWrapperStyled>
              </MainContentStyled>
            )}
          {billingItems.length === 0 &&
            !billingItemsError &&
            billingItemsSuccess &&
            !billingItemsLoading &&
            showNoDataModal()}
          {billingItems.length !== 0 &&
            !tableData.length &&
            !billingItemsLoading &&
            !selectedCategories.length && (
              <ConfirmationScreen>
                <ConfirmationTitleStyled>Well Done</ConfirmationTitleStyled>
                <h2>{`You have completed ${activeTabText} for all sites`}</h2>
              </ConfirmationScreen>
            )}
        </BillingThisMonthMainPageStyled>
      }
    </>
  )
}

const mapDispatchToProps = (dispatch) => ({
  actions: {
    ...bindActionCreators(billingItemsActions, dispatch),
    ...bindActionCreators(itemCommentsActions, dispatch),
    ...bindActionCreators(modalActions, dispatch),
    ...bindActionCreators(messagesActions, dispatch),
  },
})

const mapStateToProps = (state) => {
  const billingItemsEntity = selectBillingThisMonthItemsEntity(state)
  const billingItemCommentEntity = selectBillingThisMonthItemComments(state)
  const {
    meta: {
      loading: billingItemsLoading,
      error: billingItemsError,
      success: billingItemsSuccess,
    },
    items: billingItems,
    reCalculateSavingsMeta: {
      loading: reCalculateSavingsLoading,
      error: reCalculateSavingsError,
    },
    sendToVerificationMeta: {
      loading: sendToVerificationLoading,
      error: sendToVerificationError,
    },
    sendToInvestigationMeta: {
      loading: sendToInvestigationLoading,
      error: sendToInvestigationError,
    },
    sendToReviewMeta: {
      loading: sendToReviewLoading,
      error: sendToReviewError,
    },
    sendToReviewBulkMeta: {
      loading: sendToReviewBulkLoading,
      error: sendToReviewBulkError,
    },
    sendToNetsuiteMeta: {
      loading: sendToNetsuiteLoading,
      error: sendToNetsuiteError,
    },
    resendToVerificationMeta: {
      loading: resendToVerificationLoading,
      error: resendToVerificationError,
    },
    billingComments: {
      comments: billingComments,
      success: billingCommentsSuccess,
      loading: billingCommentsLoading,
      error: billingCommentsError,
    },
  } = billingItemsEntity
  const {
    meta: {
      loading: billingItemCommentsLoading,
      error: billingItemCommentsError,
    },
    items: billingItemComments,
    postCommentMeta: { loading: postCommentLoading },
  } = billingItemCommentEntity
  const {
    auth: { permissions },
  } = state
  return {
    sendToInvestigationLoading,
    sendToInvestigationError,
    sendToVerificationLoading,
    sendToVerificationError,
    sendToReviewLoading,
    sendToReviewError,
    sendToReviewBulkLoading,
    sendToReviewBulkError,
    sendToNetsuiteLoading,
    sendToNetsuiteError,
    reCalculateSavingsLoading,
    reCalculateSavingsError,
    resendToVerificationLoading,
    resendToVerificationError,
    billingComments,
    billingCommentsSuccess,
    billingCommentsLoading,
    billingCommentsError,
    billingItemComments,
    billingItemCommentsLoading,
    billingItemCommentsError,
    billingItemsLoading,
    billingItemsError,
    billingItemsSuccess,
    billingItems,
    postCommentLoading,
    permissions,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BillingThisMonthMainPage)
