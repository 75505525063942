import { FormikProps } from 'formik'
import { ChangeEvent, useEffect, useState } from 'react'
import styled from 'styled-components'

import {
  ClearBtnStyled,
  PageBodyStyled,
  PageHeaderStyled,
  PanelFieldValues,
} from './commons'
import ExpandablePhotoHandler from './ExpandablePhotoHandler'
import { FTMessageInput } from '../../ducks/messages'
import { FTPhotoDownloadUrl } from '../../ducks/panelPhotos'
import { FTPhotoObject } from '../../ducks/panels'
import { zIndices } from '../../utils'
import { ACCEPTED_IMAGE_EXTENSIONS } from '../../utils/constants'
import Button2 from '../Button2'
import ChevronDown from '../Icons/svg/ChevronDown'
import ChevronUp from '../Icons/svg/ChevronUp'
import ExclamationOutline from '../Icons/svg/ExclamationOutline'

const ConfirmOverlayStyled = styled.div`
  background-color: rgba(255, 255, 255, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: ${zIndices.ModalScenario + 1};
`

const ConfirmDialogStyled = styled.div`
  background-color: white;
  border-radius: 4px;
  padding: 30px 25px;
  width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  p {
    color: #4a4a4a;
    font-family: 'Avenir Next';
    font-style: normal;
    font-weight: 600;
    text-align: center;
    font-size: 16px;
    margin-bottom: 16px;
  }
`
const DialogBtnContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`

const enum PanelPhotoTypes {
  LOCATION = 'Location',
  BOX = 'Box',
  CONFIGURATION = 'Configuration',
  SCHEDULE = 'Schedule',
  BREAKER_TYPES = 'BreakerTypes',
}

const PanelPhotosForm = (props: {
  photos: FTPhotoObject[]
  setPhotos: (photos: FTPhotoObject[]) => void
  availablePhotos: FTPhotoDownloadUrl[]
  setPhotoIdsToDelete: (photoIds: string[]) => void
  photoIdsToDelete: string[]
  panelId?: string
  updateMode?: boolean
  formik?: FormikProps<PanelFieldValues>
  showMessage: (value: FTMessageInput) => void
}) => {
  const {
    panelId,
    photos,
    setPhotos,
    availablePhotos,
    setPhotoIdsToDelete,
    formik,
    updateMode,
    photoIdsToDelete,
    showMessage,
  } = props

  // helpers
  const parseAvailablePhotosToSections = (
    photoArray: Array<FTPhotoDownloadUrl>,
  ) => {
    const photosObj: Record<string, Array<FTPhotoDownloadUrl>> = {}
    const addPhotos = (type: string, value: FTPhotoDownloadUrl) => {
      if (photosObj[type]) {
        photosObj[type].push(value)
      } else {
        photosObj[type] = [value]
      }
    }
    if (photoArray.length > 0) {
      photoArray
        .filter((p) => !photoIdsToDelete.includes(p.id))
        .forEach((photo) => {
          const type = photo.objectId.split('/')[1]
          addPhotos(type, photo)
        })
      return photosObj
    }
    return {}
  }

  const [initialRender, setInitialRender] = useState(true)
  const [isExpanded, setIsExpanded] = useState(true)
  const [parsedAvailablePhotos, setParsedAvailablePhotos] = useState<
    Record<string, Array<FTPhotoDownloadUrl>>
  >(parseAvailablePhotosToSections(availablePhotos))
  const [isConfirming, setIsConfirming] = useState(false)
  const [removingPhotoData, setRemovingPhotoData] = useState<{
    fileName: string
    fileFor: string
    fileId?: string
  } | null>(null)

  const toggleIsExpanded = () => {
    setIsExpanded(!isExpanded)
  }
  const parsePhotoData = (
    photoFiles: File[],
    location: string,
  ): FTPhotoObject[] => {
    const objectId = `${panelId}/${location}`
    return photoFiles.map((photo) => ({
      id: '',
      objectId,
      objectType: 'panel',
      file: photo,
      fileName: photo.name,
    }))
  }

  const [locationPhotos, setLocationPhotos] = useState<File[]>(
    photos
      .filter(
        (photo) => photo.objectId.split('/')[1] === PanelPhotoTypes.LOCATION,
      )
      .map((photo) => photo.file),
  )
  const [boxPhotos, setBoxPhotos] = useState<File[]>(
    photos
      .filter((photo) => photo.objectId.split('/')[1] === PanelPhotoTypes.BOX)
      .map((photo) => photo.file),
  )
  const [configurationPhotos, setConfigurationPhotos] = useState<File[]>(
    photos
      .filter(
        (photo) =>
          photo.objectId.split('/')[1] === PanelPhotoTypes.CONFIGURATION,
      )
      .map((photo) => photo.file),
  )
  const [schedulePhotos, setSchedulePhotos] = useState<File[]>(
    photos
      .filter(
        (photo) => photo.objectId.split('/')[1] === PanelPhotoTypes.SCHEDULE,
      )
      .map((photo) => photo.file),
  )
  const [breakerTypesPhotos, setBreakerTypesPhotos] = useState<File[]>(
    photos
      .filter(
        (photo) =>
          photo.objectId.split('/')[1] === PanelPhotoTypes.BREAKER_TYPES,
      )
      .map((photo) => photo.file),
  )

  const onRemove = (fileName: string, fileFor: string, fileId?: string) => {
    if (fileId) {
      const parsedAvailablePhotosCopy = structuredClone(parsedAvailablePhotos)
      parsedAvailablePhotosCopy[fileFor] = parsedAvailablePhotosCopy[
        fileFor
      ].filter((photo) => photo.id !== fileId)
      setParsedAvailablePhotos(parsedAvailablePhotosCopy)
      setPhotoIdsToDelete([...photoIdsToDelete, fileId])
      return
    }
    switch (fileFor) {
      case PanelPhotoTypes.LOCATION:
        setLocationPhotos(
          locationPhotos.filter((photo) => photo.name !== fileName),
        )
        break

      case PanelPhotoTypes.BOX:
        setBoxPhotos(boxPhotos.filter((photo) => photo.name !== fileName))
        break

      case PanelPhotoTypes.CONFIGURATION:
        setConfigurationPhotos(
          configurationPhotos.filter((photo) => photo.name !== fileName),
        )
        break

      case PanelPhotoTypes.SCHEDULE:
        setSchedulePhotos(
          schedulePhotos.filter((photo) => photo.name !== fileName),
        )
        break

      case PanelPhotoTypes.BREAKER_TYPES:
        setBreakerTypesPhotos(
          breakerTypesPhotos.filter((photo) => photo.name !== fileName),
        )
        break

      default:
        break
    }
  }

  const onRemoveHandler = (
    fileName: string,
    fileFor: string,
    fileId?: string,
  ) => {
    setRemovingPhotoData({ fileName, fileFor, fileId })
    setIsConfirming(true)
  }

  const updatePhotos = () => {
    setPhotos([])
    setPhotos([
      ...parsePhotoData(locationPhotos, 'Location'),
      ...parsePhotoData(boxPhotos, 'Box'),
      ...parsePhotoData(configurationPhotos, 'Configuration'),
      ...parsePhotoData(schedulePhotos, 'Schedule'),
      ...parsePhotoData(breakerTypesPhotos, 'BreakerTypes'),
    ])
  }

  const onPhotoInputChange = (e: ChangeEvent) => {
    const files = e.target ? (e.target as HTMLInputElement).files : null
    if (!files) {
      return
    }
    if (files.length === 0) {
      return
    }
    const fileExtensions = Array.from(files).map((file) =>
      file.name.split('.').pop(),
    )
    const uniqueFileExtensions = Array.from(new Set(fileExtensions))
    const isFileExtensionEmpty =
      uniqueFileExtensions.includes(undefined) ||
      uniqueFileExtensions.includes('')
    if (isFileExtensionEmpty) {
      return
    }

    const isFileExtensionNotAccepted = uniqueFileExtensions.some(
      (fileExtension) =>
        fileExtension ?
          !ACCEPTED_IMAGE_EXTENSIONS.includes(fileExtension.toLocaleLowerCase())
        : false,
    )
    if (isFileExtensionNotAccepted) {
      return
    }

    const filefor =
      e.currentTarget ?
        (e.currentTarget as HTMLInputElement).dataset.filefor
      : null

    switch (filefor) {
      case PanelPhotoTypes.LOCATION:
        setLocationPhotos([...locationPhotos, ...Array.from(files)])
        break

      case PanelPhotoTypes.BOX:
        setBoxPhotos([...boxPhotos, ...Array.from(files)])
        break

      case PanelPhotoTypes.CONFIGURATION:
        setConfigurationPhotos([...configurationPhotos, ...Array.from(files)])
        break

      case PanelPhotoTypes.SCHEDULE:
        setSchedulePhotos([...schedulePhotos, ...Array.from(files)])
        break

      case PanelPhotoTypes.BREAKER_TYPES:
        setBreakerTypesPhotos([...breakerTypesPhotos, ...Array.from(files)])
        break

      default:
        break
    }
  }

  const hintMessage =
    updateMode ?
      `${formik?.values.name} Panel and photos will be updated once you click Save & Next Button.`
    : `${formik?.values.name} panel is created. Associate any photos to the created panel.`

  useEffect(() => {
    if (!initialRender) {
      updatePhotos()
    }
    if (initialRender) {
      showMessage({
        messageId: 'panelAddHintMessage',
        title: hintMessage,
        type: 'success',
        position: 'fixed',
      })
    }
    setInitialRender(false)
  }, [
    locationPhotos,
    boxPhotos,
    configurationPhotos,
    schedulePhotos,
    breakerTypesPhotos,
  ])

  return (
    <>
      {isConfirming && (
        <ConfirmOverlayStyled>
          <ConfirmDialogStyled>
            <p>Are you sure you want to remove this image?</p>
            <DialogBtnContainer>
              <Button2 onClick={() => setIsConfirming(false)}>No</Button2>
              <Button2
                type='secondary'
                onClick={() => {
                  setIsConfirming(false)
                  if (removingPhotoData) {
                    onRemove(
                      removingPhotoData.fileName,
                      removingPhotoData.fileFor,
                      removingPhotoData.fileId,
                    )
                  }
                }}
              >
                Yes
              </Button2>
            </DialogBtnContainer>
          </ConfirmDialogStyled>
        </ConfirmOverlayStyled>
      )}
      <PageHeaderStyled>
        <p>Photos</p>
        {}
        <ClearBtnStyled onClick={toggleIsExpanded}>
          {isExpanded ?
            <>
              <span>Collapse All</span>
              <ChevronUp color='#337AB7' />
            </>
          : <>
              <span>Expand All</span>
              <ChevronDown color='#337AB7' />
            </>
          }
        </ClearBtnStyled>
      </PageHeaderStyled>
      <PageBodyStyled>
        <ExpandablePhotoHandler
          availablePhotos={parsedAvailablePhotos[PanelPhotoTypes.LOCATION]}
          title={PanelPhotoTypes.LOCATION}
          items={locationPhotos}
          isAllExpanded={isExpanded}
          onPhotoInputChange={onPhotoInputChange}
          onRemove={onRemoveHandler}
        />
        <ExpandablePhotoHandler
          availablePhotos={parsedAvailablePhotos[PanelPhotoTypes.BOX]}
          title={PanelPhotoTypes.BOX}
          items={boxPhotos}
          isAllExpanded={isExpanded}
          onPhotoInputChange={onPhotoInputChange}
          onRemove={onRemoveHandler}
        />
        <ExpandablePhotoHandler
          availablePhotos={parsedAvailablePhotos[PanelPhotoTypes.CONFIGURATION]}
          title={PanelPhotoTypes.CONFIGURATION}
          items={configurationPhotos}
          isAllExpanded={isExpanded}
          onPhotoInputChange={onPhotoInputChange}
          onRemove={onRemoveHandler}
        />
        <ExpandablePhotoHandler
          availablePhotos={parsedAvailablePhotos[PanelPhotoTypes.SCHEDULE]}
          title={PanelPhotoTypes.SCHEDULE}
          items={schedulePhotos}
          isAllExpanded={isExpanded}
          onPhotoInputChange={onPhotoInputChange}
          onRemove={onRemoveHandler}
        />
        <ExpandablePhotoHandler
          availablePhotos={parsedAvailablePhotos[PanelPhotoTypes.BREAKER_TYPES]}
          title={PanelPhotoTypes.BREAKER_TYPES}
          items={breakerTypesPhotos}
          isAllExpanded={isExpanded}
          onPhotoInputChange={onPhotoInputChange}
          onRemove={onRemoveHandler}
        />
        {/* custom Delete photo confirmation modal */}
      </PageBodyStyled>
    </>
  )
}

export default PanelPhotosForm
