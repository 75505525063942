import { FilterValue, Row } from 'react-table'
import { TSEnergyStarSubmissions } from '../../../../ducks/energyStarIntegration/types'
import {
  columnIds,
  DataAvailabiltyFilterValueEnum,
  DataSourceValueMap,
  DeltaFilterValueEnum,
  ResourceValueMap,
  StatusViewValueMap,
} from '../../helpers/utils'
import { SelectColumnMultiFilterListSelectorStyled } from '../../../../components/RedaptiveReactTable7'

const deltaEnergyConsumptionItems = Object.keys(DeltaFilterValueEnum).map(
  (value) => ({
    id: DeltaFilterValueEnum[value],
    name: value,
  }),
)

const dataAvailabilityItems = Object.keys(DataAvailabiltyFilterValueEnum).map(
  (value) => ({
    id: DataAvailabiltyFilterValueEnum[value],
    name: value,
  }),
)

interface TSProp {
  column: {
    preFilteredRows: Array<Row<TSEnergyStarSubmissions>>
    setFilter: (
      updater: ((filterValue: FilterValue) => FilterValue) | FilterValue,
    ) => void
    id: keyof TSEnergyStarSubmissions
    filterValue: string[]
  }
}

const getDropdownItemList = ({
  id,
  preFilteredRows,
}: {
  id: keyof TSEnergyStarSubmissions
  preFilteredRows: Array<Row<TSEnergyStarSubmissions>>
}) => {
  if (id === columnIds.DeltaEnergyConsumption) {
    return deltaEnergyConsumptionItems
  }
  if (id === columnIds.DataAvailability) {
    return dataAvailabilityItems
  }
  return [...new Set(preFilteredRows.map((row) => row.values[id]))].map(
    (value) => {
      if (id === 'status') {
        return {
          id: value,
          name: StatusViewValueMap[value as keyof typeof StatusViewValueMap],
        }
      }

      if (id === columnIds.ResourceType) {
        return {
          id: value,
          name: ResourceValueMap[value] || value,
        }
      }

      if (id === columnIds.DataSource) {
        return {
          id: value,
          name: DataSourceValueMap[value] || value,
        }
      }

      return {
        id: value,
        name: value,
      }
    },
  )
}

const MutipleSelectFilter = ({
  column: { setFilter, preFilteredRows, id, filterValue },
}: TSProp) => {
  const items = getDropdownItemList({ id, preFilteredRows })

  return (
    <SelectColumnMultiFilterListSelectorStyled
      isMulti={
        id !== columnIds.DeltaEnergyConsumption &&
        id !== columnIds.DataAvailability
      }
      items={items}
      unsettable={false}
      selectedItems={items.filter((item) => filterValue?.includes(item.id))}
      selectedItem={
        filterValue ? items.find((item) => filterValue?.[0] === item.id) : null
      }
      updateValueMulti={(values) => {
        setFilter(values.map(({ value }) => value))
      }}
      updateValue={({ value }) => {
        setFilter([value])
      }}
      menuShouldBlockScroll
    />
  )
}

export default MutipleSelectFilter
