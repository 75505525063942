import { PureComponent } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { RouteProps, withRouter } from 'react-router-dom'
import { bindActionCreators, Dispatch } from 'redux'

import { RootState } from '../..'
import { getDashboardUrl } from '../../api'
import Billing from './components/Billing'
import SiteBreadcrumbs from './components/Breadcrumbs'
import Meters from './components/Meters'
import SiteInfo from './components/SiteInfo'
import features from '../../authorization/features'
import { selectors as authSelectors } from '../../ducks/auth'
import {
  actions as customerActions,
  selectCustomerEntity,
} from '../../ducks/customers'
import {
  actions as energyStarIntegrationActions,
  energyStarSubmissionsEntity,
} from '../../ducks/energyStarIntegration'
import {
  actions as meterActions,
  selectMeterListWithHealthEntity,
} from '../../ducks/meters'
import { METER_RESOURCE_TYPES } from '../../ducks/meters/generation'
import { actions as meterStatusActions } from '../../ducks/meterStatus'
import { actions as modalActions } from '../../ducks/modal'
import {
  actions as panelActions,
  selectPanelListEntity,
} from '../../ducks/panels'
import {
  actions as siteActions,
  CONTRACT_TYPES,
  selectSiteEntity,
  selectSiteHealth,
} from '../../ducks/sites'
import {
  actions as userSummaryActions,
  getUserSummaryListEntity,
} from '../../ducks/userSummaries'
import '../../types'
import type { FTRequestStatus, FTRouterLocation } from '../../types'
import { isVariantActive } from '../../utils'
import ActionPaneView from '../ActionPaneView'
import AllPanelsTable from '../AllPanelsTable'
import ESSiteStatus from '../ESSiteStatus/ESSiteStatus'
import TabPane from '../Tabs/TabPane'
import Title from '../Title'
import UsersTable from '../UsersTable'
import { FTUserPermissions } from '../../authorization/models/userPermissions'
import { FTState } from './types'
import {
  PanelHierarchyCardStyled,
  PanelHierarchyPaperStyled,
  Styles,
} from './styles'
import PanelHierarchyChart from '../../pages/AccountManagement/PanelHierarchyChart'
import { FTFetchEntityList } from '../../ducks/types'

type FTProps = FTRouterLocation &
  RouteProps &
  PropsFromRedux & {
    siteHealthRequestStatus: FTRequestStatus
    permissions: FTUserPermissions
    loading: boolean
    actions: {
      fetchPanelList: FTFetchEntityList
      fetchAllPanels: FTFetchEntityList
      showModalPanelForm3: (params: {
        siteId: string
        handleSuccess: (panelId: string) => void
        closeModal: () => void
        editMode: boolean
      }) => void
      hideModal: () => void
    }
    panelListEntity: FTFetchEntityList
    match: {
      params: {
        siteId: string
      }
      url: string
      path: string
    }
  }

export const defaultSiteIds = [
  // Default Enertiv.
  '669e2c26-6afc-4323-a379-9877fdbd67e1', // Default Redaptive.
  'f0c4b7ff-3214-4323-992d-f73dacbd79d6', // Default Leviton.
  '15e7e4ce-44ec-486a-bb1f-cdc6ce533e25',
]

class SiteDetail extends PureComponent<FTProps, FTState> {
  constructor(props: FTProps) {
    super(props)
    this.state = {
      isCustomerStatusApiCalled: false,
      isSiteStatusApiCalled: false,
      isMeterDeleteApiCalled: false,
      isFetchWaterMeterApiCalled: false,
      meterStatusFetched: false,
    }
  }

  componentDidMount() {
    const {
      actions,
      match: {
        params: { siteId },
      },
    } = this.props
    actions.clearUserSummaries()
    actions.fetchSite({
      siteId,
    })
    actions.fetchSiteExternalResources({
      siteId,
    })
    actions.fetchSiteHealth({
      siteId,
    })
    actions.fetchMeterList({
      siteId,
      orderBy: {
        field: 'name',
        sort: 'ASC',
      },
      filterBy: {
        field: 'resource',
        values: METER_RESOURCE_TYPES,
      },
    })
  }

  componentDidUpdate(prev: FTProps) {
    const {
      siteEntity: { item: prevSite },
      customerEntity: { item: prevCustomer },
      history,
      location: { pathname },
      match: { url },
      energyStarEntity: {
        meta: { loading },
      },
    } = prev
    const {
      actions,
      siteEntity: { item: site },
      permissions,
      match: {
        params: { siteId },
      },
      energyStarEntity: { customerStatus },
      meterListEntity,
    } = this.props
    const { meterStatusFetched } = this.state
    const meterList = meterListEntity

    if (
      !this.state.isCustomerStatusApiCalled &&
      site &&
      features.energyStarIntegration.allMatchWithPermissions(permissions)
    ) {
      actions.getESCustomerIDStatus({
        customerId: site.customerId,
      })
      this.setState({
        isCustomerStatusApiCalled: true,
      })
    }

    if (
      customerStatus &&
      customerStatus.enabled &&
      !this.state.isSiteStatusApiCalled &&
      features.energyStarIntegration.allMatchWithPermissions(permissions)
    ) {
      actions.getESSiteIDStatus({
        redaptiveSiteId: siteId,
      })
      this.setState({
        isSiteStatusApiCalled: true,
      })
    }

    if (
      customerStatus &&
      customerStatus.enabled &&
      !this.state.isFetchWaterMeterApiCalled &&
      features.energyStarIntegration.allMatchWithPermissions(permissions)
    ) {
      actions.fetchWaterMeter({
        siteId,
      })
      this.setState({
        isFetchWaterMeterApiCalled: true,
      })
    }

    if (features.energyStarIntegration.allMatchWithPermissions(permissions)) {
      const isCurrEditPage = pathname.split('/').reverse()[0] === 'edit'

      if (
        isCurrEditPage &&
        loading &&
        !this.props.loading &&
        !this.state.isMeterDeleteApiCalled &&
        !this.props.energyStarEntity?.meta?.error
      ) {
        history.push(`${url}/energyStar`)
      }
    }

    if (meterList.items && !meterStatusFetched) {
      const meterIds = Array.from(
        new Set(meterList.items.map((meter) => meter.name)),
      )
      if (meterIds && meterIds.length) {
        actions.getRealTimeMeterStatus({ ids: meterIds })
        this.setState({ meterStatusFetched: true })
      }
    }

    if (!prevCustomer && !prevSite && site) {
      const { customerId } = site
      actions.fetchCustomer({
        customerId,
      })
    }
  }

  renderUsers = () => (
    <UsersTable
      listEntity={this.props.userSummaryListEntity}
      fetchListEntity={this.props.actions.fetchUserSummaries}
      siteId={this.props.match.params.siteId}
    />
  )

  handleAddPanelSuccess = (panelId: string) => {
    const {
      history,
      match: { url },
    } = this.props
    history.push(`${url}/panels/${panelId}/edit`)
  }

  renderPanels = () => {
    const { actions, panelListEntity } = this.props
    return (
      <AllPanelsTable
        showModalPanelForm3={() => {
          actions.showModalPanelForm3({
            siteId: this.props.match.params.siteId,
            handleSuccess: this.handleAddPanelSuccess,
            closeModal: actions.hideModal,
            editMode: false,
          })
        }}
        panelListEntity={panelListEntity}
        fetchPanels={actions.fetchPanelList}
        fetchAllPanels={actions.fetchAllPanels}
      />
    )
  }

  renderPanelHierarchyChart = () => {
    const { match } = this.props
    return (
      <PanelHierarchyCardStyled>
        <Title>Panel Topology</Title>
        <PanelHierarchyPaperStyled>
          <PanelHierarchyChart match={match} />
        </PanelHierarchyPaperStyled>
      </PanelHierarchyCardStyled>
    )
  }

  renderBilling = () => <Billing />

  resetMeterStatusAPIFlag = () => {
    this.setState({ meterStatusFetched: false })
  }

  renderMeters = () => (
    <Meters resetMeterStatusAPIFlag={this.resetMeterStatusAPIFlag} />
  )

  renderESSiteDetails = () => {
    const {
      energyStarEntity,
      meterListEntity: { items: meters },
      actions,
      match: {
        params: { siteId },
      },
    } = this.props

    const onSubmit = (values) => {
      this.setState({
        isMeterDeleteApiCalled: false,
      })
      actions.updateESSiteIDStatus({
        redaptiveSiteId: siteId,
        reqData: values,
      })
    }

    const onMeterDelete = (meterId: string) => {
      this.setState({
        isMeterDeleteApiCalled: true,
      })
      actions.deleteESMeter({
        meterId,
      })
    }

    const refreshSiteData = () => {
      actions.getESSiteIDStatus({
        redaptiveSiteId: siteId,
      })
    }

    const clearError = () => {
      actions.clearError()
    }

    return (
      <ESSiteStatus
        onSubmit={onSubmit}
        onMeterDelete={onMeterDelete}
        energyStarEntity={energyStarEntity}
        meters={meters}
        clearError={clearError}
        refreshSiteData={refreshSiteData}
      />
    )
  }

  renderMain = () => {
    const {
      meterListEntity: { items: meters },
      match: {
        url,
        params: { siteId, customerId },
      },
      permissions,
      energyStarEntity: {
        meta: { loading: ESloading },
        customerStatus,
      },
    } = this.props
    const contractTypeNames: Record<string, string> = {}
    CONTRACT_TYPES.forEach((contractType) => {
      contractTypeNames[contractType.id] = contractType.name
    })

    const editESSiteStatusLink = {
      href: `${url}/energyStar/edit`,
      text: 'Edit',
      withDefaultTab: true,
    }
    const tabs = [
      {
        tab: 'meters',
        text: 'Meters',
        render: this.renderMeters,
      },
      {
        tab: 'billing',
        text: 'Billing Inputs',
        render: this.renderBilling,
      },
      {
        tab: 'users',
        text: 'Users',
        render: this.renderUsers,
      },
      {
        tab: 'panels',
        text: 'Panels',
        render: this.renderPanels,
      },
    ]

    if (
      features.energyStarIntegration.allMatchWithPermissions(permissions) &&
      customerStatus &&
      customerStatus.enabled
    ) {
      tabs.push({
        tab: 'energyStar',
        text: 'Energy Star',
        render: this.renderESSiteDetails,
        rightHandLink: !ESloading && editESSiteStatusLink,
      })
    }

    if (isVariantActive('BLUE_2576'))
      tabs.push({
        tab: 'panelHierarchy',
        text: 'Panel Hierarchy',
        render: this.renderPanelHierarchyChart,
      })

    return (
      <Styles>
        <SiteBreadcrumbs siteId={siteId} customerId={customerId} url={url} />
        <Title>Site Detail</Title>
        <SiteInfo customerIdUrl={customerId} siteId={siteId} />
        {meters && <TabPane tabs={tabs} />}
      </Styles>
    )
  }

  render() {
    const {
      meterListEntity: { items: meters },
      match: {
        url,
        params: { siteId },
      },
      siteEntity: { item: site },
      permissions,
    } = this.props
    const actions = []

    // If this site is not one of the default sites...
    if (!defaultSiteIds.includes(siteId)) {
      actions.push({
        href: `${url}/edit`,
        label: 'Edit',
      })
    }

    if (
      meters.length > 0 &&
      features.meterExport.allMatchWithPermissions(permissions)
    ) {
      actions.push({
        href: `${url}/meter-export`,
        label: 'Export Meter Data',
      })
    }

    if (site && site.active) {
      actions.push({
        href: `${getDashboardUrl()}/billing/${siteId}`,
        external: true,
        label: 'View in Dashboard',
      })
    }

    return (
      <div className='SiteDetailPage'>
        <ActionPaneView actions={actions} renderMain={this.renderMain} />
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: {
    ...bindActionCreators(siteActions, dispatch),
    ...bindActionCreators(customerActions, dispatch),
    ...bindActionCreators(userSummaryActions, dispatch),
    ...bindActionCreators(meterActions, dispatch),
    ...bindActionCreators(panelActions, dispatch),
    ...bindActionCreators(modalActions, dispatch),
    ...bindActionCreators(energyStarIntegrationActions, dispatch),
    ...bindActionCreators(meterStatusActions, dispatch),
  },
})

const mapStateToProps = (
  state: RootState,
  props: { match: { params: { siteId: string; customerId: string } } },
) => {
  const {
    match: {
      params: { siteId, customerId: customerIdUrl },
    },
  } = props
  const siteEntity = selectSiteEntity(state, siteId)
  let customerId = customerIdUrl

  if (!customerId && siteEntity.item) {
    ;({ customerId } = siteEntity.item)
  }

  const customerEntity = selectCustomerEntity(state, customerId)
  return {
    customerEntity,
    panelListEntity: selectPanelListEntity(state),
    meterListEntity: selectMeterListWithHealthEntity(state),
    permissions: authSelectors.selectPermissions(state),
    siteEntity: {
      ...siteEntity,
      meta: { ...siteEntity.meta, newOpportunity: null },
    },
    energyStarEntity: energyStarSubmissionsEntity(state),
    siteHealth: selectSiteHealth(state),
    userSummaryListEntity: getUserSummaryListEntity(state),
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>

export default withRouter(connector(SiteDetail))
