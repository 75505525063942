import { useSelector, useDispatch } from 'react-redux'
import TabPane from '../../Tabs/TabPane'
import CircuitTable from '../../CircuitTable'
import VerticalTable from '../../VerticalTable'
import { getConditionalFields } from '../../../ducks/meters/generation'
import type { FTDeletePanelAction, FTPanel } from '../../../ducks/panels'
import MeterConfiguration from '../../MeterConfiguration'
import {
  actions as panelActions,
  selectPanelListEntity,
  selectAllById as selectPanelsById,
  FTPanelState as PanelStateType,
} from '../../../ducks/panels'
import {
  FTConfirmModal,
  FTModalPanelForm,
  actions as modalActions,
} from '../../../ducks/modal'
import {
  actions as meterActions,
  selectMeterEntity,
  FTState as MeterTypeState,
  selectStepDownTransformersItems,
} from '../../../ducks/meters'
import { FTEntityPayload as StepDownType } from '../../../ducks/utils'
import {
  getCircuitListEntity,
  getCircuitsById,
  FTState as CircuitTypeState,
} from '../../../ducks/circuits'
import withPermissions from '../../../authorization/components/withPermissions'
import { editElectricMeterPermissions, getFirmwareFields } from '../utils'
import { FTUserPermissions } from '../../../authorization/models/userPermissions'
import { FTMeterConfigUpdate } from '../../../ducks/meters/config'

type PropsType = {
  meterId: string
  url: string
  pathname: string
  permissions: FTUserPermissions
  onMeterConfigUpdate: () => void
}

function ElectricMeter({
  meterId,
  url,
  pathname,
  permissions,
  onMeterConfigUpdate,
}: PropsType) {
  const dispatch = useDispatch()
  const circuitListEntity = useSelector((state: CircuitTypeState) =>
    getCircuitListEntity(state),
  )
  const circuitsById = useSelector((state: CircuitTypeState) =>
    getCircuitsById(state),
  )
  const meterEntity = useSelector((state: MeterTypeState) =>
    selectMeterEntity(state, meterId),
  )
  const stepDownTransformers = useSelector((state: StepDownType) =>
    selectStepDownTransformersItems(state),
  )
  const panelListEntity = useSelector((state: PanelStateType) =>
    selectPanelListEntity(state),
  )
  const panelsById = useSelector((state: PanelStateType) =>
    selectPanelsById(state),
  )
  const meterConfig = useSelector((state) => state.meterConfig)
  const {
    item: meter,
    meta: { loading },
  } = meterEntity

  const [editChannelsLink, editMeterConfigLink] = editElectricMeterPermissions(
    meter,
    permissions,
    url,
    pathname,
  )

  const renderUnderDev = () => <div>Under Development</div>
  const renderFirmware = () => {
    const fields = meter ? getFirmwareFields(meter) : []
    return <VerticalTable.Basic fields={fields} />
  }
  const renderCircuits = () => {
    return meter ?
        <CircuitTable meter={meter} circuitListEntity={circuitListEntity} />
      : null
  }
  const renderConfiguration = () => {
    const { allItems: panelsSite = [], meta: panelsMeta = {} } =
      panelListEntity || {}
    const { generation = 'UNKNOWN' } = meter || {}
    const { getVoltageValues, panelTypes } = getConditionalFields(generation)
    const panels = panelsSite.filter(
      ({ type, voltage }: FTPanel) =>
        panelTypes.includes(type) && getVoltageValues(type).includes(voltage),
    )

    if (loading || !meter || !Object.keys(circuitsById).length) {
      return <div>Loading...</div>
    }

    if (meter.source !== 'REDAPTIVE') {
      return renderUnderDev()
    }

    return (
      <MeterConfiguration
        circuitsById={circuitsById}
        deletePanel={(args: FTDeletePanelAction) =>
          dispatch(panelActions.deletePanel(args))
        }
        meter={meter}
        meterConfig={meterConfig}
        panels={panels}
        panelsById={panelsById}
        panelsMeta={panelsMeta}
        handleSubmit={(args: FTMeterConfigUpdate) =>
          dispatch(meterActions.updateMeterConfig(args))
        }
        handleSuccess={onMeterConfigUpdate}
        showConfirmModal={(args: FTConfirmModal) =>
          dispatch(modalActions.showConfirmModal(args))
        }
        showModalPanelForm={(args: FTModalPanelForm) =>
          dispatch(modalActions.showModalPanelForm(args))
        }
        stepDownTransformers={stepDownTransformers}
      />
    )
  }

  const tabs = [
    {
      tab: 'circuits',
      text: 'Channel Tags',
      render: renderCircuits,
      rightHandLink: editChannelsLink,
    },
    {
      tab: 'network',
      text: 'Network',
      render: renderUnderDev,
    },
    {
      tab: 'firmware',
      text: 'Hardware & Firmware',
      render: renderFirmware,
    },
    {
      tab: 'configuration',
      text: 'Configuration',
      render: renderConfiguration,
      rightHandLink: editMeterConfigLink,
    },
  ]

  return <TabPane tabs={tabs} />
}

export default withPermissions(ElectricMeter)
