import moment from 'moment'
import { useCallback, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  TableInstance,
  UseFiltersInstanceProps,
  UseRowSelectInstanceProps,
} from 'react-table'

import RenderMonthPicker from './RenderMonthPicker'
import WarningModal from './WarningModal'
import FileDownloadIcon from '../../../../components/Icons/FileDownloadIcon'
import RefreshIcon from '../../../../components/Icons/RefreshIcon'
import { TSEnergyStarSubmissions } from '../../../../ducks/energyStarIntegration/types'
import { actions as modalActions } from '../../../../ducks/modal'
import {
  useEnergyStarCommonData,
  useReSyncSubmissionsMutation,
  useSubmissionsDataQuery,
  useSubmitSubmissionsMutation,
} from '../../../../queries/energyStar'
import { HeaderButton, SubmitButton } from '../../styles'
import downloadCSV from '../helpers/downloadCSV'
import exportDataMapper from '../mapper/exportDataMapper'

const HeaderButtons = ({
  tableInstance,
}: {
  tableInstance: TableInstance<TSEnergyStarSubmissions> &
    UseFiltersInstanceProps<TSEnergyStarSubmissions> &
    UseRowSelectInstanceProps<TSEnergyStarSubmissions> & {
      state: { filters: { id: string; value: string[] }[] }
    }
}) => {
  const { activeYearMonth, activeSubmissionTab } = useEnergyStarCommonData()

  const submissionData = useSubmissionsDataQuery()
  const submitSubmissionsMutation = useSubmitSubmissionsMutation()

  const reSyncSubmissionsMutation = useReSyncSubmissionsMutation()

  const isLoading =
    submissionData.isLoading || submitSubmissionsMutation.isLoading

  const [showWarningModal, setShowWarningModal] = useState(false)

  const dispatch = useDispatch()

  const { setAllFilters, toggleAllRowsSelected, selectedFlatRows, state } =
    tableInstance

  const isLastMonth = useMemo(
    () => moment().subtract(1, 'month').format('YYYY-MM') === activeYearMonth,
    [activeYearMonth],
  )

  const clearRowsAndFilters = useCallback(() => {
    setAllFilters([])
    toggleAllRowsSelected(false)
  }, [setAllFilters, toggleAllRowsSelected])

  const handleSubmit = useCallback(() => {
    const selectedIds = selectedFlatRows?.map(({ original: { id } }) => id)
    clearRowsAndFilters()
    dispatch(
      modalActions.showEnergyStarSubmissionModal({
        closeModal: () => dispatch(modalActions.hideModal()),
      }),
    )
    submitSubmissionsMutation.mutate({
      submissionIds: selectedIds,
    })
  }, [
    selectedFlatRows,
    clearRowsAndFilters,
    dispatch,
    submitSubmissionsMutation,
  ])

  const checkAndSubmit = () => {
    const areAllCasesClosed = selectedFlatRows?.every(
      ({ original: { caseStatus } }) =>
        caseStatus?.every((status) => status === 'Closed'),
    )

    if (areAllCasesClosed) return handleSubmit()
    return setShowWarningModal(true)
  }

  const handleReSync = useCallback(() => {
    const selectedIds = selectedFlatRows?.map(({ original: { id } }) => id)
    setAllFilters([])
    reSyncSubmissionsMutation.mutate({
      submissionIds: selectedIds,
    })
  }, [reSyncSubmissionsMutation, selectedFlatRows, setAllFilters])

  const clearAllFilters = useCallback(() => {
    setAllFilters([])
  }, [setAllFilters])

  const handleDownloadCSV = useCallback(() => {
    downloadCSV({
      exportData: exportDataMapper(submissionData.data ?? []),
      activeTab: activeSubmissionTab,
      selectedYearMonth: activeYearMonth!,
    })
  }, [submissionData.data, activeSubmissionTab, activeYearMonth])

  return (
    <>
      {showWarningModal && (
        <WarningModal
          onSubmit={handleSubmit}
          onClose={() => setShowWarningModal(false)}
        />
      )}
      <RenderMonthPicker onSubmit={clearRowsAndFilters} />
      <HeaderButton onClick={handleDownloadCSV} type='outlined'>
        <FileDownloadIcon />
        Download CSV
      </HeaderButton>
      {!!selectedFlatRows?.length && isLastMonth && (
        <HeaderButton
          onClick={handleReSync}
          loading={isLoading}
          type='outlined'
        >
          <RefreshIcon />
          <span>Re-Sync</span>
        </HeaderButton>
      )}
      {!!selectedFlatRows?.length && (
        <SubmitButton
          disabled={isLoading}
          onClick={checkAndSubmit}
          type='secondary'
        >
          Submit
        </SubmitButton>
      )}
      {!!state?.filters?.some(({ value }) => !!value.length) && (
        <SubmitButton onClick={clearAllFilters} type='transparent'>
          Clear Filters
        </SubmitButton>
      )}
    </>
  )
}

export default HeaderButtons
