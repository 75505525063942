import { TSEnergyStarSubmissions } from '../../../../../ducks/energyStarIntegration/types'

function capitalizeFirstLetter(str: string) {
  return str[0].toUpperCase() + str.slice(1)
}
const tableDataMapper = (items: TSEnergyStarSubmissions[]) =>
  items.map((item) => {
    if (item.dataAggregation) {
      let { dataAggregation } = item

      if (item.dataAggregation !== 'wholeSite') {
        if (
          item.resourceType === 'electricity' ||
          item.resourceType === 'water'
        ) {
          dataAggregation = `${capitalizeFirstLetter(dataAggregation)}-${
            item.meterName
          }`
        }
      } else {
        dataAggregation = 'Whole Site'
      }

      return { ...item, dataAggregation }
    }

    return item
  })

export default tableDataMapper
