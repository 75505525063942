import { Parser } from '@json2csv/plainjs'
import FileSaver from 'file-saver'
import moment from 'moment'
import { ReactNode, useCallback, useEffect, useRef, useState } from 'react'
import { DateRangePicker } from 'react-dates'
import { END_DATE, START_DATE } from 'react-dates/constants'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'

import PlotViewChart from './PlotViewChart'
import type { FTPlotViewField } from './PlotViewFieldList'
import PlotViewFieldList, { LabelCellStyled } from './PlotViewFieldList'
import PlotViewModal from './PlotViewModal'
import type { FTMeasurementTypeMap } from '../../ducks/circuits'
import {
  flippedCTStatusLabels,
  getCombinedLabelFromMeasurementType,
  measurementTypesBigBangMap,
  measurementTypesNebulaMap,
} from '../../ducks/circuits'
import type { FTCircuitData, FTCircuitDataMeta } from '../../ducks/circuitsData'
import {
  actions as circuitsDataActions,
  selectCircuitData,
  selectCircuitDataMeta,
} from '../../ducks/circuitsData'
import type { FTPending } from '../../ducks/types'
import type { FTRange } from '../../ducks/utils'
import {
  DATE_FORMAT_DATA_API_REQUEST,
  DATE_FORMAT_SIMPLE,
  DATE_FORMAT_TIMESTAMP,
} from '../../ducks/utils'
import type { FTDateRange } from '../../types'
import { formatNumber, makeListSelectorItem } from '../../utils'
import Button from '../Button'
import ButtonLink from '../ButtonLink'
import { ReportProblemIconStyled } from '../CircuitTable/utils'
import Disabled from '../Disabled'
import type { FTItem } from '../ListSelector'
import ListSelector, { SelectStyles } from '../ListSelector'
import Spinner from '../Spinner'
import SwitchButton from '../SwitchButton'

type FTProps = {
  actions: Record<string, any>
  circuitData: FTCircuitData
  circuitDataMeta: FTCircuitDataMeta
  data: {
    contractId: string
    contractField?: ReactNode
    breakerNumber: string
    breakerNumberField?: ReactNode
    buildingArea: string
    buildingAreaField?: ReactNode
    buildingSystem: string
    buildingSystemField?: ReactNode
    circuitDescription: string
    circuitDescriptionField?: ReactNode
    circuitId: string
    ctTypeAmps: string
    ctTypeAmpsField?: ReactNode
    equipment: string
    equipmentField?: ReactNode
    flippedCTLabel: string
    isBigBang: boolean
    isRedaptive: boolean
    meterChannel: string
    meterId: string
    meterName: string
    meterStartDate: string
    panelName: string
    panelNameField?: ReactNode
    phase: string
    phaseField?: FTPending
    phaseGroup: string
    phaseGroupField?: ReactNode
    siteTimezone: string
  }
  editMode?: boolean
}
export type FTCsvData = {
  circuitId: string
  circuitDescription: string
  meterChannel: string
  breakerNumber: string
  phase: string
  dateUtc: string
  date: string
  measurement: string | number
}
const GridWrapperStyled = styled.div`
  border-top: 1px solid #e0e0e0;
  margin: 1px 30px 32px 30px;
  padding-top: 20px;
`
const GridStyled = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;

  ${SelectStyles} {
    min-width: 110px;
  }
`
const SectionFirstStyled = styled.section`
  > table {
    width: 100%;
  }
`
const PlainCellStyles = styled.div`
  padding: 0 10px;
`

const SectionMiddleStyled = styled.section`
  align-items: flex-start;
  display: flex;
  justify-content: center;

  ${LabelCellStyled} {
    min-width: 310px;
  }
`
const SectionLastStyled = styled.section`
  align-items: flex-start;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
`
const MainWrapperStyled = styled.div`
  position: relative;
`
const PlotViewChartStyled = styled.div`
  position: relative;
  margin-top: 50px;
`
const FiltersSectionStyled = styled.section`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
  position: absolute;
  top: 0;
  width: 100%;

  > * {
    padding: 14px;
  }
`
const FiltersPrimaryStyled = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`
const FiltersSecondaryStyled = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  width: 290px;
`
const FilterLabelStyled = styled.span`
  display: inline-block;
  font-weight: 600;
  padding-right: 0.5em;
`
const HelpSectionStyled = styled.div`
  bottom: 0;
  position: absolute;
  width: 100%;
`
const ZoomHelpTextStyled = styled.div`
  font-size: 12px;
  position: absolute;
  text-align: center;
  right: 106px;
  top: -40px;
`
const HelpTextStyled = styled.div`
  font-size: 12px;
  position: absolute;
  top: -40px;
  right: 376px;
`
const measurementSelectWidth = 460
const MeasurementStyled = styled.div`
  align-items: center;
  display: flex;
  width: ${measurementSelectWidth + 150}px;

  ${SelectStyles} {
    width: ${measurementSelectWidth}px;
  }
`
const ResolutionStyled = styled.div`
  align-items: center;
  display: flex;
  width: 200px;

  ${SelectStyles} {
    width: 100px;
  }
`
const ButtonStyles = styled.div`
  margin-left: 20px;

  button {
    font-size: 14px;
  }
`
const DateRangeStyles = styled.div`
  align-items: center;
  display: flex;
  width: 360px;

  ${FilterLabelStyled} {
    width: 114px;
  }

  .DateRangePicker {
    width: 100%;
  }

  .DateRangePickerInput__withBorder {
    border-radius: 4px;
    border: 1px solid #c7c7c7;
    box-sizing: border-box;
  }

  .DateInput {
    width: 84px;
  }

  .DateRangePickerInput input {
    width: 84px;
    font-size: 14px;
    font-family: 'Avenir Next';
    color: #4a4a4a;
    font-weight: 400;
    padding: 6px 0 2px;
  }

  .DateRangePickerInput_arrow {
    margin-left: 3px;
    margin-right: 10px;
    margin-top: 2px;
    max-width: 13px;
  }

  .ion-android-arrow-forward {
    font-size: 20px;
    font-weight: 500;
  }

  .DateRangePickerInput_calendarIcon {
    margin-left: 0;
    margin-right: 0;
    outline: none;
    padding-top: 7px;
    padding-bottom: 8px;
  }
`
const SpinnerStyled = styled.span`
  align-items: center;
  display: flex;
  padding-right: 0.5em;

  ${Spinner} .Spinner {
    position: static;
  }
`
const PlotItButtonStyled = styled.div`
  display: inline-block;
  padding-left: 0.5em;
`
const FlippedCTFieldStyles = styled.div`
  color: ${({ warning }) => (warning ? '#C70D08' : 'inherit')};
  min-width: 120px;
`
const ToggleDataWrapperStyled = styled.div`
  position: absolute;
  top: -40px;
  right: 0;
  display: flex;
  margin-right: 55px;

  ${Button} {
    width: 200px;
  }
`
const initialDateRange: FTDateRange = {
  startDate: moment().subtract(6, 'days'),
  endDate: moment(),
}
const maxDataPoints = 45000

const getResolutionInMinutes = (resolution: string): number =>
  parseInt(resolution.replace('min', ''), 10)

const getMaxDays = (resolution: string) =>
  Math.floor(maxDataPoints / (24 * (60 / getResolutionInMinutes(resolution))))

const noDataMessage = 'No data found for the requested time period.'
const resolutionItems: Array<FTItem> = [
  makeListSelectorItem('1min', '1 min'),
  makeListSelectorItem('15min', '15 min'),
]
const rawDataTypeLabel = {
  label: 'Raw Data',
  value: 'true',
}
const cleanedDataTypeLabel = {
  label: 'Cleaned Data',
  value: 'false',
}
const dataTypeLabels = [cleanedDataTypeLabel, rawDataTypeLabel]

const ChannelTagsPlotView = (props: FTProps) => {
  const {
    actions,
    circuitData,
    circuitDataMeta,
    data: {
      contractId,
      contractField,
      buildingArea,
      buildingAreaField,
      buildingSystem,
      buildingSystemField,
      breakerNumber,
      breakerNumberField,
      circuitDescription,
      circuitDescriptionField,
      circuitId,
      ctTypeAmps,
      ctTypeAmpsField,
      equipment,
      equipmentField,
      flippedCTLabel,
      isBigBang,
      isRedaptive,
      meterChannel,
      meterStartDate,
      panelName,
      panelNameField,
      phase,
      phaseField,
      phaseGroup,
      phaseGroupField,
      siteTimezone,
    },
    editMode,
  } = props
  const { error, loading } = circuitDataMeta
  const { data, ts, uncleansed } = circuitData
  const measurementTypesResponse = Object.keys(data)
  const [focusedInput, setFocusedInput] = useState()
  const [dateRange, setDateRange] = useState(initialDateRange)
  const [switchButtonOptions, setSwitchButtonOptions] = useState(dataTypeLabels)
  const [initialized, setInitialized] = useState(false)
  const [measurementTypesSelected, setMeasurementTypesSelected] = useState(
    isRedaptive && !isBigBang ? ['activePower'] : ['power'],
  )
  const measurementTypesMap: FTMeasurementTypeMap =
    isRedaptive && !isBigBang ?
      measurementTypesNebulaMap
    : measurementTypesBigBangMap

  const getMeasurementUnit = (measurementType) =>
    measurementTypesMap[measurementType].unit

  const [measurementUnits, setMeasurementUnits] = useState(
    measurementTypesSelected.reduce(
      (acc, cur) => ({ ...acc, [cur]: getMeasurementUnit(cur) }),
      {},
    ),
  )
  const [selectedResolution, setSelectedResolution] = useState('1min')
  const [modalNoDataVisible, setModalNoDataVisible] = useState(false)
  const [modalErrorVisible, setModalErrorVisible] = useState(false)
  const chartRef = useRef()

  const getRequestRange = (): FTRange => {
    const startDate = moment.tz(dateRange.startDate, siteTimezone)
    const isSameDate =
      dateRange.startDate.date() === startDate.startOf('day').date()
    const fromDate =
      isSameDate ?
        startDate.startOf('day')
      : startDate.add(1, 'days').startOf('day')
    return {
      from: fromDate.format(DATE_FORMAT_DATA_API_REQUEST),
      to: moment
        .tz(dateRange.endDate, siteTimezone)
        .endOf('day')
        .format(DATE_FORMAT_DATA_API_REQUEST),
    }
  }

  useEffect(() => {
    if (!initialized && circuitId) {
      setInitialized(true)
      actions.getCircuitData({
        circuitId,
        measurementTypes: measurementTypesSelected,
        resolution: selectedResolution,
        siteTimezone,
        uncleansed,
        ...getRequestRange(),
      })
    }
  })
  useEffect(() => {
    if (!loading && Object.keys(data).length === 0) {
      setModalNoDataVisible(true)
    }

    return function cleanUp() {
      setModalNoDataVisible(false)
    }
  }, [loading])
  useEffect(() => {
    if (error) {
      setModalErrorVisible(true)
    }

    return function cleanUp() {
      setModalErrorVisible(false)
    }
  }, [error])
  const measurementItems: Array<FTItem> = Object.keys(measurementTypesMap).map(
    (type) =>
      makeListSelectorItem(
        type,
        getCombinedLabelFromMeasurementType(type, measurementTypesMap),
      ),
  )
  const selectedMeasurementItems: Array<FTItem> = measurementTypesSelected.map(
    (measurementType) =>
      measurementItems.find((item) => item.id === measurementType) || {
        id: '',
        name: '',
      },
  )

  const onUpdateMeasurement = ({ value }) => {
    setMeasurementTypesSelected([value])
  }

  const onUpdateMeasurementMulti = (items) => {
    setMeasurementTypesSelected(items.slice(-2).map((item) => item.value))
  }

  const currentResolutionItem = resolutionItems.find(
    (item) => item.id === selectedResolution,
  )

  const onUpdateResolution = ({ value }) => {
    const daysCount =
      dateRange.endDate && dateRange.startDate ?
        dateRange.endDate.diff(dateRange.startDate, 'days') + 1
      : 0
    const maxDays = getMaxDays(value)

    if (daysCount > maxDays) {
      setDateRange({
        ...dateRange,
        endDate: moment(dateRange.startDate).add(maxDays - 1, 'days'),
      })
    }

    setSelectedResolution(value)
  }
  const handleClick = (label: string, key: string) => {
    if (chartRef.current) {
      const isMin = label.includes('Min')
      const series = chartRef.current.chart.series.find(
        (y) => y.name === measurementTypesMap[key].label,
      )
      const maxPoint = series.points.find(
        (point) => point.y === circuitData.max?.[key],
      )
      const minPoint = series.points.find(
        (point) => point.y === circuitData.min?.[key],
      )
      chartRef.current.chart.tooltip.refresh(isMin ? minPoint : maxPoint)
    }
  }
  const onDatesChange = ({ startDate, endDate }: FTDateRange) => {
    let end = endDate

    if (
      startDate &&
      endDate && // If the start date is being set...
      focusedInput === START_DATE
    ) {
      // Reset the end date.
      end = null
    }
    setDateRange({
      startDate,
      endDate: end,
    })
  }

  const isOutsideRange = (
    baseDay: typeof moment,
    focusedInputOverride: string | null | undefined = null,
  ) => {
    const meterStartDateMoment = moment(meterStartDate, DATE_FORMAT_SIMPLE)
    const maxDays = getMaxDays(selectedResolution)
    const focusedInputState = focusedInputOverride || focusedInput
    // Utilize startOf('day') to provide a baseline for comparison
    // the `baseDay` provided by DatePicker is at noon for each day.
    const day = baseDay.clone().startOf('day')
    const today = moment().startOf('day')
    const yesterday = moment().startOf('day').subtract(1, 'days')

    if (day.isBefore(meterStartDateMoment)) {
      return true
    }

    // startDate can never be later than yesterday.
    if (focusedInputState === START_DATE && day.isAfter(yesterday)) {
      return true
    }

    if (focusedInputState === END_DATE) {
      // endDate can never be later than today.
      if (day.isAfter(today)) {
        return true
      }

      if (dateRange.startDate) {
        const startDate = dateRange.startDate.clone().startOf('day')

        // endDate can never be before startDate.
        if (day.isBefore(startDate)) {
          return true
        }

        if (day.isAfter(startDate.clone().add(maxDays - 1, 'days'))) {
          return true
        }
      }
    }

    return false
  }

  const onPlotItSubmit = (isCleaned = true) => {
    actions.getCircuitData({
      circuitId,
      measurementTypes: measurementTypesSelected,
      resolution: selectedResolution,
      siteTimezone,
      uncleansed: isCleaned,
      ...getRequestRange(),
    })

    if (chartRef.current) {
      chartRef.current.chart.zoomOut()
    }

    setMeasurementUnits(
      measurementTypesSelected.reduce(
        (acc, cur) => ({ ...acc, [cur]: getMeasurementUnit(cur) }),
        {},
      ),
    )
  }

  const closeNoDataModal = () => {
    setModalNoDataVisible(false)
  }

  const closeErrorModal = () => {
    setModalErrorVisible(false)
  }

  const getCsvData = (): {
    data: FTCsvData
    headers: FTCsvData
  } => {
    const measurementFields = measurementTypesResponse.map(
      (measurementType) => ({
        value: measurementType,
        label: getCombinedLabelFromMeasurementType(
          measurementType,
          measurementTypesMap,
        ),
      }),
    )
    const fields = [
      {
        value: 'circuitId',
        label: 'Circuit ID',
      },
      {
        value: 'circuitDescription',
        label: 'Description',
      },
      {
        value: 'meterChannel',
        label: 'Meter Channel',
      },
      {
        value: 'breakerNumber',
        label: 'Breaker Number',
      },
      {
        value: 'phase',
        label: 'Phase',
      },
      {
        value: 'dateUtc',
        label: 'Timestamp (UTC)',
      },
      {
        value: 'date',
        label: 'Timestamp (Site Time Zone)',
      },
      ...measurementFields,
    ]
    const json2csvParser = new Parser({
      fields,
    })
    const csvData = []
    ts.forEach((timestamp, i) => {
      const dateMoment = moment.tz(
        timestamp,
        DATE_FORMAT_TIMESTAMP,
        siteTimezone,
      )
      const measurementCsvFields = measurementTypesResponse.reduce(
        (acc, cur) => ({ ...acc, [cur]: data[cur][i] }),
        {},
      )
      csvData.push({
        breakerNumber,
        circuitDescription,
        circuitId,
        date: dateMoment.toISOString(true).replace('.000', ''),
        dateUtc: dateMoment.toISOString().replace('.000', ''),
        ...measurementCsvFields,
        meterChannel,
        phase,
      })
    })
    return json2csvParser.parse(csvData)
  }

  const downloadCSV = () => {
    const start = ts[0] || ''
    const end = ts.slice(-1)[0] || ''
    const file = new Blob([getCsvData()], {
      type: 'text/csv',
    })
    const measurementItemNames = selectedMeasurementItems.map(
      (measurementItem) => measurementItem.name,
    )
    const fileName =
      `Circuit Data - ${circuitDescription
        .substring(0, 20)
        .trim()} - ${circuitId} - ${start}` +
      ` - ${end} - ${measurementItemNames.join(' - ')}.csv`
    FileSaver.saveAs(file, fileName)
  }

  const plotItButtonDisabled =
    loading ||
    dateRange.startDate === null ||
    dateRange.endDate === null ||
    !measurementTypesSelected.length
  const fieldsCol1Redaptive =
    isRedaptive ?
      [
        {
          label: 'Phase:',
          value: phaseField || phase,
        },
        {
          label: 'Phase Group:',
          value: phaseGroup,
        },
      ]
    : []
  const fieldsCol1Nebula =
    isRedaptive && !isBigBang ?
      [
        {
          label: 'CT Orientation',
          renderField: () => {
            const labelWarning = [
              flippedCTStatusLabels.NOT_LABELED,
              flippedCTStatusLabels.NEEDS_REVIEW,
            ].includes(flippedCTLabel)
            return (
              <FlippedCTFieldStyles warning={labelWarning}>
                {labelWarning && <ReportProblemIconStyled />}
                {flippedCTLabel}
              </FlippedCTFieldStyles>
            )
          },
          value: flippedCTLabel,
        },
      ]
    : []
  const fieldsCol1: Array<FTPlotViewField> = [
    {
      label: 'Panel:',
      value: panelName,
    },
    {
      label: 'Breaker Number:',
      value: breakerNumber,
    },
    ...fieldsCol1Redaptive,
    {
      label: 'CT Type:',
      value: ctTypeAmps,
    },
    ...fieldsCol1Nebula,
  ]
  const fieldsCol2 = [
    {
      label: 'Circuit Description (from Panel Label):',
      value: circuitDescription,
    },
    {
      label: 'Equipment Name (In Energy Dashboard):',
      value: equipment,
    },
    {
      label: 'Building System (In Energy Dashboard):',
      value: buildingSystem,
    },
    {
      label: 'Building Area:',
      value: buildingArea,
    },
    {
      label: 'Billable Contract:',
      value: contractId,
    },
  ]
  const fieldsCol3 = []

  if (!loading && !error) {
    measurementTypesResponse.forEach((measurementType) => {
      fieldsCol3.push({
        label: `${measurementTypesMap[measurementType].label} - Min:`,
        value: `${formatNumber(circuitData.min[measurementType])} ${
          measurementUnits[measurementType]
        }`,
        key: measurementType,
      })
      fieldsCol3.push({
        label: `${measurementTypesMap[measurementType].label} - Max:`,
        value: `${formatNumber(circuitData.max[measurementType])} ${
          measurementUnits[measurementType]
        }`,
        key: measurementType,
      })
    })
  }

  const fieldsEditCol1Redaptive =
    isRedaptive ?
      [
        {
          label: 'Phase:',
          value: phase,
          renderField: () => <PlainCellStyles>{phase}</PlainCellStyles>,
        },
        {
          label: 'Phase Group:',
          value: phaseGroup,
          renderField: () => phaseGroupField || '',
        },
      ]
    : []
  const fieldsEditCol1Nebula =
    isRedaptive && !isBigBang ?
      [
        {
          label: 'CT Orientation',
          renderField: () => {
            const labelWarning = [
              flippedCTStatusLabels.NOT_LABELED,
              flippedCTStatusLabels.NEEDS_REVIEW,
            ].includes(flippedCTLabel)
            return (
              <PlainCellStyles>
                <Disabled>
                  <FlippedCTFieldStyles warning={labelWarning}>
                    {labelWarning && <ReportProblemIconStyled />}
                    {flippedCTLabel}
                  </FlippedCTFieldStyles>
                </Disabled>
              </PlainCellStyles>
            )
          },
          value: flippedCTLabel,
        },
      ]
    : []
  const fieldsEditCol1: Array<FTPlotViewField> = [
    {
      label: 'Panel:',
      value: panelName,
      renderField: () => panelNameField || '',
    },
    {
      label: 'Breaker Number:',
      value: breakerNumber,
      renderField: () => breakerNumberField || '',
    },
    ...fieldsEditCol1Redaptive,
    {
      label: 'CT Type',
      value: ctTypeAmps,
      renderField: () => ctTypeAmpsField || '',
    },
    ...fieldsEditCol1Nebula,
  ]
  const fieldsEditCol2 = [
    {
      label: 'Circuit Description (from Panel Label):',
      value: circuitDescription,
      renderField: () => circuitDescriptionField || '',
    },
    {
      label: 'Equipment Name (In Energy Dashboard):',
      value: equipment,
      renderField: () => equipmentField || '',
    },
    {
      label: 'Building System (In Energy Dashboard):',
      value: buildingSystem,
      renderField: () => buildingSystemField || '',
    },
    {
      label: 'Building Area:',
      value: buildingArea,
      renderField: () => buildingAreaField || '',
    },
    {
      label: 'Billable Contract:',
      value: contractId,
      renderField: () => contractField || '',
    },
  ]
  const fieldsEditCol3 = []

  if (!loading && !error) {
    measurementTypesResponse.forEach((measurementType) => {
      fieldsEditCol3.push({
        label: `${measurementTypesMap[measurementType].label} - Min:`,
        value: circuitData.min[measurementType],
        key: measurementType,
        renderField: () => (
          <PlainCellStyles>
            {`${formatNumber(circuitData.min[measurementType])} ${
              measurementUnits[measurementType]
            }`}
          </PlainCellStyles>
        ),
      })
      fieldsEditCol3.push({
        label: `${measurementTypesMap[measurementType].label} - Max:`,
        value: circuitData.max[measurementType],
        key: measurementType,
        renderField: () => (
          <PlainCellStyles>
            {`${formatNumber(circuitData.max[measurementType])} ${
              measurementUnits[measurementType]
            }`}
          </PlainCellStyles>
        ),
      })
    })
  }

  const onUpdateDataType = useCallback(
    (value: string) => {
      onPlotItSubmit(value === 'true')
    },
    [onPlotItSubmit, selectedResolution],
  )
  useEffect(() => {
    let isUncleased = true

    if (selectedResolution === '15min') {
      isUncleased = false
      setSwitchButtonOptions([cleanedDataTypeLabel])
    } else {
      setSwitchButtonOptions(dataTypeLabels)
    }

    onPlotItSubmit(isUncleased)
  }, [selectedResolution])
  return (
    <MainWrapperStyled>
      <ToggleDataWrapperStyled>
        <SwitchButton
          current={uncleansed.toString()}
          options={switchButtonOptions}
          update={onUpdateDataType}
        />
      </ToggleDataWrapperStyled>
      <PlotViewChartStyled>
        <HelpSectionStyled>
          <ZoomHelpTextStyled>
            Click-and-drag to zoom. Shift-drag to pan.
          </ZoomHelpTextStyled>
          <HelpTextStyled>Data is displayed in site time zone.</HelpTextStyled>
        </HelpSectionStyled>
        <PlotViewChart
          chartRef={chartRef}
          circuitData={circuitData}
          loading={loading}
          measurementTypesMap={measurementTypesMap}
          measurementUnits={measurementUnits}
          timezone={siteTimezone}
        />
      </PlotViewChartStyled>
      <FiltersSectionStyled>
        <FiltersPrimaryStyled>
          <MeasurementStyled>
            <FilterLabelStyled>Data Parameter:</FilterLabelStyled>
            <ListSelector
              isMulti={isRedaptive && !isBigBang}
              items={measurementItems}
              selectedItem={
                isRedaptive && !isBigBang ? null : selectedMeasurementItems[0]
              }
              selectedItems={
                isRedaptive && !isBigBang ? selectedMeasurementItems : []
              }
              updateValue={onUpdateMeasurement}
              updateValueMulti={onUpdateMeasurementMulti}
              unsettable={false}
              notSetLabelText='Select up to two parameters'
            />
          </MeasurementStyled>
          <DateRangeStyles>
            <FilterLabelStyled>Date Range:</FilterLabelStyled>
            <DateRangePicker
              customArrowIcon={<i className='ion-android-arrow-forward' />}
              endDate={dateRange.endDate}
              endDateId='channel-tags-plot-view-end'
              focusedInput={focusedInput}
              isOutsideRange={isOutsideRange}
              minimumNights={0}
              onDatesChange={onDatesChange}
              onFocusChange={setFocusedInput}
              showDefaultInputIcon
              startDate={dateRange.startDate}
              startDateId='channel-tags-plot-view-start'
            />
          </DateRangeStyles>
          <ResolutionStyled>
            <FilterLabelStyled>Resolution:</FilterLabelStyled>
            <ListSelector
              items={resolutionItems}
              selectedItem={currentResolutionItem}
              updateValue={onUpdateResolution}
              unsettable={false}
            />
          </ResolutionStyled>
          {!loading && !error && (
            <ButtonStyles>
              <ButtonLink type='button' onClick={downloadCSV}>
                Download CSV
              </ButtonLink>
            </ButtonStyles>
          )}
        </FiltersPrimaryStyled>
        <FiltersSecondaryStyled>
          {loading && (
            <>
              <SpinnerStyled>
                <Spinner inline size='tiny' />
              </SpinnerStyled>
              Loading...
            </>
          )}
          <PlotItButtonStyled>
            <Button
              disabled={plotItButtonDisabled}
              onClick={() => onPlotItSubmit(uncleansed)}
              primary
            >
              Plot It
            </Button>
          </PlotItButtonStyled>
        </FiltersSecondaryStyled>
      </FiltersSectionStyled>
      <GridWrapperStyled>
        <GridStyled>
          <SectionFirstStyled>
            <PlotViewFieldList
              editMode={editMode}
              fields={editMode ? fieldsEditCol1 : fieldsCol1}
            />
          </SectionFirstStyled>
          <SectionMiddleStyled>
            <PlotViewFieldList
              editMode={editMode}
              fields={editMode ? fieldsEditCol2 : fieldsCol2}
            />
          </SectionMiddleStyled>
          <SectionLastStyled>
            <PlotViewFieldList
              editMode={editMode}
              fields={editMode ? fieldsEditCol3 : fieldsCol3}
              handleClick={handleClick}
            />
          </SectionLastStyled>
        </GridStyled>
      </GridWrapperStyled>
      {modalNoDataVisible && (
        <PlotViewModal message={noDataMessage} goBack={closeNoDataModal} />
      )}
      {modalErrorVisible && (
        <PlotViewModal message={error} goBack={closeErrorModal} />
      )}
    </MainWrapperStyled>
  )
}

ChannelTagsPlotView.defaultProps = {
  editMode: false,
}

const mapDispatchToProps = (dispatch) => ({
  actions: { ...bindActionCreators(circuitsDataActions, dispatch) },
})

const mapStateToProps = (state, props) => ({
  circuitData: selectCircuitData(state, props.data.circuitId),
  circuitDataMeta: selectCircuitDataMeta(state, props.data.circuitId),
})

export default connect(mapStateToProps, mapDispatchToProps)(ChannelTagsPlotView)
