import { FormikHelpers, useFormik } from 'formik'
import moment from 'moment-timezone'
import { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import styled from 'styled-components'
import { boolean, number, object, string } from 'yup'

import AuditInfoForm from './AuditInfoForm'
import { PanelFieldValues } from './commons'
import { Step } from './models'
import PanelConfigForm from './PanelConfigForm'
import PanelCreationStepper from './PanelCreationStepper'
import PanelDescriptionForm from './PanelDescriptionForm'
import PanelLayoutForm from './PanelLayoutForm'
import PanelPhotosForm from './PanelPhotosForm'
import { findArrayType } from './utils'
import { actions as messageActions } from '../../ducks/messages'
import { FTModalPanelForm3 } from '../../ducks/modal'
import {
  actions as panelCircuitActions,
  FTPanelCircuitMetaState,
  selectPanelCircuitsMeta,
} from '../../ducks/panelCircuits'
import {
  FTPanelMetaDataState,
  selectPanelMetaData,
} from '../../ducks/panelPhotos'
import {
  actions as panelActions,
  FTAddPanelAction,
  FTPanel,
  FTPanelState,
  FTPhotoObject,
  selectPanelListEntity,
} from '../../ducks/panels'
import { selectSiteEntity } from '../../ducks/sites'
import { zIndices } from '../../utils'
import { colors } from '../../utils/themes'
import Button2 from '../Button2'
import Spinner from '../Spinner'

const PanelCreateModalStyled = styled.div`
  backdrop-filter: blur(4px);
  background-color: rgba(200, 200, 200, 0.5);
  color: ${colors.blue3};
  font-family: 'Public Sans', sans-serif;
  letter-spacing: 0.02em;
  height: 100vh;
  overflow-y: auto;
  position: fixed;
  top: 0;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: ${zIndices.ModalScenario};
  * {
    box-sizing: border-box;
  }
`

const ModalContentWrapperStyled = styled.div`
  width: 982px;
  height: 80vh;
  background-color: white;
  display: flex;
  flex-direction: column;
`

const ModalHeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
  border-bottom: 1px solid ${colors.gray3};
  height: 48px;
  p {
    color: #6c6d6e;
    font-family: 'Avenir Next';
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    text-transform: uppercase;
  }
`

const CloseIconStyled = styled.div`
  color: #464a54;
  cursor: pointer;
  font-size: 24px;
  text-decoration: none;
  & .ion-android-close {
    color: #4a4a4a;
  }
`

const ModalBodyStyled = styled.div<{
  error: string | null
}>`
  height: calc(
    80vh - 48px - 60px
      ${({ error }) => (error && error.length > 0 ? '- 40px' : '- 0px')}
  );
  display: flex;
  flex-direction: row;
`

const StepperContainer = styled.div`
  width: 220px;
  display: flex;
  justify-content: center;
  border-right: 1px solid #dbdbdb;
  padding: 24px 0;
`

const StepBodyContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 24px;
  overflow-y: auto;
  height: 100%;
  Form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`

const ModalFooterStyled = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 60px;
  padding: 0 24px;
  border-top: 1px solid #dbdbdb;
  & button {
    font-family: 'Public Sans';
    margin-left: 8px;
    height: 40px;
    font-size: 12px;
    font-weight: 600;
    width: 150px;
  }
`

const ClearBtnStyled = styled.div<{
  disabled?: boolean
}>`
  display flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 600;
  height: 40px;
  color: #6C6D6E;
  margin-right: 12px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  opacity:${({ disabled }) => (disabled ? '0.5' : '1')};
`

const ErrorContainerStyled = styled.div`
  background-color: #f8d7da;
  color: #721c24;
  height: 40px;
  padding: 0px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  p {
    margin: 0;
    font-size: 14px;
    color: #721c24;
  }
  ${ClearBtnStyled} {
    color: #721c24;
    pointer: cursor;
  }
`

const LoadingOverlay = styled.div`
  background-color: rgba(255, 255, 255, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: ${zIndices.ModalScenario + 1};
`

const ConfirmOverlayStyled = styled.div`
  background-color: rgba(255, 255, 255, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: ${zIndices.ModalScenario + 1};
`

const ConfirmDialogStyled = styled.div`
  background-color: white;
  border-radius: 4px;
  padding: 30px 25px;
  width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  p {
    color: #4a4a4a;
    font-family: 'Avenir Next';
    font-style: normal;
    font-weight: 600;
    text-align: center;
    font-size: 16px;
    margin-bottom: 16px;
  }
`
const DialogBtnContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`

type AddPanelModalProps = {
  actions: typeof panelActions &
    typeof panelCircuitActions &
    typeof messageActions
  allPanels: FTPanel[]
  addLoading: boolean
  updateLoading: boolean
  updateLoaded: boolean
  uploadingPhotos: boolean
  uploadingPhotosStatus: boolean
  deletePhotosLoading: boolean
  deletePhotosLoaded: boolean
  deleteLoading: boolean
  deleteComplete: boolean
  deleteError: string
  siteId: string
  addedPanelId: string
  circuitsMeta: FTPanelCircuitMetaState
  closeModal: () => void
  handleSuccess: (panelId?: string) => void
  panelMetaData: FTPanelMetaDataState
  site: Record<string, string>
} & FTModalPanelForm3

const currentDate = new Date()
const ModalPanel3 = (props: AddPanelModalProps) => {
  const initialValues: PanelFieldValues = {
    name: '',
    description: '',
    opportunityId: '',
    floorNumber: '',
    location: '',
    networkAvailability: true,
    networkStrength: '',
    type: '',
    voltage: '',
    amperage: '',
    isNumbered: true,
    powerSourceLevel: '',
    parentPanelId: '',
    numberOfCircuits: 0,
    leftSidePanel: '',
    rightSidePanel: '',
    phasingPattern: '',
    numberOfSwitches: 0,
    auditorName: '',
    auditDate: `${moment(new Date()).utc().format('YYYY-MM-DDTHH:mm:ss')}Z`,
    auditNotes: '',
  }
  const {
    allPanels,
    addLoading,
    uploadingPhotos,
    uploadingPhotosStatus,
    deletePhotosLoading,
    deletePhotosLoaded,
    deleteComplete,
    deleteError,
    updateLoading,
    updateLoaded,
    handleSuccess,
    closeModal,
    addedPanelId,
    siteId,
    circuitsById,
    meterIds,
    updateMode,
    panelById,
    site,
    panelMetaData: { photosDownloadUrl },
    circuitsMeta: {
      deleteLoading: deleteCircuitLoading,
      deleteComplete: deleteCircuitComplete,
    },
    actions: {
      addPanel,
      updatePanel,
      setTempData,
      uploadPhotos,
      deletePanelCircuits,
      deletePhotos,
      resetPanel,
      showMessage,
      hideMessage,
    },
  } = props

  const [error, setError] = useState<string | null>(null)

  const [photos, setPhotos] = useState<FTPhotoObject[]>([])
  const [photoIdsToDelete, setPhotoIdsToDelete] = useState<string[]>([])

  const [activeStep, setActiveStep] = useState(0)
  const [showStepperError, setShowStepperError] = useState(false)
  const [isConfirming, setIsConfirming] = useState(false)
  const [updateSteps, setUpdateSteps] = useState(['panel'])

  const firstUpdate = useRef(true)

  const circuits =
    circuitsById ?
      Object.keys(circuitsById).map((key) => circuitsById[key])
    : []
  const { item: siteItem = {} } = site || {}
  const { contracts = [] } = siteItem || {}
  const validOpportunities = contracts.map(
    (item: { opportunityId: string }) => item?.opportunityId,
  )

  // if update mode then remove the current panel from allPanels
  if (updateMode && panelById) {
    const currentPanelIndex = allPanels.findIndex((p) => p.id === panelById.id)
    if (currentPanelIndex > -1) {
      allPanels.splice(currentPanelIndex, 1)
    }
  }

  const isMetered = meterIds && meterIds.length > 0
  let initialAuditDate = currentDate
  if (updateMode && panelById) {
    initialValues.name = panelById.name
    initialValues.description = panelById.description || ''
    initialValues.opportunityId = panelById.opportunityId || ''
    initialValues.floorNumber = panelById.floorNumber || ''
    initialValues.location = panelById.location || ''
    initialValues.networkAvailability =
      panelById.networkAvailability === undefined ?
        undefined
      : panelById.networkAvailability
    initialValues.networkStrength = panelById.networkStrength || ''
    initialValues.type = panelById.type || ''
    initialValues.voltage = panelById.voltage || ''
    initialValues.amperage = panelById.amperage || ''
    initialValues.isNumbered = !!panelById.isNumbered
    initialValues.powerSourceLevel = panelById.powerSourceLevel || ''
    initialValues.parentPanelId = panelById.parentPanelId || ''
    initialValues.numberOfSwitches = panelById.isNumbered ? 0 : circuits.length
    initialValues.auditorName = panelById.auditorName || ''
    // find user timezone using moment-timezone
    const userTimezone = moment.tz.guess()
    // convert date to local timezone
    const localDate = moment.utc(panelById.auditDate).tz(userTimezone)
    initialAuditDate = localDate.toDate()
    initialValues.auditNotes = panelById.auditNotes || ''

    if (panelById.isNumbered) {
      initialValues.numberOfCircuits = circuits.length
      const leftCircuits = circuits.filter((c) => c.sideBreakerOrder === 'LEFT')
      const rightCircuits = circuits.filter(
        (c) => c.sideBreakerOrder === 'RIGHT',
      )
      // leftCircuits array and rightCircuits has breakerNumber property we have to determine if the breakerNumber property are all odd or even or in a consecutive pattern
      const leftCircuitNumbers = leftCircuits.map((c) => c.breakerNumber)
      const rightCircuitNumbers = rightCircuits.map((c) => c.breakerNumber)

      initialValues.leftSidePanel = findArrayType(leftCircuitNumbers) || ''
      initialValues.rightSidePanel = findArrayType(rightCircuitNumbers) || ''
      if (circuits && circuits.length > 0) {
        initialValues.phasingPattern =
          circuits[0].phasingPattern || 'TOP_TO_BOTTOM'
      }
    }
  }

  const formValidationSchema = object({
    name: string().required('Name is required'),
    description: string().required('Description is required'),
    opportunityId: string()
      .required('Opportunity ID is required')
      .oneOf(validOpportunities, 'Opportunity id does not belong to the site'),
    floorNumber: string().required('Floor Number is required'),
    location: string().required('Location is required'),
    networkAvailability: boolean().required('Network Availability is required'),
    networkStrength: string()
      .required('Signal Strength is required')
      .nullable(),
    type: string().required('Panel Type is required').nullable(),
    voltage: string().required('Voltage is required').nullable(),
    amperage: string().nullable(),
    isNumbered: boolean().required('Panel Slot Numbering is required'),
    powerSourceLevel: string().required('Power Source is required'),
    parentPanelId: string()
      .when('powerSourceLevel', {
        is: 'MDP',
        then: string().required('Please select a power source').nullable(),
      })
      .when('powerSourceLevel', {
        is: 'SUB_PANEL',
        then: string().required('Please select a power source').nullable(),
      })
      .nullable(),
    numberOfCircuits:
      !updateMode ?
        number()
          .when('isNumbered', {
            is: true,
            then: number()
              .required('Number of Circuits is required')
              .min(2, 'Number of Circuits must be greater than 1')
              .nullable(),
          })
          .nullable()
      : number().nullable(),
    leftSidePanel:
      !updateMode ?
        string()
          .when('isNumbered', {
            is: true,
            then: string().required('Left Side Panel is required'),
          })
          .nullable()
      : string().nullable(),
    rightSidePanel:
      !updateMode ?
        string()
          .when('isNumbered', {
            is: true,
            then: string().required('Right Side Panel is required'),
          })
          .nullable()
      : string().nullable(),
    phasingPattern:
      !updateMode ?
        string()
          .when('isNumbered', {
            is: true,
            then: string().required('Phaing Pattern is required'),
          })
          .nullable()
      : string().nullable(),
    numberOfSwitches:
      !updateMode ?
        number()
          .when('isNumbered', {
            is: false,
            then: number()
              .required('Number of Switches is required')
              .min(1, 'Number of Switches must be greater than 0')
              .nullable(),
          })
          .nullable()
      : number().nullable(),
    auditorName: string().nullable(),
    auditDate: string().nullable(),
    auditNotes: string().nullable(),
  })

  const formik = useFormik<PanelFieldValues>({
    initialValues,
    validationSchema: formValidationSchema,
    onSubmit: (values: PanelFieldValues) => {
      // check which fields are changed
      const currentPanelNameAndLocation = `${values.name.toLocaleLowerCase()}${values.location.toLocaleLowerCase()}`

      if (updateMode && panelById) {
        const currentPanelIndex = allPanels.findIndex(
          (p) => p.id === panelById.id,
        )
        if (currentPanelIndex > -1) {
          allPanels.splice(currentPanelIndex, 1)
        }
      }
      const panelExists = allPanels.some((panel) => {
        const panelNameAndLocation = `${panel.name.toLocaleLowerCase()}${(
          panel.location || ''
        ).toLocaleLowerCase()}`
        return panelNameAndLocation === currentPanelNameAndLocation
      })

      if (panelExists) {
        showMessage({
          messageId: 'duplicatePanelMessage',
          title: 'Duplicate panel cannot be added in same location.',
          type: 'error',
          position: 'fixed',
        })
        return
      }

      const changedFields = Object.keys(values).filter(
        (key) => values[key] !== initialValues[key],
      )

      // convert values type to FTAddPanelAction
      const valuesToSubmit: FTAddPanelAction = {
        siteId,
        name: values.name,
        description: values.description,
        opportunityId: values.opportunityId,
        floorNumber: values.floorNumber,
        location: values.location,
        networkAvailability: values.networkAvailability,
        networkStrength: values.networkStrength ?? undefined,
        type: values.type,
        voltage: values.voltage,
        amperage: values.amperage,
        isNumbered: values.isNumbered,
        powerSourceLevel: values.powerSourceLevel,
        parentPanelId: values.parentPanelId as string,
        auditorName: values.auditorName,
        auditDate: values.auditDate,
        auditNotes: values.auditNotes,
      }
      if (panelById && panelById.id) {
        valuesToSubmit.id = panelById.id
        delete valuesToSubmit.siteId
        if (!changedFields.includes('isNumbered')) {
          delete valuesToSubmit.isNumbered
        }
        if (isMetered) {
          delete valuesToSubmit.voltage
          delete valuesToSubmit.type
          delete valuesToSubmit.isNumbered
        }
        updatePanel(valuesToSubmit)
      } else {
        addPanel(valuesToSubmit)
      }

      const tempMeta = {
        isNumbered: values.isNumbered,
        numberOfCircuits: values.numberOfCircuits,
        leftSidePanel: values.leftSidePanel,
        rightSidePanel: values.rightSidePanel,
        phasingPattern: values.phasingPattern,
        numberOfSwitches: values.numberOfSwitches,
        fromPanelModal: true,
        photosUpdated: photos.length > 0,
      }
      setTempData(tempMeta)
    },
  })

  const resetPageState = () => {
    setPhotos([])
    setPhotoIdsToDelete([])
    setActiveStep(0)
    setShowStepperError(false)
    setError(null)
    setIsConfirming(false)
    setUpdateSteps(['panel'])
  }

  const handleRadioButtonChange = (e: Event) => {
    const event = e as unknown as React.ChangeEvent<HTMLInputElement>
    const { name, value } = event.target

    let parsedValue
    if (value === 'true') {
      parsedValue = true
    } else if (value === 'false') {
      parsedValue = false
    } else {
      parsedValue = value
    }
    formik.setFieldValue(name, parsedValue).then(() => {
      formik.setFieldTouched(name, true)
    })
    formik.validateForm()
  }

  const hasValidationError = (stepIndex: number) => {
    switch (stepIndex) {
      case 0:
        return (
          formik.errors.name ||
          formik.errors.description ||
          formik.errors.opportunityId ||
          formik.errors.floorNumber ||
          formik.errors.location ||
          formik.errors.networkAvailability ||
          formik.errors.networkStrength
        )
      case 1:
        return (
          formik.errors.type ||
          formik.errors.voltage ||
          formik.errors.powerSourceLevel ||
          ((formik.values.powerSourceLevel === 'MDP' ||
            formik.values.powerSourceLevel === 'SUB_PANEL') &&
            formik.errors.parentPanelId) ||
          formik.errors.isNumbered
        )
      case 2:
        return formik.values.isNumbered ?
            formik.errors.numberOfCircuits ||
              formik.errors.leftSidePanel ||
              formik.errors.rightSidePanel ||
              formik.errors.phasingPattern
          : formik.errors.numberOfSwitches
      default:
        return false
    }
  }
  let steps: Step[] = [
    {
      label: 'Panel Description',
      active: true,
      hasValidationErrors: !!hasValidationError(0),
      componentToRender: (
        <PanelDescriptionForm
          formik={formik}
          handleRadioButtonChange={handleRadioButtonChange}
        />
      ),
    },
    {
      label: 'Panel Configuration',
      hasValidationErrors: !!hasValidationError(1),
      active: false,
      componentToRender: (
        <PanelConfigForm
          updateMode={!!updateMode}
          initialValues={initialValues}
          isMetered={!!isMetered}
          formik={formik}
          panels={allPanels}
          handleRadioButtonChange={handleRadioButtonChange}
        />
      ),
    },
    {
      label: 'Panel Layout',
      hasValidationErrors: !!hasValidationError(2),
      active: false,
      componentToRender: (
        <PanelLayoutForm updateMode={!!updateMode} formik={formik} />
      ),
    },
    {
      label: 'Audit Info',
      hasValidationErrors:
        !updateMode ? !((panelById && panelById.id) || addedPanelId) : false,
      active: false,
      componentToRender: (
        <AuditInfoForm formik={formik} initialAuditDate={initialAuditDate} />
      ),
    },
    {
      label: 'Panel Photos',
      hasValidationErrors: false,
      active: false,
      componentToRender: (
        <PanelPhotosForm
          formik={formik}
          photos={updateMode ? photos : []}
          availablePhotos={updateMode ? photosDownloadUrl : []}
          photoIdsToDelete={updateMode ? photoIdsToDelete : []}
          setPhotoIdsToDelete={setPhotoIdsToDelete}
          panelId={addedPanelId || (panelById && panelById.id)}
          updateMode={updateMode}
          setPhotos={setPhotos}
          showMessage={showMessage}
        />
      ),
    },
  ]

  if (updateMode) {
    steps = steps.filter((step) => step.label !== 'Panel Layout')
  }

  const reInitCircuits = () => {
    const shouldDeleteCiruits =
      formik.touched.isNumbered &&
      formik.values.isNumbered !== initialValues.isNumbered &&
      circuits.length > 0
    if (shouldDeleteCiruits) {
      setUpdateSteps((prev: string[]) => [...prev, 'deleteCircuits'])
      deletePanelCircuits({ circuitIds: circuits.map((c) => c.id) })
    }
  }

  const handleCloseModalWithReset = () => {
    closeModal()
    resetPageState()
    resetPanel()
  }

  const handleSaveAndNext = () => {
    if (activeStep === 0) {
      // check for duplicate panel
      const currentPanelNameAndLocation = `${formik.values.name.toLocaleLowerCase()}${formik.values.location.toLocaleLowerCase()}`
      const panelExists = allPanels.some((panel) => {
        const panelNameAndLocation = `${panel.name.toLocaleLowerCase()}${(
          panel.location || ''
        ).toLocaleLowerCase()}`
        return panelNameAndLocation === currentPanelNameAndLocation
      })
      if (panelExists) {
        showMessage({
          messageId: 'duplicatePanelMessage',
          title: 'Duplicate panel cannot be added in same location.',
          type: 'error',
          position: 'fixed',
        })
        return
      }
    }
    if (!updateMode) {
      if (activeStep === steps.length - 2) {
        if (!formik.isValid) {
          setError('Please fill all required fields on highlighted sections.')
          setShowStepperError(true)
          return
        }
        formik.handleSubmit()
      } else if (activeStep === steps.length - 1) {
        if (!addedPanelId || addedPanelId === '') {
          setError('Please click save and next on panel details page first.')
          return
        }
        hideMessage('panelAddHintMessage')
        if (photos.length === 0) {
          handleSuccess(addedPanelId)
          closeModal()
          return
        }
        uploadPhotos(photos)
      } else {
        setActiveStep((currentStep) => currentStep + 1)
      }
      return
    }
    if (activeStep === steps.length - 1) {
      hideMessage('panelAddHintMessage')
      formik.setFieldError('isNumbered', undefined)
      formik.validateForm()
      if (!formik.isValid) {
        setError('Please fill all required fields.')
        return
      }
      formik.handleSubmit()
      // check if there are photos to upload
      if (photos.length > 0) {
        setUpdateSteps((prev: string[]) => [...prev, 'photos'])
        uploadPhotos(photos)
      }
      // check if there is any photo to delete
      if (photoIdsToDelete && photoIdsToDelete.length > 0) {
        setUpdateSteps((prev: string[]) => [...prev, 'deletePhotos'])
        deletePhotos(photoIdsToDelete)
      }

      // delete circuits if isNumbered changed
      reInitCircuits()
    } else {
      setActiveStep((currentStep) => currentStep + 1)
    }
  }

  useEffect(() => {
    if (addedPanelId) {
      setActiveStep(4)
    }
  }, [addedPanelId])

  useEffect(() => {
    if (updateMode && firstUpdate.current) {
      firstUpdate.current = false
      return
    }
    const stepStatuses: Record<string, boolean> = {
      panel: updateLoaded,
      photos: uploadingPhotosStatus,
      deletePhotos: deletePhotosLoaded,
      deleteCircuits: deleteCircuitComplete,
    }

    const count = updateSteps.reduce(
      (acc, step) => acc + (stepStatuses[step] ? 1 : 0),
      0,
    )

    if (count === updateSteps.length) {
      handleSuccess()
      closeModal()
    }
  }, [uploadingPhotosStatus, deletePhotosLoaded, updateLoaded, deleteComplete])

  useEffect(() => {
    if (uploadingPhotosStatus && !updateMode) {
      handleSuccess(addedPanelId)
      closeModal()
    }
  }, [uploadingPhotosStatus])

  useEffect(() => {
    formik.validateForm()
    if (activeStep < steps.length - 1) {
      hideMessage('panelAddHintMessage')
    }
  }, [activeStep])

  useEffect(() => () => resetPageState(), [])

  useEffect(() => {
    if (error && error.length > 0) {
      setTimeout(() => {
        setError(null)
      }, 3000)
    }
  }, [error])

  return (
    <PanelCreateModalStyled>
      {(addLoading ||
        uploadingPhotos ||
        deletePhotosLoading ||
        deleteCircuitLoading ||
        updateLoading) && (
        <LoadingOverlay>
          <Spinner />
        </LoadingOverlay>
      )}
      {isConfirming && (
        <ConfirmOverlayStyled>
          <ConfirmDialogStyled>
            <p>
              Are you sure you want to discard all changes? The information
              entered will be lost
            </p>
            <DialogBtnContainer>
              <Button2 onClick={() => setIsConfirming(false)}>No</Button2>
              <Button2 type='secondary' onClick={closeModal}>
                Yes
              </Button2>
            </DialogBtnContainer>
          </ConfirmDialogStyled>
        </ConfirmOverlayStyled>
      )}
      <ModalContentWrapperStyled>
        <ModalHeaderStyled>
          <p>{updateMode ? 'Edit ' : 'Add '}Panel</p>
          <CloseIconStyled
            onClick={() => {
              if (Object.keys(formik.touched).length > 0) {
                setIsConfirming(true)
                return
              }
              handleCloseModalWithReset()
            }}
          >
            <span className='ion-android-close' />
          </CloseIconStyled>
        </ModalHeaderStyled>
        {((error && !formik.isValid) ||
          (error && !addedPanelId) ||
          (deleteError && deleteError.length > 0)) && (
          <ErrorContainerStyled>
            <p>{error}</p>
            <CloseIconStyled onClick={() => setError(null)}>
              <span className='ion-android-close' />
            </CloseIconStyled>
          </ErrorContainerStyled>
        )}

        <ModalBodyStyled error={error}>
          <StepperContainer>
            <PanelCreationStepper
              showStepperError={showStepperError}
              disabled={!!addedPanelId}
              activeStepIndex={activeStep}
              setActiveStep={setActiveStep}
              steps={steps}
            />
          </StepperContainer>
          <StepBodyContainer>
            <form>{steps[activeStep].componentToRender}</form>
          </StepBodyContainer>
        </ModalBodyStyled>
        <ModalFooterStyled>
          <ClearBtnStyled
            onClick={() => {
              if (Object.keys(formik.touched).length > 0) {
                setIsConfirming(true)
                return
              }
              handleCloseModalWithReset()
            }}
          >
            <span>Cancel</span>
          </ClearBtnStyled>
          <Button2
            type='redaptivePrimary'
            disabled={hasValidationError(activeStep)}
            onClick={handleSaveAndNext}
          >
            Save & Next
          </Button2>
        </ModalFooterStyled>
      </ModalContentWrapperStyled>
    </PanelCreateModalStyled>
  )
}

const mapStateToProps = (state: FTPanelState, props) => ({
  ...selectPanelListEntity(state).meta,
  allPanels: selectPanelListEntity(state).allItems,
  panelsById: selectPanelListEntity(state).allById,
  panelMetaData: selectPanelMetaData(state),
  circuitsMeta: selectPanelCircuitsMeta(state),
  site: selectSiteEntity(state, props.siteId),
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: {
    ...bindActionCreators(panelActions, dispatch),
    ...bindActionCreators(panelCircuitActions, dispatch),
    ...bindActionCreators(messageActions, dispatch),
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(ModalPanel3)
