import moment from 'moment'
import { ComponentType, useCallback, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'

import {
  actions as billingItemsActions,
  BillingEntitiesState,
  selectBillingThisMonthItemsEntity,
} from '../../ducks/billing/billingThisMonth/billingItems'
import type { FTReRunSavingsModal } from '../../ducks/modal'
import { actions as modalActions } from '../../ducks/modal'
import { colors } from '../../utils/themes'
import Button2 from '../Button2'
import KeyboardDoubleArrowDownIcon from '../Icons/KeyboardDoubleArrowDownIcon'
import KeyboardDoubleArrowUpIcon from '../Icons/KeyboardDoubleArrowUpIcon'
import {
  ModalConfirmBodyStyled,
  ModalConfirmButtonsStyled,
  ModalConfirmStyled,
  ModalConfirmTitleStyled,
  ModalConfirmWrapperStyled,
} from '../ModalConfirm2'
import RedaptiveReactTable7, {
  ReactTableWrapperStyles,
  RedaptiveReactTableStyles,
} from '../RedaptiveReactTable7'

type FTProps = {
  actions: Record<string, any>
  loading: boolean
} & FTReRunSavingsModal
const tableMarginBottom = 130
const itemsToggleHeight = 43
const ModalTitleWrapperStyled = styled.div`
  padding: 20px 20px 0px 20px;

  ${ModalConfirmTitleStyled} {
    padding: 0;

    & > span {
      color: ${colors.blue};
    }
  }
`
const SubTitleStyled = styled.h5`
  color: ${colors.blue5};
  font-family: 'Public Sans', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  margin: 10px 24px;
  text-align: center;
`
const TableWrapperStyled = styled.div`
  font-size: 12px;

  ${RedaptiveReactTableStyles} {
    margin: 0 20px;
  }
  ${ReactTableWrapperStyles} {
    overflow-x: auto;
    border: 1px solid #e0e0e0;
    max-height: 195px;
  }
`
const ItemsToggleStyled: ComponentType<{
  itemsVisible: boolean
}> = styled.div`
  align-items: center;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.8) 0%,
    #ffffff 100%
  );
  bottom: ${({ itemsVisible }) =>
    itemsVisible ?
      `${tableMarginBottom - itemsToggleHeight}px`
    : `${tableMarginBottom}px`};
  color: ${colors.blue2};
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-weight: 600;
  height: ${itemsToggleHeight}px;
  justify-content: center;
  letter-spacing: 0.02em;
  width: 100%;
`
const ItemsToggleIconStyled = styled.div`
  margin-right: 6px;
  position: relative;
  top: 2px;
`
const columns = [
  {
    accessor: 'opportunityId',
    Header: 'Opportunity ID',
  },
  {
    accessor: 'name',
    Header: 'Opportunity Name',
  },
]

const ReRunSavingsModal = ({
  actions,
  loading,
  savingsMonth,
  title,
  subTitle,
  billingSites,
  setReRunSavingsSubmitted,
}: FTProps) => {
  const [isToggleItemsActive, setIsToggleItemsActive] = useState(true)
  const tableData = useMemo(
    () => (isToggleItemsActive ? billingSites : billingSites.slice(0, 3)),
    [isToggleItemsActive],
  )

  const handleClick = () => {
    actions.hideModal()

    if (billingSites) {
      setReRunSavingsSubmitted(true)
      // $FlowFixMe
      actions.reCalculateSavings({
        billIds: billingSites.map((key) => key.id),
        savingsYearMonth: savingsMonth,
      })
    }
  }

  const handleItemsToggleClick = useCallback(() => {
    setIsToggleItemsActive(!isToggleItemsActive)
  }, [isToggleItemsActive])
  return (
    <ModalConfirmWrapperStyled>
      <ModalConfirmStyled>
        <ModalTitleWrapperStyled>
          {!!billingSites && (
            <ModalConfirmTitleStyled>{title}</ModalConfirmTitleStyled>
          )}
        </ModalTitleWrapperStyled>
        <ModalConfirmBodyStyled>
          <SubTitleStyled>{subTitle}</SubTitleStyled>
          {!!billingSites && (
            <TableWrapperStyled>
              <RedaptiveReactTable7
                columns={columns}
                data={tableData}
                globalFilterable={false}
              />
              {billingSites.length > 2 && (
                <ItemsToggleStyled
                  itemsVisible={isToggleItemsActive}
                  onClick={handleItemsToggleClick}
                >
                  {isToggleItemsActive ?
                    <>
                      <ItemsToggleIconStyled>
                        <KeyboardDoubleArrowUpIcon />
                      </ItemsToggleIconStyled>
                      Hide
                    </>
                  : <>
                      <ItemsToggleIconStyled>
                        <KeyboardDoubleArrowDownIcon />
                      </ItemsToggleIconStyled>
                      Show All
                    </>
                  }
                </ItemsToggleStyled>
              )}
            </TableWrapperStyled>
          )}
        </ModalConfirmBodyStyled>
        <ModalConfirmButtonsStyled>
          <Button2
            onClick={() => actions.hideModal()}
            loading={loading}
            type='transparent'
          >
            Cancel
          </Button2>
          <Button2 onClick={handleClick} loading={loading} type='primary'>
            Re-Run
          </Button2>
        </ModalConfirmButtonsStyled>
      </ModalConfirmStyled>
    </ModalConfirmWrapperStyled>
  )
}

const mapDispatchToProps = (dispatch) => ({
  actions: {
    ...bindActionCreators(billingItemsActions, dispatch),
    ...bindActionCreators(modalActions, dispatch),
  },
})

const mapStateToProps = (state: BillingEntitiesState) => {
  const {
    reCalculateSavingsMeta: { loading },
  } = selectBillingThisMonthItemsEntity(state)
  return {
    loading,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReRunSavingsModal)
