import { FilterValue, IdType, Row } from 'react-table'
import { TSEnergyStarSubmissions } from '../../../../ducks/energyStarIntegration/types'
import { columnIds, DeltaFilterValueEnum } from '../../helpers/utils'
import { isValueSet } from '../../../../utils'

const MultipleSelectFilterFunction = (
  rows: Array<Row<TSEnergyStarSubmissions>>,
  [id]: Array<IdType<TSEnergyStarSubmissions>>,
  filterValue: FilterValue,
) => {
  if (!filterValue || !filterValue.length) return rows

  if (id === columnIds.DeltaEnergyConsumption) {
    if (filterValue[0] === DeltaFilterValueEnum['N/A']) {
      return rows.filter(
        ({ original: { deltaEnergyConsumption } }) =>
          deltaEnergyConsumption === null,
      )
    }

    const maxValue = Math.max(...filterValue)
    return rows.filter(({ original: { deltaEnergyConsumption } }) => {
      if (deltaEnergyConsumption === null) return false
      if (maxValue > 50) {
        return (
          Math.abs(deltaEnergyConsumption) <= maxValue &&
          Math.abs(deltaEnergyConsumption) > 50
        )
      }
      return Math.abs(deltaEnergyConsumption) <= maxValue
    })
  }

  if (id === columnIds.DataAvailability) {
    const minValue = Math.min(...filterValue)

    if (minValue < 90) {
      return rows.filter(
        ({ original: { dataAvailability } }) =>
          Math.abs(
            isValueSet(dataAvailability) ?
              Math.floor((dataAvailability ?? 0) * 100)
            : 0,
          ) < minValue,
      )
    }

    return rows.filter(
      ({ original: { dataAvailability } }) =>
        Math.abs(
          isValueSet(dataAvailability) ?
            Math.floor((dataAvailability ?? 0) * 100)
          : 0,
        ) >= minValue,
    )
  }

  return rows.filter(({ original }) =>
    filterValue.includes(original[id as keyof TSEnergyStarSubmissions]),
  )
}

export default MultipleSelectFilterFunction
