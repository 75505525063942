import { useSelector } from 'react-redux'
import {
  HeaderTablesStyles,
  InlineNotVerifiedIcon,
  InlineVerifiedIcon,
} from './styles'
import VerticalTable from '../VerticalTable'
import StatusIconItem from '../StatusIconItem'
import StyledLink from '../StyledLink'
import { selectMeterEntity, FTState } from '../../ducks/meters'

type PropsType = {
  meterId: string
  pathname: string
  siteId: string
}

export default function MeterInfo({ meterId, pathname, siteId }: PropsType) {
  const meterEntity = useSelector((state: FTState) =>
    selectMeterEntity(state, meterId),
  )
  const { item: meter } = meterEntity
  const getMeterDetailFieldsFirst = () => {
    const { source } = meter
    const isRedaptive = source === 'REDAPTIVE'
    const customerLink = `/account-management/customers/${meter.customerId}`
    const siteLink =
      siteId ?
        pathname.replace(/\/sites\/.*$/, `/sites/${meter.siteId}`)
      : pathname.replace(/\/meters\/.*$/, `/sites/${meter.siteId}`)
    return [
      {
        label: 'Meter Identifier',
        value: meter.name,
      },
      {
        label: 'Customer',
        value: meter.customerName,
        renderField: () => (
          <StyledLink href={customerLink}>{meter.customerName}</StyledLink>
        ),
      },
      {
        label: 'Site',
        value: meter.siteName,
        renderField: () => (
          <StyledLink href={siteLink}>{meter.siteName}</StyledLink>
        ),
      },
      {
        label: 'Display',
        value: meter.display,
        renderField: () =>
          meter.display ? <div>{meter.display}</div> : <div>Not Available</div>,
      },
      {
        label: 'Description',
        value: meter.description,
      },
      {
        label: 'Meter Type',
        value: meter.meterType,
      },
      {
        label: 'Model',
        value: meter.model,
      },
      {
        label: 'Generation',
        value: isRedaptive ? meter.generationName : `N/A (${meter.meterType})`,
      },
      {
        label: 'Opportunity',
        value:
          meter?.opportunityId ?
            `${meter.opportunityId} ${
              meter.opportunityType ? `(${meter.opportunityType})` : ''
            }`
          : 'Not Available',
      },
    ]
  }

  const getMeterDetailFieldsSecond = () => {
    const { source } = meter
    const isRedaptive = source === 'REDAPTIVE' || source === 'REDAPTIVE_GAS'
    const electronVerifiedField =
      isRedaptive ?
        [
          {
            label: 'Electron Verified',
            value:
              meter.verified ?
                <>
                  <InlineVerifiedIcon />
                  {' Yes'}
                </>
              : <>
                  <InlineNotVerifiedIcon />
                  {' No'}
                </>,
          },
        ]
      : []
    const electronVerifiedDateField =
      isRedaptive ?
        [
          {
            label: 'Electron Verified Date',
            value: meter.verifiedDateFormatted || 'Not Verified',
          },
        ]
      : []
    return [
      {
        label: 'Online Status',
        value: meter.meterStatus,
        renderField: () => <StatusIconItem status={meter.meterOnline} />,
      },
      {
        label: 'Field Status',
        value: meter.active,
        renderField: () => (
          <StatusIconItem
            status={meter.active}
            labelText={
              meter.active ? 'Active' : meter.deactivationReason || 'Other'
            }
          />
        ),
      },
      ...electronVerifiedField,
      {
        label: 'Labeled Channels',
        value: `${meter.labeledChannels} / ${meter.totalChannels} total`,
      },
      {
        label: 'First Configuration Date',
        value: meter.firstConfigDate,
      },
      {
        label: 'Most Recent Configuration Date',
        value: meter.mostRecentConfigurationDate,
      },
      ...electronVerifiedDateField,
      {
        label: 'Most Recent Salesforce Update',
        value: meter.dateMostRecentSalesforceUpdate,
      },
      {
        label: 'Most Recent Data Report',
        value: meter.lastReportDate,
      },
    ]
  }
  const fieldsFirst = meter ? getMeterDetailFieldsFirst() : []
  const fieldsSecond = meter ? getMeterDetailFieldsSecond() : []
  return (
    <HeaderTablesStyles>
      <VerticalTable.Basic fields={fieldsFirst} columned={false} />
      <VerticalTable.Basic fields={fieldsSecond} columned={false} />
    </HeaderTablesStyles>
  )
}
