import { useSelector } from 'react-redux'
import Breadcrumbs from '../../Breadcrumbs'
import {
  selectCustomerEntity,
  FTState as CustomerEntityType,
} from '../../../ducks/customers'
import {
  selectSiteEntity,
  FTState as SiteEntityType,
} from '../../../ducks/sites'

type Props = {
  customerId: string
  url: string
  siteId: string
}

const SiteBreadcrumbs = ({ customerId, url, siteId }: Props) => {
  const customerEntity = useSelector((state: CustomerEntityType) =>
    selectCustomerEntity(state, customerId),
  )
  const siteEntity = useSelector((state: SiteEntityType) =>
    selectSiteEntity(state, siteId),
  )
  const { item: site } = siteEntity
  let items

  if (customerId && customerEntity.item) {
    const { item: customer } = customerEntity
    items = [
      {
        href: '/account-management',
        text: 'Accounts',
      },
      {
        href: '/account-management/customers',
        text: 'Customers',
      },
      {
        href: `/account-management/customers/${customerId}`,
        text: customer.name,
      },
    ]
  } else {
    items = [
      {
        href: '/account-management',
        text: 'Accounts',
      },
      {
        href: '/account-management/sites',
        text: 'Sites',
      },
    ]
  }

  items = [
    ...items,
    {
      href: url,
      text: (site && site.validName) || '',
    },
  ]
  return <Breadcrumbs items={items} />
}

export default SiteBreadcrumbs
