import moment from 'moment'
import {
  CellProps,
  FilterType,
  Renderer,
  UseFiltersColumnOptions,
} from 'react-table'

import { DropdownValues } from '../../../components/ESSiteStatus/staticData'
import { TSColumnType } from '../../../components/RedaptiveReactTable7'
import { TSEnergyStarSubmissions } from '../../../ducks/energyStarIntegration/types'
import { colors } from '../../../utils/themes'

export const tabColumns = ({
  MutipleSelectFilter,
  MultipleSelectFilterFunction,
  RenderCustomCell,
}: {
  MutipleSelectFilter: Pick<
    UseFiltersColumnOptions<TSEnergyStarSubmissions>,
    'Filter'
  >
  MultipleSelectFilterFunction: FilterType<TSEnergyStarSubmissions>
  RenderCustomCell: Renderer<CellProps<TSEnergyStarSubmissions>>
}): TSColumnType<TSEnergyStarSubmissions>[] => [
  {
    accessor: 'customerName',
    Header: 'Customer',
    filter: MultipleSelectFilterFunction,
    Filter: MutipleSelectFilter,
    hideSettings: false,
    id: 'customerName',
  },
  {
    accessor: 'siteName',
    Header: 'Site',
    filter: MultipleSelectFilterFunction,
    Filter: MutipleSelectFilter,
    hideSettings: false,
    id: 'siteName',
  },
  {
    accessor: 'status',
    Header: 'Status',
    filter: MultipleSelectFilterFunction,
    Filter: MutipleSelectFilter,
    Cell: RenderCustomCell,
    hideSettings: false,
    id: 'status',
  },
  {
    accessor: 'resourceType',
    Header: 'Resource',
    filter: MultipleSelectFilterFunction,
    Filter: MutipleSelectFilter,
    hideSettings: false,
    Cell: RenderCustomCell,
    id: 'resourceType',
  },
  {
    accessor: 'dataAggregation',
    Header: 'Aggregation',
    filter: MultipleSelectFilterFunction,
    Filter: MutipleSelectFilter,
    hideSettings: false,
    id: 'dataAggregation',
    minWidth: 200,
  },
  {
    accessor: 'dataSource',
    Header: 'Data Source',
    filter: MultipleSelectFilterFunction,
    Filter: MutipleSelectFilter,
    hideSettings: false,
    Cell: RenderCustomCell,
    id: 'dataSource',
  },
  {
    accessor: 'dataAvailability',
    Header: 'Data Availability',
    filter: MultipleSelectFilterFunction,
    Filter: MutipleSelectFilter,
    Cell: RenderCustomCell,
    hideSettings: false,
    id: 'dataAvailability',
  },
  {
    accessor: 'deltaEnergyConsumption',
    Header: 'Delta',
    filter: MultipleSelectFilterFunction,
    Filter: MutipleSelectFilter,
    Cell: RenderCustomCell,
    hideSettings: false,
    id: 'deltaEnergyConsumption',
  },
  {
    accessor: 'currentMonthEnergyConsumption',
    Header: 'This Period',
    filter: '',
    hideSettings: false,
    Cell: RenderCustomCell,
    id: 'currentMonthEnergyConsumption',
  },
  {
    accessor: 'previousMonthEnergyConsumption',
    Header: 'Previous Period',
    filter: '',
    hideSettings: false,
    Cell: RenderCustomCell,
    id: 'previousMonthEnergyConsumption',
  },
]

export const tabsEnum = {
  NOT_SUBMITTED: 'not-submitted',
  SUBMITTED: 'submitted',
}
export const statusEnum = {
  NOT_SUBMITTED: 'NOT_SUBMITTED',
  SUBMITTED: 'SUCCESS',
  FAILED: 'FAILED',
  IN_PROGRESS: 'IN_PROGRESS',
}
export const tabs = [
  {
    text: 'Not Submitted',
    tabId: tabsEnum.NOT_SUBMITTED,
  },
  {
    text: 'Submitted',
    tabId: tabsEnum.SUBMITTED,
  },
]
export const columnIds = {
  ID: 'id',
  CustomerID: 'customerId',
  SiteID: 'siteId',
  EnergyStarMeterID: 'energyStarMeterId',
  ReportMonthlyCycle: 'reportMonthlyCycle',
  CustomerName: 'customerName',
  SiteName: 'siteName',
  ResourceType: 'resourceType',
  DataSource: 'dataSource',
  Status: 'status',
  DataAvailability: 'dataAvailability',
  CurrentMonthEnergyConsumption: 'currentMonthEnergyConsumption',
  DeltaEnergyConsumption: 'deltaEnergyConsumption',
  PreviousMonthEnergyConsumption: 'previousMonthEnergyConsumption',
  CurrentStage: 'currentStage',
  CaseId: 'caseId',
  CaseStatus: 'caseStatus',
  OpportunityId: 'opportunityId',
  CaseStartDate: 'caseStartDate',
  CaseAssignedTo: 'caseAssignedTo',
  MeterList: 'meterList',
}
export const alwaysLockedColumns = ['selectAll', 'customer']
export const defaultSort = [
  {
    id: 'customer',
  },
]

export const DeltaFilterValueEnum: Record<string, number | null> = {
  'Between +/- 10%': 10,
  'Between +/- 20%': 20,
  'Between +/- 30%': 30,
  'Between +/- 40%': 40,
  'Between +/-50%': 50,
  'More than +/- 50%': 100,
  'N/A': null,
}
export const DataAvailabiltyFilterValueEnum: Record<string, number> = {
  '>=99%': 99,
  '>=98%': 98,
  '>=95%': 95,
  '>=90%': 90,
  '<90%': 89,
}

export const StatusViewValueMap = {
  SUCCESS: 'Successfully Submitted',
  NOT_SUBMITTED: 'Not Submitted',
  FAILED: 'Failed',
  IN_PROGRESS: 'In Progress',
}

export const StatusColorMap = {
  SUCCESS: colors.green,
  NOT_SUBMITTED: colors.blue5,
  FAILED: colors.red2,
  IN_PROGRESS: colors.orange2,
}

export const ResourceValueMap = DropdownValues.resource.reduce<
  Record<string, string>
>((a, c) => {
  const acc = { ...a }
  acc[`${c.id}`] = c.name
  return acc
}, {})

export const DataSourceValueMap = DropdownValues.dataSource.reduce<
  Record<string, string>
>((a, c) => {
  const acc = { ...a }
  acc[`${c.id}`] = c.name
  return acc
}, {})
export const errorMap = {
  400: 'Technical Error. Contact Product Team for Details.',
  401: 'Invalid Energy Star Credentials.',
  403: 'Insufficient Access Permission for this site or Meter.',
  404: "Meter record doesn't exist in Energy Star.",
  429: 'Too many submission request sent to Energy Star. Wait for some time before making new submission.',
  500: 'Problem with Energy Star Servers. Try making submission again later.',
  502: 'Energy Star Servers under maintenance. Try making submission again later.',
  405: 'Not all meters mapped',
  406: 'Site Data Availability < 99%',
}

export const TAB_ENUM_VALUES = Object.values(tabsEnum)

export const getDefaultMonth = (querymonth: string | null) => {
  if (querymonth && moment(querymonth, 'YYYY-MM', true).isValid())
    return querymonth
  return moment().subtract(1, 'months').format('YYYY-MM')
}

export enum SubmissionStages {
  UNDER_MAINTENANCE = 'UNDER_MAINTENANCE',
  CASE_NOT_FOUND = 'CASE_NOT_FOUND',
  READY_FOR_REVIEW = 'READY_FOR_REVIEW',
  SUBMITTED = 'SUBMITTED',
}

export enum TabUrlEnum {
  UNDER_MAINTENANCE = 'under-maintenance',
  CASE_NOT_FOUND = 'case-not-found',
  READY_FOR_REVIEW = 'ready-for-review',
  SUBMITTED = 'submitted',
}

export const TabUrlWithSubmissionStage = {
  [TabUrlEnum.UNDER_MAINTENANCE]: SubmissionStages.UNDER_MAINTENANCE,
  [TabUrlEnum.CASE_NOT_FOUND]: SubmissionStages.CASE_NOT_FOUND,
  [TabUrlEnum.READY_FOR_REVIEW]: SubmissionStages.READY_FOR_REVIEW,
  [TabUrlEnum.SUBMITTED]: SubmissionStages.SUBMITTED,
  [SubmissionStages.UNDER_MAINTENANCE]: TabUrlEnum.UNDER_MAINTENANCE,
  [SubmissionStages.CASE_NOT_FOUND]: TabUrlEnum.CASE_NOT_FOUND,
  [SubmissionStages.READY_FOR_REVIEW]: TabUrlEnum.READY_FOR_REVIEW,
  [SubmissionStages.SUBMITTED]: TabUrlEnum.SUBMITTED,
}

export const TAB_URL_ENUM_VALUES = Object.values(TabUrlEnum)

export const maintenanceTabs = [
  {
    text: 'Under Maintenance',
    tabId: TabUrlEnum.UNDER_MAINTENANCE,
  },
  {
    text: 'Case Not Found',
    tabId: TabUrlEnum.CASE_NOT_FOUND,
  },
  {
    text: 'Ready for Review',
    tabId: TabUrlEnum.READY_FOR_REVIEW,
  },
  {
    text: 'Submitted',
    tabId: TabUrlEnum.SUBMITTED,
  },
]
