/* eslint-disable react/no-array-index-key */
import React from 'react'
import { RootState } from '../..'
import { connect, ConnectedProps } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import withPermissions from '../../authorization/components/withPermissions'
import { selectors as authSelectors } from '../../ducks/auth'
import type { FTCircuitsById } from '../../ducks/circuits'
import {
  actions as circuitActions,
  utils as circuitUtils,
  getCircuitListEntity,
} from '../../ducks/circuits'
import { actions as messageActions } from '../../ducks/messageFlasher'
import { actions as meterActions, selectMeterEntity } from '../../ducks/meters'
import { actions as modalActions } from '../../ducks/modal'
import { actions as panelActions } from '../../ducks/panels'
import { selectSiteListEntity, actions as siteActions } from '../../ducks/sites'
import { FTRouterLocation, FTRouterMatch } from '../../types'
import ActionPaneView from '../ActionPaneView'
import withNotice from '../MeterConfiguration/withNotice'
import Title from '../Title'
import MeterInfo from './MeterInfo'
import MeterBreadcrumbs from './Breadcrumbs'
import ElectricMeter from './Electric'
import GasMeter from './Gas'

type FTProps = FTRouterLocation &
  PropsFromRedux & {
    circuitsById: FTCircuitsById
    isAdmin: boolean
    match: FTRouterMatch
  }
type FTState = {
  isKFactorModalOpen: boolean
}

class MeterDetail extends React.PureComponent<FTProps, FTState> {
  componentDidMount() {
    this.fetchMeter()
    this.props.actions.fetchStepDownTransformers()
  }

  componentDidUpdate(prev: FTProps) {
    const {
      meterEntity: { item: prevMeter },
    } = prev
    const {
      meterEntity: { item: meter },
    } = this.props

    if (!prevMeter && meter && meter.id) {
      this.onMeterLoad()
    }

    if (prevMeter && meter && prevMeter.opportunityId !== meter.opportunityId) {
      this.props.actions.hideModal()
    }
  }

  onMeterLoad = () => {
    const {
      actions,
      meterEntity: { item: meter },
    } = this.props
    this.props.actions.loadAllCircuits(this.getCircuitListFetchProps())
    this.fetchPanels()

    if (meter && meter.resource === 'NATURAL_GAS') {
      actions.fetchKFactorHistory({
        id: meter.id,
      })
    }
  }

  getCircuitListFetchProps = () => {
    const {
      meterEntity: { item: meter },
    } = this.props
    const { source, id: meterId } = meter
    return circuitUtils.getCircuitListFetchProps({
      source,
      meterId,
    })
  }

  onMeterConfigUpdate = () => {
    const { actions } = this.props
    actions.hideMessages()
    this.fetchMeter()
    this.fetchPanels()
  }

  fetchMeter = () => {
    const {
      actions,
      match: {
        params: { meterId },
      },
    } = this.props
    actions.fetchMeter({
      id: meterId,
    })
  }

  fetchPanels = () => {
    const {
      actions,
      meterEntity: { item: meter },
    } = this.props
    const { siteId } = meter
    actions.fetchAllPanels({
      siteId,
    })
    actions.fetchSite({
      siteId,
    })
  }

  renderMain = () => {
    const {
      match: {
        url,
        params: { siteId, customerId, meterId },
      },
      location: { pathname },
      meterEntity: {
        item: meter,
        meta: { loading },
      },
      circuitListEntity: { items: circuits },
    } = this.props

    return (
      <div>
        <MeterBreadcrumbs
          meter={meter}
          pathname={pathname}
          siteId={siteId}
          customerId={customerId}
        />
        <Title>Meter Detail</Title>
        {meter && (
          <MeterInfo meterId={meterId} pathname={pathname} siteId={siteId} />
        )}
        {circuits && !loading ?
          meter && meter.resource === 'NATURAL_GAS' ?
            <GasMeter meterId={meterId} url={url} pathname={pathname} />
          : <ElectricMeter
              meterId={meterId}
              url={url}
              pathname={pathname}
              onMeterConfigUpdate={this.onMeterConfigUpdate}
            />

        : null}
      </div>
    )
  }

  getActionPaneActions = () => {
    const {
      actions,
      isAdmin,
      isAccountManagers,
      isOperationsSupport,
      meterEntity: { item: meter },
      siteListEntity: { items: sites = [] },
    } = this.props
    const { source, siteId } = meter || {}
    let meterActionArr: Array<{
      href: string
      label: string
      onClick: (e: React.SyntheticEvent<HTMLLinkElement>) => void
    }> = []
    const { contracts = [] } = sites.find((item) => item.id === siteId) || {}
    const isRedaptive = source === 'REDAPTIVE' || source === 'REDAPTIVE_GAS'
    if (isRedaptive && isAdmin) {
      meterActionArr = [
        {
          href: '',
          label: 'Change Electron Verified Status',
          onClick: (event: React.SyntheticEvent<HTMLLinkElement>) => {
            event.preventDefault()
            actions.showModalElectronVerifiedForm(meter)
          },
        },
        {
          href: '',
          label: 'Change Field Status',
          onClick: (event: React.SyntheticEvent<HTMLLinkElement>) => {
            event.preventDefault()
            actions.showModalFieldStatusForm(meter)
          },
        },
        {
          href: '',
          label: 'Opportunity Association',
          onClick: (event: React.SyntheticEvent<HTMLLinkElement>) => {
            event.preventDefault()
            actions.showModalMeterContractAssociationForm({
              ...meter,
              opportunities: contracts,
            })
          },
        },
      ]
    } else if (isAdmin) {
      meterActionArr = [
        {
          href: '',
          label: 'Change Field Status',
          onClick: (event: React.SyntheticEvent<HTMLLinkElement>) => {
            event.preventDefault()
            actions.showModalFieldStatusForm(meter)
          },
        },
        {
          href: '',
          label: 'Opportunity Association',
          onClick: (event: React.SyntheticEvent<HTMLLinkElement>) => {
            event.preventDefault()
            actions.showModalMeterContractAssociationForm({
              ...meter,
              opportunities: contracts,
            })
          },
        },
      ]
    } else if (isAccountManagers || isOperationsSupport) {
      meterActionArr = [
        {
          href: '',
          label: 'Opportunity Association',
          onClick: (event: React.SyntheticEvent<HTMLLinkElement>) => {
            event.preventDefault()
            actions.showModalMeterContractAssociationForm({
              ...meter,
              opportunities: contracts,
            })
          },
        },
      ]
    }

    return meterActionArr
  }

  render() {
    return (
      <ActionPaneView
        actions={this.getActionPaneActions()}
        renderMain={this.renderMain}
      />
    )
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: {
    ...bindActionCreators(circuitActions, dispatch),
    ...bindActionCreators(messageActions, dispatch),
    ...bindActionCreators(meterActions, dispatch),
    ...bindActionCreators(modalActions, dispatch),
    ...bindActionCreators(panelActions, dispatch),
    ...bindActionCreators(siteActions, dispatch),
  },
})

const mapStateToProps = (
  state: RootState,
  props: { match: { params: { meterId: string } } },
) => {
  const authGroups = authSelectors.selectGroups(state)
  const isAdmin = authGroups && authGroups.includes('admin')
  const isAccountManagers =
    authGroups && authGroups.includes('account-managers')
  const isOperationsSupport =
    authGroups && authGroups.includes('operations-support')
  return {
    circuitListEntity: getCircuitListEntity(state),
    isAdmin,
    isAccountManagers,
    isOperationsSupport,
    meterEntity: selectMeterEntity(state, props.match.params.meterId),
    siteListEntity: selectSiteListEntity(state),
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

export default withPermissions(withNotice(connector(MeterDetail)))
