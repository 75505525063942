import styled from 'styled-components'
import { Label } from '../VerticalTable/Base'
import VerifiedIcon from '../Icons/VerifiedIcon'
import NotVerifiedIcon from '../Icons/NotVerifiedIcon'
import { Paper } from '../../pages/ToolsAndReports/Ernesto/ListScreen/styles'
import { colors } from '../../utils/themes'
import Card from '../Card'
export const Styles = styled.div`
  ${Label} {
    min-width: 325px;
  }
`

export const InlineVerifiedIcon = styled(VerifiedIcon)`
  font-size: 16px;
  vertical-align: text-top;
`

export const InlineNotVerifiedIcon = styled(NotVerifiedIcon)`
  font-size: 16px;
  vertical-align: text-top;
`
export const PanelHierarchyCardStyled = styled(Card)`
  overflow: auto;
  height: 400px;
  borderradius: 8px;
  border: 1px solid ${colors.silver};
`

export const PanelHierarchyPaperStyled = styled(Paper)`
  margintop: 10px;
  height: 100%;
`
