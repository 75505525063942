import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'

import { GROUPS } from '../utils/constants'

const useHasPermission = (permission: GROUPS) => {
  const [hasPermission, setHasPermission] = useState(false)
  const history = useHistory()
  const { isLoading, groups } = useSelector((state) => state.auth)

  useEffect(() => {
    if (!isLoading) {
      setHasPermission(groups.includes(permission))
    }
  }, [groups, history, isLoading, permission])

  return hasPermission
}

export default useHasPermission
