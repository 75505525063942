// @flux
import { all } from 'redux-saga/effects'

import { watchLoginRequest, watchLogoutRequest } from './authSaga'
import { uploadRequestWatcherSaga } from './uploadSaga'
import { watchSessionTimeout } from './utils'
import { sagas as uploadAuditFileSagas } from '../ducks/auditVendorValidations'
import { sagas as authSagas } from '../ducks/auth'
import { sagas as authzPlaygroundSaga } from '../ducks/authzPlayground'
import { sagas as billingThisMonthSaga } from '../ducks/billing/billingThisMonth/billingItems'
import { sagas as billingThisMonthCommentsSaga } from '../ducks/billing/billingThisMonth/comments'
import { sagas as isrSagas } from '../ducks/billing/ISR/isrProcessingTool'
import { sagas as buildingEquipmentsSaga } from '../ducks/buildingEquiment'
import { sagas as bsSagas } from '../ducks/buildingSystems'
import { sagas as circuitSagas } from '../ducks/circuits'
import { sagas as circuitsDataSagas } from '../ducks/circuitsData'
import { sagas as configHistorySagas } from '../ducks/configHistory'
import { sagas as customerSagas } from '../ducks/customers'
import { sagas as meterElectronVerificationHistory } from '../ducks/electronMeters'
import { sagas as meterElectronVerification } from '../ducks/electronVerification'
import { sagas as energyStarSagas } from '../ducks/energyStarIntegration'
import { sagas as equipmentSagas } from '../ducks/equipment'
import { sagas as hvacAssetSagas } from '../ducks/hvacAsset'
import { sagas as lightingAsBuiltsAssetSagas } from '../ducks/lightingAsBuiltsAsset'
import { sagas as lightingControlsTypeGuideAssetSagas } from '../ducks/lightingControlsTypeGuideAsset'
import { sagas as measureCodeGuideAssetSagas } from '../ducks/measureCodeGuideAsset'
import {
  pollJobStatusSaga,
  sagas as meterExportSagas,
} from '../ducks/meterExport'
import { sagas as meterInstallReportSagas } from '../ducks/meterInstallReport'
import { sagas as meterInstallSubmissionDetailsSagas } from '../ducks/meterInstallSubmissions/meterInstallSubmissionDetails'
import { sagas as meterInstallSubmissionsSagas } from '../ducks/meterInstallSubmissions/meterInstallSubmissions'
import { sagas as meterSagas } from '../ducks/meters'
import { sagas as metersMostRecentMeasurementDataSagas } from '../ducks/metersMostRecentMeasurementData'
import { sagas as meterStatusSagas } from '../ducks/meterStatus'
import { sagas as panelCircuitSagas } from '../ducks/panelCircuits'
import { sagas as panelPhotosSaga } from '../ducks/panelPhotos'
import { sagas as panelSagas } from '../ducks/panels'
import { sagas as phaseGroupsSagas } from '../ducks/phaseGroups'
import { sagas as proposalCustomerGlobalInputsSaga } from '../ducks/proposals/customerGlobalInputs'
import { sagas as proposalCustomerMetricsSagas } from '../ducks/proposals/customerMetrics'
import { sagas as proposalCustomerSummariesSagas } from '../ducks/proposals/customerSummaries'
import { sagas as emissionRate } from '../ducks/proposals/emissionRate'
import { sagas as importedSaga } from '../ducks/proposals/importedScenario'
import { sagas as proposalBatchesSagas } from '../ducks/proposals/multisite/batches'
import { sagas as proposalBatchSummariesSagas } from '../ducks/proposals/multisite/batchSummaries'
import { sagas as proposalOpportunitiesScenariosSagas } from '../ducks/proposals/multisite/opportunities'
import { sagas as proposalOpportunitiesSummariesSagas } from '../ducks/proposals/opportunitySummaries'
import { sagas as proposalOpportunitiesSummaryDownloadSagas } from '../ducks/proposals/opportunitySummaryDownload'
import { sagas as recData } from '../ducks/proposals/recData'
import { sagas as proposalScenariosSagas } from '../ducks/proposals/scenarios'

/* eslint-disable max-len */
import { sagas as proposalSitesSagas } from '../ducks/proposals/sites'
import { sagas as utilityInflationRateData } from '../ducks/proposals/utilityInflationRateData'
import { sagas as isrProcessingSags } from '../ducks/billing/ISR/isrProcessingTool'
import { sagas as isrTableDataSaga } from '../ducks/billing/ISR/isrTableItems'
import { sagas as siteSagas } from '../ducks/sites'
import { sagas as specSheetsSagas } from '../ducks/specSheets'
import { sagas as unassignedSagas } from '../ducks/unassignedMeters'
import { sagas as userSagas, watchFetchUserInfo } from '../ducks/users'
import { sagas as userSummarySagas } from '../ducks/userSummaries'
import { sagas as fetchUtilityDataSagas } from '../ducks/utilityData/fetchUtilityData'
import { sagas as uploadUtilityProfileDataSagas } from '../ducks/utilityData/uploadUtilityProfileData'
import { sagas as uploadUtilityDataSagas } from '../ducks/utilityData/uploadUtilityUsageData'
import { sagas as downloadCustomerSitesSagas } from '../ducks/utilityData/utilityCustomerSites'

export default function* rootSaga() {
  yield all([
    watchLoginRequest(),
    watchLogoutRequest(),
    watchFetchUserInfo(),
    watchSessionTimeout(),
    uploadRequestWatcherSaga(),
    ...authSagas,
    ...authzPlaygroundSaga,
    ...billingThisMonthSaga,
    ...billingThisMonthCommentsSaga,
    ...bsSagas,
    ...circuitSagas,
    ...circuitsDataSagas,
    ...customerSagas,
    ...downloadCustomerSitesSagas,
    ...hvacAssetSagas,
    ...lightingAsBuiltsAssetSagas,
    ...lightingControlsTypeGuideAssetSagas,
    ...measureCodeGuideAssetSagas,
    ...equipmentSagas,
    ...meterExportSagas,
    ...meterInstallReportSagas,
    ...meterInstallSubmissionsSagas,
    ...meterSagas,
    ...metersMostRecentMeasurementDataSagas,
    ...meterStatusSagas,
    ...panelSagas,
    ...panelCircuitSagas,
    ...buildingEquipmentsSaga,
    ...phaseGroupsSagas,
    ...proposalBatchSummariesSagas,
    ...proposalBatchesSagas,
    ...proposalCustomerGlobalInputsSaga,
    ...proposalCustomerMetricsSagas,
    ...proposalCustomerSummariesSagas,
    ...proposalOpportunitiesSummariesSagas,
    ...proposalOpportunitiesScenariosSagas,
    ...proposalOpportunitiesSummaryDownloadSagas,
    ...meterInstallSubmissionDetailsSagas,
    ...proposalScenariosSagas,
    ...proposalSitesSagas,
    ...siteSagas,
    ...specSheetsSagas,
    ...unassignedSagas,
    ...uploadUtilityDataSagas,
    ...uploadUtilityProfileDataSagas,
    ...userSagas,
    ...userSummarySagas,
    ...energyStarSagas,
    ...importedSaga,
    ...emissionRate,
    ...recData,
    ...configHistorySagas,
    ...fetchUtilityDataSagas,
    ...panelPhotosSaga,
    ...uploadAuditFileSagas,
    ...utilityInflationRateData,
    ...meterElectronVerification,
    ...meterElectronVerificationHistory,
    ...isrProcessingSags,
    ...isrTableDataSaga,
    pollJobStatusSaga(),
  ])
}
