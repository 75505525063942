import styled from 'styled-components'
import ListSelector from '../../components/ListSelector'

export const StyledListSelector = styled(ListSelector)`
  width: 80px;
  height: 36px;

  .Select__input,
  .Select__multi-value-wrapper {
    height: 100%;
  }

  .Select__option,
  .Select__value-container {
    align-items: center;
    display: flex;
  }

  .Select__placeholder {
    width: 100%;
  }

  .Select__menu-outer {
    top: auto;
  }
`
export const PanelDropdownContainer = styled.div`
  margin-bottom: 44px;
  margin-top: -15px;

  ${StyledListSelector} .Select__menu .Select__option {
    height: auto;
    padding: 10px;
  }
`
export const StyledPanelSelector = styled(StyledListSelector)`
  * {
    box-sizing: border-box;
  }
  width: 450px;

  .Select__option,
  .Select__value-container {
    align-items: center;
    display: flex;
    padding: 15px;
  }

  .Select--is-disabled {
    opacity: 0.6;
  }
`
export const TextCellStyled = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
`
export const CircuitSectionStyled = styled.div`
  margin-top: 50px;
  margin-bottom: 100px;
  position: relative;
`
export const SectionTitleStyled = styled.div`
  font-weight: 600;
  font-size: 14px;
  color: #4a4a4a;
  padding-bottom: 15px;
`
export const HeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px #c7c7c7 solid;
`
export const EditActionStyled = styled.div`
  font-weight: 600;
  font-size: 14px;
  display: flex;
  align-items: center;
  margin-right: 5px;
  color: #337ab7;
  a {
    color: #337ab7;
  }
`

export const PanelHeading = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 16px;
  border-bottom: 1px solid #dbdbdb;
  color: #4a4a4a;
  padding: 8px 0;
`

export const MeterIconWrapperStyled = styled.div`
  display: flex;
  align-items: center;
  margin-left: 6px;
`

export const ViewPhotosButton = styled.span`
  color: #337ab7;
  cursor: pointer;
`

export const Wrapper = styled.div`
  .panel-info {
    margin-bottom: 30px;
  }
`
