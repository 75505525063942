import moment from 'moment'
import StyledLink from '../../StyledLink'
import * as requestStatusStates from '../../../constants/status'
import { formatHealthStatus } from '../../../utils'
import LoadingSpinner from '../../LoadingSpinner'
import {
  naturallySortContracts,
  selectSiteHealth,
  selectSiteHealthRequestStatus,
  FTState as SiteType,
} from '../../../ducks/sites'
import { selectSiteEntity, themes } from '../../../ducks/sites'
import {
  selectCustomerEntity,
  FTState as CustomerType,
} from '../../../ducks/customers'
import { selectSiteExternalResources } from '../../../ducks/sites'
import VerticalTable from '../../VerticalTable'
import { useSelector } from 'react-redux'
import { Field } from '../types'

type Props = {
  customerIdUrl: string
  siteId: string
}

export default function SiteInfo({ customerIdUrl, siteId }: Props) {
  const siteEntity = useSelector((state: SiteType) =>
    selectSiteEntity(state, siteId),
  )
  let customerId = customerIdUrl

  if (!customerId && siteEntity.item) {
    ;({ customerId } = siteEntity.item)
  }
  const siteHealthRequestStatus = useSelector((state: SiteType) =>
    selectSiteHealthRequestStatus(state),
  )

  const siteExternalResources = useSelector((state: SiteType) =>
    selectSiteExternalResources(state),
  )

  const siteHealth = useSelector((state: SiteType) => selectSiteHealth(state))
  const customerEntity = useSelector((state: CustomerType) =>
    selectCustomerEntity(state, customerId),
  )
  const { item: site } = siteEntity

  const renderHealthStatusWithSpinner = (
    healthStatus: number | null | undefined,
  ) => {
    switch (siteHealthRequestStatus) {
      case requestStatusStates.ERROR:
        return 'Unavailable'

      case requestStatusStates.LOADED:
        return formatHealthStatus(healthStatus)

      default:
        return <LoadingSpinner />
    }
  }

  const {
    item: customer,
    meta: { loading },
  } = customerEntity
  const hydropointSiteId =
    siteExternalResources &&
    siteExternalResources.find(
      (obj: { resourceProvider: string }) =>
        obj.resourceProvider === 'HYDROPOINT',
    )?.externalId
  let fields: Array<Field> = []

  if (site) {
    const { contracts = [] } = site
    fields = [
      {
        label: 'Name',
        value: site.validName,
        editable: false,
      },
      {
        label: 'Address',
        // eslint-disable-next-line react/no-array-index-key
        renderField: () =>
          site.address.map(
            (l, i) => l && <div key={`address-line-${i}`}>{l}</div>,
          ),
        editable: false,
      },
      {
        label: 'Customer',
        renderField: () =>
          customer && (
            <StyledLink href={`/account-management/customers/${customer.id}`}>
              {customer.name}
            </StyledLink>
          ),
        editable: false,
      },
      {
        label: 'Opportunity IDs',
        renderField: () => {
          if (!contracts.length) {
            return <div>-</div>
          }

          return [...contracts].sort(naturallySortContracts).map((contract) => (
            <div>
              {`${contract.opportunityId} (
                  ${contract.opportunityType}${
                    contract.opportunityTypeLevel2 ?
                      ` - ${contract.opportunityTypeLevel2}`
                    : ''
                  })`}
            </div>
          ))
        },
        editable: false,
      },
      {
        label: 'Electric Data Start Date',
        value:
          site.ingestionDataStartElectricity &&
          moment(site.ingestionDataStartElectricity).utc().format('MM/DD/YYYY'),
        editable: false,
      },
      {
        label: 'Natural Gas Data Start Date',
        value:
          site.ingestionDataStartNaturalGas &&
          moment(site.ingestionDataStartNaturalGas).utc().format('MM/DD/YYYY'),
        editable: false,
      },
      {
        label: 'Water Data Start Date',
        value:
          site.ingestionDataStartWater &&
          moment(site.ingestionDataStartWater).utc().format('MM/DD/YYYY'),
        editable: false,
      },
      {
        label: 'Time Zone',
        value: site.prettyTimezone,
        editable: false,
      },
      {
        label: 'Hydropoint Site ID',
        value: hydropointSiteId,
        editable: false,
      },
      {
        label: 'Display in Energy Dashboard',
        value: site.active ? 'Yes' : 'No',
        editable: false,
      },
      {
        label: 'Enable Equipment View',
        value: site.enableEquipmentView ? 'Yes' : 'No',
        editable: false,
      },
      {
        label: 'Date Created',
        value: site.dateCreated,
        editable: false,
      },
      {
        label: 'Last Updated',
        value: site.lastUpdated,
        editable: false,
      },
      {
        label: 'Branding',
        value: themes.getThemeNameById(site.theme),
        editable: false,
      },
      {
        label: 'Electric Site Data Availability: Last Month',
        value: renderHealthStatusWithSpinner(
          siteHealth.electricSiteHealthStatusLastMonth,
        ),
        editable: false,
      },
      {
        label: 'Electric Site Data Availability: Month to Date',
        value: renderHealthStatusWithSpinner(
          siteHealth.electricSiteHealthStatusCurrentMonth,
        ),
        editable: false,
      },
      {
        label: 'Natural Gas Site Data Availability: Last Month',
        value: renderHealthStatusWithSpinner(
          siteHealth.gasSiteHealthStatusLastMonth,
        ),
        editable: false,
      },
      {
        label: 'Natural Gas Site Data Availability: Month to Date',
        value: renderHealthStatusWithSpinner(
          siteHealth.gasSiteHealthStatusCurrentMonth,
        ),
        editable: false,
      },
    ]
  }

  return (
    <div>{site && !loading && <VerticalTable.Basic fields={fields} />}</div>
  )
}
