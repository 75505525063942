import { useHistory, useLocation } from 'react-router'
import { useEffect, useMemo } from 'react'
import Spinner from '../../../components/Spinner'
import {
  ConfirmationScreen,
  ConfirmationTitleStyled,
  LoadingSpinnerWrapperStyled,
  SubmissionPageOuter,
  TableWrapperStyled,
} from '../styles'
import { alwaysLockedColumns, defaultSort, TabUrlEnum } from '../helpers/utils'
import RedaptiveReactTable7 from '../../../components/RedaptiveReactTable7'
import getTableColumns from './helpers/getTableColumns'
import HeaderDownloadButtons from './components/HeaderButtons'
import RenderMonthPicker from './components/RenderMonthPicker'
import {
  useEnergyStarCommonData,
  useSubmissionsDataQuery,
} from '../../../queries/energyStar'
import tableDataMapper from './mapper/tableDataMapper'
import getDataByTabs from './helpers/getDataByTabs'
import RenderActionPaneView from './components/RenderActionPaneView'

const SubmitSubmissions = () => {
  const { activeSubmissionTab, isActiveMonthValid, activeYearMonth } =
    useEnergyStarCommonData()
  const { search } = useLocation()
  const history = useHistory()

  const submissionsData = useSubmissionsDataQuery()

  const tableData = useMemo(
    () =>
      tableDataMapper(
        getDataByTabs(submissionsData.data ?? [])?.[activeSubmissionTab].items,
      ),
    [activeSubmissionTab, submissionsData.data],
  )

  useEffect(() => {
    if (!activeYearMonth || !isActiveMonthValid) {
      const month = activeYearMonth
      const tempQueryParams = new URLSearchParams(search)
      tempQueryParams.set('activeMonth', month)
      history.replace({ search: tempQueryParams.toString() })
    }
  }, [activeYearMonth, history, isActiveMonthValid, search])

  if (submissionsData?.isFetching) {
    return (
      <LoadingSpinnerWrapperStyled>
        <Spinner size='medium' />
      </LoadingSpinnerWrapperStyled>
    )
  }

  return (
    <SubmissionPageOuter suppressLoading={submissionsData?.isFetching}>
      <RenderActionPaneView />
      {tableData.length ?
        <TableWrapperStyled suppressLoading={submissionsData?.isFetching}>
          <RedaptiveReactTable7
            columns={getTableColumns(activeSubmissionTab)}
            HeaderActions={HeaderDownloadButtons}
            data={tableData}
            defaultSort={defaultSort}
            alwaysLockedColumns={alwaysLockedColumns}
            enableColumnHiding
            enablePagination
            filterable
            enableRowSelection={
              activeSubmissionTab === TabUrlEnum.READY_FOR_REVIEW
            }
            globalFilterable={false}
            fixedHeader
          />
        </TableWrapperStyled>
      : <>
          <RenderMonthPicker />
          <ConfirmationScreen>
            <ConfirmationTitleStyled>No Submissions</ConfirmationTitleStyled>
            <h2>Please submit customer meter data for Energy Star Rating.</h2>
          </ConfirmationScreen>
        </>
      }
    </SubmissionPageOuter>
  )
}

export default SubmitSubmissions
