import features from '../../authorization/features'
import { FTUserPermissions } from '../../authorization/models/userPermissions'
import { BirthCertificate, GasMeterType, MeterInfoType } from './types'
export const getFirmwareFields = (meter: MeterInfoType) => {
  return [
    {
      label: 'Serial Number',
      value: meter.serialNumber,
    },
    {
      label: 'Hardware Version',
      value: meter.hardwareVersion,
    },
    {
      label: 'OS Version at Manufacture',
      value: meter.manufactureOs,
    },
    {
      label: 'Data Packet Version at Manufacture',
      value: meter.manufactureDataPacketVersion,
    },
    {
      label: 'Cellular Carrier',
      value: meter.cellularCarrier,
    },
    {
      label: 'SIM Card ICCID',
      value: meter.simCardICCID,
    },
    {
      label: 'First Factory Logfile Received',
      value: meter.firstReportDate,
    },
  ]
}

export const getGasMeterSensorsFields = (meter: any) => {
  const birthCertificate =
    meter?.meterDetailResponse?.gasMeterDetailResponse?.birthCertificate
  const sensors =
    birthCertificate?.data_acquisition_modules.length > 0 ?
      birthCertificate?.data_acquisition_modules[0]?.sensors
    : []
  const values = [
    {
      label: 'Type',
      value: sensors[0]?.type,
    },
    {
      label: 'Make',
      value: sensors[0]?.make,
    },
    {
      label: 'Model',
      value: sensors[0]?.model,
    },
    {
      label: 'Model Number',
      value: sensors[0]?.model_number,
    },
    {
      label: 'Serial',
      value: sensors[0]?.serial,
    },
  ]
  return [
    {
      header: null,
      values,
    },
  ]
}

export const getGasMeterFirmwareFields = (meter: GasMeterType) => {
  const birthCertificate =
    meter?.meterDetailResponse?.gasMeterDetailResponse?.birthCertificate
  const dataTables = [
    {
      header: null,
      values: [
        {
          label: 'System Serial',
          value: birthCertificate?.system_serial,
        },
      ],
    },
    {
      header: {
        label: 'CPU',
        value: '',
      },
      values: [
        {
          label: 'Make',
          value: birthCertificate?.cpu?.make,
        },
        {
          label: 'Model',
          value: birthCertificate?.cpu?.model,
        },
        {
          label: 'Model Number',
          value: birthCertificate?.cpu?.model_number,
        },
        {
          label: 'Serial',
          value: birthCertificate?.cpu?.serial,
        },
        {
          label: 'MAC',
          value: birthCertificate?.cpu?.mac,
        },
        {
          label: 'Hardware ID',
          value: birthCertificate?.cpu?.hardware_id,
        },
        {
          label: 'OS Version At Manufacture',
          value: birthCertificate?.cpu?.os_verison_at_manufacture,
        },
      ],
    },
    {
      header: {
        label: 'Data Acquisition Modules',
        value: '',
      },
      values: [
        {
          label: 'Make',
          value: birthCertificate?.data_acquisition_modules[0]?.make,
        },
        {
          label: 'Model',
          value: birthCertificate?.data_acquisition_modules[0]?.model,
        },
        {
          label: 'Model Number',
          value: birthCertificate?.data_acquisition_modules[0]?.model_number,
        },
        {
          label: 'Serial',
          value: birthCertificate?.data_acquisition_modules[0]?.serial,
        },
        {
          label: 'FW Version',
          value: birthCertificate?.data_acquisition_modules[0]?.fw_version,
        },
      ],
    },
    {
      header: {
        label: 'Cell Router',
        value: '',
      },
      values: [
        {
          label: 'Make',
          value: birthCertificate?.cell_router?.make,
        },
        {
          label: 'Model',
          value: birthCertificate?.cell_router?.model,
        },
        {
          label: 'Model Number',
          value: birthCertificate?.cell_router?.model_number,
        },
        {
          label: 'Serial',
          value: birthCertificate?.cell_router?.serial,
        },
        {
          label: 'WAN MAC',
          value: birthCertificate?.cell_router?.wan_mac,
        },
        {
          label: 'FW Version',
          value: birthCertificate?.cell_router?.fw_version,
        },
        {
          label: 'IMEI',
          value: birthCertificate?.cell_router?.imei,
        },
        {
          label: 'ICCID',
          value: birthCertificate?.cell_router?.iccid,
        },
      ],
    },
  ]
  return dataTables
}

export const canEditGasMeterPermissions = (
  permissions: FTUserPermissions,
  url: string,
  pathname: string,
) => {
  const canEditMeters =
    features.editMeters.anyMatchesWithPermissions(permissions)
  let editInstalLocation =
    canEditMeters ?
      {
        href: `${url}/install-location/edit`,
        text: 'Edit',
      }
    : null
  if (pathname.endsWith('/install-location/edit')) {
    editInstalLocation = {
      href: `${url}/install-location`,
      text: 'Cancel',
    }
  }
  let editUtilityAccount =
    canEditMeters ?
      {
        href: `${url}/utility-account/edit`,
        text: 'Edit',
      }
    : null

  if (pathname.endsWith('/utility-account/edit')) {
    editUtilityAccount = {
      href: `${url}/utility-account`,
      text: 'Cancel',
    }
  }
  return [editInstalLocation, editUtilityAccount]
}

export const editElectricMeterPermissions = (
  meter: MeterInfoType,
  permissions: FTUserPermissions,
  url: string,
  pathname: string,
) => {
  const canEditMeters =
    features.editMeters.anyMatchesWithPermissions(permissions)
  const canEditMeterConfig =
    features.editMeterConfig.allMatchWithPermissions(permissions)
  const editChannelsLink =
    canEditMeters ?
      {
        href: `${url}/circuits/edit`,
        text: 'Edit',
        withDefaultTab: true,
      }
    : null

  const showMeterConfigLink =
    canEditMeterConfig &&
    meter &&
    meter.source === 'REDAPTIVE' &&
    !pathname.endsWith('/configuration/edit')

  const editMeterConfigLink =
    showMeterConfigLink ?
      {
        href: `${url}/configuration/edit`,
        text: 'Edit',
      }
    : null
  return [editChannelsLink, editMeterConfigLink]
}
