// @flow
import Tippy from '@tippyjs/react'
import debounce from 'debounce'
import { Form, withFormik } from 'formik'
import React, { type ComponentType, useEffect } from 'react'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import { bindActionCreators } from 'redux'
import styled, { css } from 'styled-components'
import { string } from 'yup'

import validationSchema from './validationSchema'
import {
  type FTAddScenarioWithoutSalesTaxModal,
  actions as modalActions,
} from '../../../ducks/modal'
import { FTProposalCustomerGlobalInputs } from '../../../ducks/proposals/customerGlobalInputs'
import emissionRate, {
  actions as emissionActions,
  FTFetchEmissionRateAction,
} from '../../../ducks/proposals/emissionRate'
import type { FTProposalOpportunitySummary } from '../../../ducks/proposals/opportunitySummaries'
import {
  actions as recActions,
  FTFetchRECDataResp,
  selectRECDataEntity,
} from '../../../ducks/proposals/recData'
import {
  type FTFetchSalesTaxRateAction,
  type FTScenarioFieldInfoItemFieldType,
  actions as scenarioActions,
  defaultScenario,
  formatScenarioFieldValueBoolean,
  formatScenarioFieldValueCurrency,
  formatScenarioFieldValueEnum,
  formatScenarioFieldValuePercentage,
  FTCalculations,
  FTProposalScenario,
  FTProposalScenarioResponse,
  getAdjustedFieldValue,
  getPrecisionDefault,
  getScenarioCalculations,
  getScenarioEnhancedWithCalculations,
  scenarioEnumFieldInfo,
  scenarioEnumNames,
  scenarioFieldCategories,
  scenarioFieldInfo,
  selectProposalScenarioEntity,
} from '../../../ducks/proposals/scenarios'
import type { FTProposalSite } from '../../../ducks/proposals/sites'
import countryNameLookup from '../../../ducks/sites/countryNameLookup'
import {
  conditionalReadOnlyFields,
  contryCodeByCountryName,
  defaultFieldsMapping,
  salesTaxWarningValue,
  skipFieldsToCheckIfEdited,
} from '../../../pages/ProposalOperations/ProposalsEngine/utils'
import type { FTWithFormik, FTWithRouter } from '../../../types'
import { isValueSet, valueSetOrDefault, YES_NO, zIndices } from '../../../utils'
import { colors } from '../../../utils/themes'
import Button from '../../Button'
import DownloadVendorFile from '../../DownloadVendorFile'
import FormField, {
  FormFieldStyles,
  FormFieldTitleSection,
} from '../../FormField'
import EditIcon from '../../Icons/EditIcon'
import MonitorHeartOutlinedIcon from '../../Icons/MonitorHeartOutlinedIcon'
import { InputComponent, InputErrorMessageStyles } from '../../Input'
import InputInteger from '../../InputInteger'
import InputNumberWithPrecision from '../../InputNumberWithPrecision'
import type { FTItem } from '../../ListSelector'
import ListSelector, { SelectStyles } from '../../ListSelector'
import { SpinnerStyles } from '../../Spinner'
import TextArea, { TextAreaInnerStyled } from '../../TextArea'
import ModalScenarioTabsStyles from '../ModalScenario/ModalScenarioTabsStyles'
import {
  FieldGridStyled,
  FieldValueStyles,
  FieldWrapperStyled,
  FormFieldWidthWrapperFullWidthStyles,
  TabHighlightStyled,
  TabPanelsStyled,
  ViewInputField,
} from '../ScenarioView'

type FTProps = {
  customerGlobalInputs: FTProposalCustomerGlobalInputs
  editMode: boolean
  loading: boolean
  onCancel: () => void
  opportunity: FTProposalOpportunitySummary
  scenario: object
  site: FTProposalSite
  source: string
  refetchedSalesTaxRate: number
  recData: FTFetchRECDataResp
  actions: {
    showModalAddScenarioWithoutSalesTax: (
      params: FTAddScenarioWithoutSalesTaxModal,
    ) => null
    hideModal: () => null
    fetchSalesTaxRate: (props: FTFetchSalesTaxRateAction) => null
    fetchEmissionRate: (params?: FTFetchEmissionRateAction) => null
    fetchRECData: () => null
  }
} & FTWithRouter &
  FTWithFormik

const getSelectControlBorderColor = ({
  hiddenMode,
  showRequired,
}: {
  hiddenMode: boolean
  showRequired: boolean
}) => {
  if (showRequired) {
    return '#D0021B'
  }
  return hiddenMode ? 'transparent' : '#e0e0e0'
}

const fieldHeight = '42px'

const ScenarioFormStyled = styled.div`
  ${FormFieldTitleSection} {
    margin-bottom: 8px;
  }

  ${FormFieldStyles} {
    margin-bottom: 16px;
  }

  ${InputComponent} {
    color: inherit;
    font-size: inherit;
    letter-spacing: 0.02em;
    line-height: inherit;
    height: auto;
    ${FieldValueStyles};

    ::-webkit-inner-spin-button,
    ::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  ${InputErrorMessageStyles} {
    left: 0;
    top: 20px;
  }

  ${SelectStyles} {
    color: inherit;
    font-family: Montserrat, sans-serif;
    height: ${fieldHeight};

    .Select {
      color: inherit;
      height: ${fieldHeight};

      .Select__control {
        border-color: ${getSelectControlBorderColor};
      }
    }

    .Select__control {
      min-height: ${fieldHeight};
    }

    .Select__option--is-focused,
    .Select__option--is-selected {
      color: inherit;
    }

    .Select__placeholder {
      color: inherit;
      height: ${fieldHeight};
      line-height: ${fieldHeight};
    }
  }

  ${TextAreaInnerStyled} {
    font-size: 14px;
    resize: vertical;
    min-height: 40px;
  }
`

const CalculatedFieldChangedIconWrapperStyles = css`
  position: absolute;
  right: 12px;
`

const CalculatedFieldChangedIconWrapperStyled = styled.div`
  ${CalculatedFieldChangedIconWrapperStyles};
  bottom: 20px;
`

const CalculatedEditableFieldChangedIconWrapperStyled = styled.div`
  ${CalculatedFieldChangedIconWrapperStyles};
  bottom: 4px;
`

const EditFormTabsWrapperStyles = styled.div`
  padding-bottom: 60px;
`

const ButtonsStyled = styled.div`
  box-shadow: -2px 0 4px rgba(0, 0, 0, 0.18);
  bottom: 0;
  display: flex;
  justify-content: flex-end;
  padding: 10px 32px 10px 24px;
  position: absolute;
  width: 100%;
`

const CancelButtonStyled = styled(Button)`
  border: 1px solid #fff;
  background-color: #fff;
  font-family: 'Public Sans', sans-serif;
  font-size: 12px;
  margin-right: 20px;
  min-width: 0;
  padding: 0;
  text-transform: none;
`

const SubmitButtonStyled = styled(Button)`
  border: 1px solid ${colors.green};
  background-color: ${colors.green};
  border-radius: 4px;
  font-family: 'Public Sans', sans-serif;
  font-size: 12px;
  min-width: 0;
  padding: 0 16px;
  position: relative;
  text-transform: none;

  &:disabled {
    color: #a5a5a5;
    cursor: default;
    background-color: #dadce0;
    border-color: #dadce0;
  }

  ${SpinnerStyles} {
    position: absolute;
    left: 50px;
    top: 10px;
  }
`

const getCalculatedEditableFieldBgColor = (readOnly: boolean, id: string) => {
  if (readOnly && conditionalReadOnlyFields.includes(id)) {
    return '#f5f5f5'
  }
  if (conditionalReadOnlyFields.includes(id)) {
    return '#ffffff'
  }
  return '#e0e5f1'
}

const CalculatedEditableFieldStyles = (readOnly: boolean, id: string) => css`
  ${InputComponent} {
    background-color: ${getCalculatedEditableFieldBgColor(readOnly, id)};
  }
`

const CalculatedEditableFieldStyled: ComponentType<{
  readOnly: boolean
  id: string
}> = styled(InputNumberWithPrecision)`
  ${({ readOnly, id }) => CalculatedEditableFieldStyles(readOnly, id)};
`

const InputFieldWithWarningStyles = css`
  ${InputComponent} {
    border-color: ${colors.orange2};
  }
`

const InputNumberWithPrecisionStyled: ComponentType<{ isWarning?: boolean }> =
  styled(InputNumberWithPrecision)`
    ${({ isWarning }) => isWarning && InputFieldWithWarningStyles};
  `

const ButtonInsideInputFieldStyled = styled.div`
  color: ${colors.blue2};
  position: relative;
  right: 40px;
  bottom: 30px;
  float: right;
  cursor: pointer;
`

const CalculatedEditableIntegerFieldStyled: ComponentType<{
  readOnly: boolean
  id: string
}> = styled(InputInteger)`
  ${({ readOnly, id }) => CalculatedEditableFieldStyles(readOnly, id)};
`

const CreateFormStyled = styled.div`
  position: relative;
`

const CreateFormSubmitButtonStickyStyled = styled.div`
  position: sticky;
  top: 144px;
  z-index: ${zIndices.ScenarioFormStickyHeader};
`

const CreateFormSubmitButtonWrapperStyled = styled.div`
  position: absolute;
  top: -46px;
  right: 30px;
`

const CreateFormTabsWrapperStyled = styled.div`
  position: sticky;
  top: 146px;
  z-index: ${zIndices.ScenarioFormStickyHeader};
`

const CreateFormTabsStyled = styled.div`
  background-color: #fafafa;
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: 24px;
  overflow-x: hidden;
  padding: 0 30px;
`

const CreateFormTabSelectedStyles = css`
  color: #162447;
  font-weight: 600;

  &:hover {
    color: #162447;
    font-weight: 600;
  }

  &:hover ${TabHighlightStyled}, ${TabHighlightStyled} {
    background-color: ${colors.green};
    border-radius: 2px 2px 0 0;
  }
`

const CreateFormTabInvalidStyles = css`
  color: ${colors.red};
`

const CreateFormTabStyled: ComponentType<{
  selected: boolean
  valid: boolean
}> = styled.div`
  color: ${colors.blue3};
  cursor: pointer;
  display: inline-block;
  letter-spacing: 0.02em;
  margin-right: 26px;
  position: relative;

  &:hover {
    ${TabHighlightStyled} {
      background-color: #dadce0;
      border-radius: 2px 2px 0 0;
    }
  }

  ${({ selected }) => selected && CreateFormTabSelectedStyles};
  ${({ valid }) => !valid && CreateFormTabInvalidStyles};
`

const CreateFormTabInnerStyled = styled.div`
  align-items: center;
  display: flex;
  height: 40px;
`

const TabCountCompletedStyles = css`
  background: ${colors.green};
  border: 1px solid ${colors.green};
  color: #fff;
`

const TabCountSelectedStyles = css`
  border: 1px dashed ${colors.green};
`

const TabCountInvalidStyles = css`
  border: 1px dashed ${colors.red};
  color: ${colors.red};
`

const TabCountStyled: ComponentType<{
  completed: boolean
  selected: boolean
  valid: boolean
}> = styled.div`
  border: 1px dashed ${colors.blue3};
  border-radius: 100px;
  font-weight: 500;
  font-size: 12px;
  margin-right: 8px;
  ${({ completed }) => completed && TabCountCompletedStyles};
  ${({ selected }) => selected && TabCountSelectedStyles};
  ${({ valid }) => !valid && TabCountInvalidStyles};
`

const TabCountInnerStyled = styled.div`
  align-items: center;
  display: flex;
  height: 20px;
  justify-content: center;
  min-width: 20px;
`

const CreateFormBodyStyled = styled.div`
  padding: 0 30px;
`

const CreateFormSectionStyled = styled.div`
  border-bottom: 1px solid #dadce0;
  padding-bottom: 30px;
  margin-bottom: 40px;
  max-width: 100%;
  :last-child {
    min-height: 740px;
  }
`

const PrimaryFieldsWrapper: ComponentType<{ col?: number }> = styled.div`
  display: grid;
  column-gap: 20px;
  ${({ col }) =>
    col &&
    css`
      grid-template-columns: repeat(${col}, 1fr);
    `};
`

const ViewFieldLabelStyled = styled.div`
  font-weight: 600;
  margin-bottom: 8px;
`

const CustomFormFieldStyled = styled.div`
  margin-bottom: 16px;
`

const FormFieldWidthWrapperStyled: ComponentType<{ fullWidth?: boolean }> =
  styled.div`
    ${({ fullWidth }) => fullWidth && FormFieldWidthWrapperFullWidthStyles};
  `

const formatScenarioFieldValue = ({
  currencyCode,
  fieldType,
  formatPostProcess,
  locale,
  value,
  precision,
  fieldName,
}: {
  currencyCode: string
  fieldType: FTScenarioFieldInfoItemFieldType
  formatPostProcess?: Function
  locale: string
  value: string | number
  precision?: number
  fieldName?: string
}) => {
  let formattedValue
  switch (fieldType) {
    case 'boolean':
      formattedValue = formatScenarioFieldValueBoolean({ value })
      break
    case 'currency':
      formattedValue = formatScenarioFieldValueCurrency({
        locale,
        currencyCode,
        value,
        precision,
        fieldName,
      })
      break
    case 'enum':
      formattedValue =
        typeof value === 'string' ? formatScenarioFieldValueEnum({ value }) : ''
      break
    case 'percentage':
      formattedValue = formatScenarioFieldValuePercentage({ locale, value })
      break
    default:
      formattedValue = value
  }
  return formatPostProcess ?
      formatPostProcess(formattedValue, currencyCode, locale)
    : formattedValue
}

const scenarioEnumItems: { [string]: Array<FTItem> } = Object.keys(
  scenarioEnumFieldInfo,
).reduce(
  (acc, cur) => ({
    ...acc,
    [cur]: scenarioEnumFieldInfo[cur].map((enumerator) => ({
      id: enumerator,
      name: scenarioEnumNames[enumerator],
    })),
  }),
  {},
)

const getScenarioEnumItem = ({
  fieldName,
  value,
}: {
  fieldName: string
  value: string | number
}) => scenarioEnumItems[fieldName].find(({ id }) => id === value)

const renderFieldTitle = (title: string, required: boolean) => (
  <>
    {title}
    {required && <RequiredAsteriskStyled>*</RequiredAsteriskStyled>}
  </>
)

const siteDependentDisabledFields: {
  [string]: ({ scenario: FTProposalScenario, site: FTProposalSite }) => boolean
} = {
  vendorSalesTax: ({
    scenario,
    site,
  }: {
    scenario: FTProposalScenario
    site: FTProposalSite
  }) =>
    scenario.dealType === 'EAAS' ?
      ['Wisconsin', 'Tennessee', 'Indiana', 'Massachusetts'].includes(
        site.shippingState,
      )
    : [
        'California',
        'Connecticut',
        'Hawaii',
        'New Mexico',
        'South Dakota',
        'Washington',
        'West Virginia',
      ].includes(site.shippingState),
}

const checkIfReadOnly = (
  fieldName: string,
  values: FTProposalScenario,
  value: string | number,
) => {
  if (
    conditionalReadOnlyFields.includes(fieldName) &&
    values.vendorProposalScenarioId &&
    Number.isFinite(Number(value))
  ) {
    return true
  }
  return false
}
function getPrecisionValue(
  fieldInfoPrecision: number | undefined,
  fieldType: string,
) {
  if (fieldInfoPrecision !== undefined && fieldInfoPrecision >= 0) {
    return fieldInfoPrecision
  }
  return getPrecisionDefault(fieldType)
}

function valueChanged(
  fieldName: string,
  initialValues: { [x: string]: any },
  value: string | number,
) {
  return (
    (initialValues[fieldName] || value) && initialValues[fieldName] !== value
  )
}

function isSalesTaxNotFetched(
  fieldName: string,
  value: string | number,
  warningValue: number,
) {
  return (
    fieldName === 'estimatedSalesTaxInPercentage' &&
    Number(value) === warningValue
  )
}

function isSalesTaxNotCalculated(
  fieldName: string,
  values: FTProposalScenarioResponse,
  warningValue: number,
) {
  return (
    fieldName === 'estimatedSalesTax' &&
    Number(values.estimatedSalesTaxInPercentage) === warningValue
  )
}

const ScenarioField = ({
  currencyCode,
  error,
  fieldName,
  handleNumericFieldChange,
  initialValues,
  locale,
  overiddenCalculatedValues,
  scenarioCalculations,
  setFieldValue,
  setUpdatedField,
  site,
  touched,
  value,
  values,
  opportunityId,
  actions,
}: {
  overiddenCalculatedValues: Object
  currencyCode: string
  error: string
  fieldName: string
  handleNumericFieldChange: Function
  initialValues: { [string]: string | number }
  locale: string
  scenarioCalculations: FTCalculations
  setFieldValue: Function
  setUpdatedField: Function
  site: FTProposalSite
  touched: boolean
  value: string | number
  values: FTProposalScenario
  opportunityId: string
  actions: {
    fetchSalesTaxRate: (props: FTFetchSalesTaxRateAction) => null
  }
}) => {
  const {
    fieldType,
    formatPostProcess,
    fullWidth,
    label,
    readOnly,
    precision: fieldInfoPrecision,
    placeholder,
  } = scenarioFieldInfo[fieldName]
  const precision = getPrecisionValue(fieldInfoPrecision, fieldType)
  if (
    siteDependentDisabledFields[fieldName] &&
    siteDependentDisabledFields[fieldName]({ scenario: values, site })
  ) {
    if (value !== 0) {
      setFieldValue(fieldName, 0)
    }
    return (
      <FieldWrapperStyled fullWidth={fullWidth}>
        <ViewInputField
          label={label}
          value={formatScenarioFieldValue({
            currencyCode,
            fieldType,
            formatPostProcess,
            locale,
            value,
            precision,
            fieldName,
          })}
          readOnly
        />
      </FieldWrapperStyled>
    )
  }

  const isCalculated = !!scenarioCalculations[fieldName]

  const FieldValueStatus = () => {
    if (
      valueChanged(fieldName, initialValues, value) &&
      !overiddenCalculatedValues[fieldName]
    ) {
      return (
        <CalculatedEditableFieldChangedIconWrapperStyled>
          <Tippy
            content='This value has been updated because of a change in a related field'
            delay={500}
          >
            <MonitorHeartOutlinedIcon className='calculated-field-icon' />
          </Tippy>
        </CalculatedEditableFieldChangedIconWrapperStyled>
      )
    }
    if (overiddenCalculatedValues[fieldName]) {
      return (
        <CalculatedEditableFieldChangedIconWrapperStyled>
          <Tippy
            content='The field has been updated manually, it will not be recalculated henceforth.'
            delay={500}
          >
            <EditIcon className='edited-field-icon' />
          </Tippy>
        </CalculatedEditableFieldChangedIconWrapperStyled>
      )
    }
    return <></>
  }

  if (readOnly) {
    return (
      <FieldWrapperStyled fullWidth={fullWidth}>
        <ViewInputField
          label={label}
          value={
            isSalesTaxNotCalculated(fieldName, values, salesTaxWarningValue) ?
              'Not Calculated'
            : formatScenarioFieldValue({
                currencyCode,
                fieldType,
                formatPostProcess,
                locale,
                value,
                precision,
                fieldName,
              })
          }
          readOnly
        />
        {!!(isCalculated && valueChanged(fieldName, initialValues, value)) && (
          <CalculatedFieldChangedIconWrapperStyled>
            <Tippy
              content='This value has been updated because of a change in a related field'
              delay={500}
            >
              <MonitorHeartOutlinedIcon className='calculated-field-icon' />
            </Tippy>
          </CalculatedFieldChangedIconWrapperStyled>
        )}
      </FieldWrapperStyled>
    )
  }

  switch (fieldType) {
    case 'string':
      return (
        <FormFieldWidthWrapperStyled fullWidth={fullWidth}>
          <FormField
            error={touched ? error : ''}
            name={fieldName}
            title={renderFieldTitle(
              label,
              !!scenarioFieldInfo[fieldName].required,
            )}
          />
        </FormFieldWidthWrapperStyled>
      )
    case 'textarea':
      return (
        <FormFieldWidthWrapperStyled fullWidth={fullWidth}>
          <FormField
            inputComponent={TextArea}
            error={touched ? error : ''}
            name={fieldName}
            title={renderFieldTitle(
              label,
              !!scenarioFieldInfo[fieldName].required,
            )}
          />
        </FormFieldWidthWrapperStyled>
      )
    case 'boolean':
      return (
        <FormFieldWidthWrapperStyled fullWidth={fullWidth}>
          <FormField
            title={label}
            renderField={() => (
              <ListSelector
                items={YES_NO}
                fieldName={fieldName}
                searchable={false}
                selectedItem={value ? YES_NO[0] : YES_NO[1]}
                unsettable={false}
                updateValue={({ value: updateValue }) => {
                  setUpdatedField(fieldName)
                  setFieldValue(fieldName, updateValue === 'yes')
                }}
              />
            )}
          />
        </FormFieldWidthWrapperStyled>
      )
    case 'enum':
      return (
        <FormFieldWidthWrapperStyled fullWidth={fullWidth}>
          <FormField
            title={renderFieldTitle(
              label,
              !!scenarioFieldInfo[fieldName].required,
            )}
            renderField={() => (
              <ListSelector
                fieldName={fieldName}
                items={scenarioEnumItems[fieldName]}
                searchable={false}
                selectedItem={getScenarioEnumItem({ fieldName, value })}
                unsettable={false}
                updateValue={({ value: updateValue }) => {
                  setUpdatedField(fieldName)
                  setFieldValue(fieldName, updateValue)
                }}
              />
            )}
          />
        </FormFieldWidthWrapperStyled>
      )
    case 'number':
    case 'currency':
    case 'percentage':
      return (
        <FormFieldWidthWrapperStyled fullWidth={fullWidth}>
          <FormField
            name={fieldName}
            title={renderFieldTitle(
              label,
              !!scenarioFieldInfo[fieldName].required,
            )}
            renderField={() =>
              isCalculated ?
                <FieldWrapperStyled>
                  <CalculatedEditableFieldStyled
                    error={touched ? error : ''}
                    id={fieldName}
                    name={fieldName}
                    onChange={handleNumericFieldChange}
                    precision={precision}
                    type='number'
                    value={value}
                    onWheel={(e) => e.currentTarget.blur()}
                    placeholder={placeholder}
                    readOnly={checkIfReadOnly(fieldName, values, value)}
                  />
                  <FieldValueStatus />
                </FieldWrapperStyled>
              : <>
                  <InputNumberWithPrecisionStyled
                    error={touched ? error : ''}
                    id={fieldName}
                    name={fieldName}
                    onChange={handleNumericFieldChange}
                    precision={precision}
                    type={
                      (
                        isSalesTaxNotFetched(
                          fieldName,
                          value,
                          salesTaxWarningValue,
                        )
                      ) ?
                        'text'
                      : 'number'
                    }
                    value={
                      (
                        isSalesTaxNotFetched(
                          fieldName,
                          value,
                          salesTaxWarningValue,
                        )
                      ) ?
                        'Value Not fetched'
                      : value
                    }
                    onWheel={(e) => e.currentTarget.blur()}
                    placeholder={placeholder}
                    isWarning={isSalesTaxNotFetched(
                      fieldName,
                      value,
                      salesTaxWarningValue,
                    )}
                    readOnly={checkIfReadOnly(fieldName, values, value)}
                  />
                  {isSalesTaxNotFetched(
                    fieldName,
                    value,
                    salesTaxWarningValue,
                  ) && (
                    <ButtonInsideInputFieldStyled
                      onClick={() =>
                        actions.fetchSalesTaxRate({ opportunityId })
                      }
                    >
                      Retry
                    </ButtonInsideInputFieldStyled>
                  )}
                </>
            }
          />
        </FormFieldWidthWrapperStyled>
      )
    case 'integer':
      return (
        <FormFieldWidthWrapperStyled fullWidth={fullWidth}>
          <FormField
            name={fieldName}
            title={renderFieldTitle(
              label,
              !!scenarioFieldInfo[fieldName].required,
            )}
            renderField={() =>
              isCalculated ?
                <FieldWrapperStyled>
                  <CalculatedEditableIntegerFieldStyled
                    error={touched ? error : ''}
                    id={fieldName}
                    name={fieldName}
                    onChange={handleNumericFieldChange}
                    precision={precision}
                    type='number'
                    value={value}
                    onWheel={(e) => e.currentTarget.blur()}
                    readOnly={checkIfReadOnly(fieldName, values, value)}
                  />
                  <FieldValueStatus />
                </FieldWrapperStyled>
              : <InputInteger
                  error={touched ? error : ''}
                  id={fieldName}
                  name={fieldName}
                  onChange={handleNumericFieldChange}
                  type='number'
                  value={value}
                  onWheel={(e) => e.currentTarget.blur()}
                  readOnly={checkIfReadOnly(fieldName, values, value)}
                />
            }
          />
        </FormFieldWidthWrapperStyled>
      )
    default:
      return (
        <FormFieldWidthWrapperStyled fullWidth={fullWidth}>
          <ViewInputField
            label={label}
            value={formatScenarioFieldValue({
              currencyCode,
              fieldType,
              locale,
              value,
              precision,
              fieldName,
            })}
            readOnly
          />
        </FormFieldWidthWrapperStyled>
      )
  }
}

const allSections = ['Opportunity Details'].concat(
  Object.keys(scenarioFieldCategories),
)

const RequiredAsteriskStyled = styled.span`
  color: ${colors.red};
`

const ScenarioForm = (props: FTProps) => {
  const {
    actions,
    customerGlobalInputs,
    dirty,
    editMode,
    errors,
    initialValues,
    isValid,
    loading,
    onCancel,
    opportunity,
    scenario,
    setFieldTouched,
    setFieldValue,
    setValues,
    site,
    touched,
    values,
    isClone,
    // areValuesPreFilled,
    source,
    emission,
    recData,
    refetchedSalesTaxRate,
  } = props
  const inputNameRef = React.useRef(null)

  const sectionRefs = allSections.reduce(
    (acc, cur) => ({
      ...acc,
      [cur]: React.useRef(),
    }),
    {},
  )
  const { currencyCode = 'USD' } = opportunity || {}
  const { locale = 'en-US' } = customerGlobalInputs || {}

  const [selectedEditTab, setSelectedEditTab] = React.useState(0)

  const [selectedCreateTab, setSelectedCreateTab] = React.useState(
    'Opportunity Details',
  )

  const [createTabClicked, setCreateTabClicked] = React.useState(false)

  const [updatedField, setUpdatedField] = React.useState('')

  const [overiddenCalculatedValues, setOveriddenCalculatedValues] =
    React.useState({})

  const scenarioCalculations = React.useMemo(
    () => getScenarioCalculations(values, site),
    [values],
  )

  const updatedInitialValues = {
    ...initialValues,
    emissionRate: emission?.egridRate || initialValues.emissionRate,
  }

  const updatedValues = {
    ...values,
    emissionRate: emission?.egridRate || initialValues.emissionRate,
  }

  const identifyEditedFields = () => {
    const enhancedValues = getScenarioEnhancedWithCalculations(
      updatedInitialValues,
      [],
      site,
      source === 'add',
    )
    const isValueChanged = (fieldName: string) => {
      if (source === 'edit' && !skipFieldsToCheckIfEdited.includes(fieldName)) {
        return updatedInitialValues[fieldName] !== enhancedValues[fieldName]
      }
      return false
    }
    return Object.keys(scenarioCalculations).filter(
      (fieldName) =>
        isValueChanged(fieldName) && !scenarioFieldInfo[fieldName]?.readOnly,
    )
  }

  const [editedCalculatedFields, setEditedCalculatedFields] = React.useState(
    identifyEditedFields(),
  )

  let PrimaryFieldsGridColCount = 1
  let FieldsGridColCount = 2

  if (source === 'add') {
    PrimaryFieldsGridColCount = 2
    FieldsGridColCount = 4
  }
  const ViewFieldGrid = ({ items }: { items: Array<Object> }) => (
    <>
      {items.map(({ label, value }) => (
        <ViewInputField label={label} key={label} value={value} readOnly />
      ))}
    </>
  )
  const updateCalculatedFields = () => {
    let newEditedCalculatedFields = editedCalculatedFields
    // If the last updated field is an editable calculated field...
    if (
      updatedField &&
      !!scenarioCalculations[updatedField] &&
      !scenarioFieldInfo[updatedField].readOnly
    ) {
      // Prevents manually overridden calculated fields from being automatically updated.
      newEditedCalculatedFields = [
        ...new Set([...editedCalculatedFields, updatedField]),
      ]
      setEditedCalculatedFields(newEditedCalculatedFields)
    }

    const newValues = getScenarioEnhancedWithCalculations(
      updatedValues,
      newEditedCalculatedFields,
      site,
      source === 'add',
    )

    const newOveriddenCalculatedValues = newEditedCalculatedFields.reduce(
      (acc, cur) => ({
        ...acc,
        [cur]: updatedValues[cur],
      }),
      {},
    )

    setOveriddenCalculatedValues(newOveriddenCalculatedValues)

    setValues({
      ...newValues,
      ...newOveriddenCalculatedValues,
    })
  }

  React.useEffect(() => {
    updateCalculatedFields()
    if (isClone) {
      // $FlowFixMe
      inputNameRef?.current?.querySelector('.scenario-name input')?.select()
    }
  }, [])

  React.useEffect(() => {
    if (updatedField) {
      updateCalculatedFields()
      setUpdatedField('')
    }
  }, [updatedField])

  useEffect(() => {
    // Re-identifying the edited calculated fields when the emission rate is fetched to compare with existing DB values
    setEditedCalculatedFields(identifyEditedFields())
  }, [emission])

  useEffect(() => {
    // Re-updating the calculated fields when the emission rate is fetched and Edited fields are again identified
    updateCalculatedFields()
  }, [editedCalculatedFields])

  React.useEffect(() => {
    if (site && editMode) {
      actions.fetchEmissionRate({
        countryCode: contryCodeByCountryName(
          countryNameLookup,
          site.shippingCountry,
        ),
        postalCode: site.shippingPostalCode,
        latitude: site.latitude,
        longitude: site.longitude,
        state: site.shippingStateCode,
      })
    }
  }, [site, editMode])

  useEffect(() => {
    if (!recData) {
      actions?.fetchRECData()
    }
  }, [recData, actions])

  const handleNumericFieldChange = React.useCallback(
    (event: SyntheticInputEvent<any>) => {
      const {
        target: { name, value },
      } = event
      setUpdatedField(name)
      setFieldTouched(name, true, false)
      setFieldValue(name, getAdjustedFieldValue(name, value))
    },
    [],
  )

  const handleScenarioTabClick = React.useCallback(
    (event: SyntheticInputEvent) => {
      const { target } = event
      const { dataset = {} } = target
      const { tabId = '' } = dataset
      setSelectedCreateTab(tabId)
      setCreateTabClicked(true)
    },
    [],
  )

  const scrollToSelectedSection = React.useCallback(() => {
    // $FlowFixMe
    const currentRef = sectionRefs[selectedCreateTab]
    if (currentRef && currentRef.current) {
      const refPositionY = currentRef.current.getBoundingClientRect().y || 0
      const sectionPositionY = refPositionY + window.scrollY
      window.scroll({
        left: 0,
        top: sectionPositionY - 280,
        behavior: 'smooth',
      })
    }
  }, [sectionRefs, selectedCreateTab])

  React.useEffect(() => {
    if (createTabClicked === true) {
      scrollToSelectedSection()
      setCreateTabClicked(false)
    }
  }, [createTabClicked])

  const getSectionPositions = () => {
    if (editMode) {
      return {}
    }
    let nextPosition = 0
    const positions = {}
    allSections.forEach((fieldCategory) => {
      positions[fieldCategory] = nextPosition
      if (sectionRefs[fieldCategory] && sectionRefs[fieldCategory].current) {
        const element = sectionRefs[fieldCategory].current
        const style = element.currentStyle || window.getComputedStyle(element)
        const height = element.offsetHeight
        const margin =
          parseFloat(style.marginTop) + parseFloat(style.marginBottom)
        nextPosition = nextPosition + height + margin
      }
    })
    return positions
  }

  const handleScroll = () => {
    const sectionPositions = getSectionPositions()
    const windowScrollY = window.scrollY
    const activeSection = allSections.reduce(
      (acc, cur) => (windowScrollY >= sectionPositions[cur] ? cur : acc),
      '',
    )
    setSelectedCreateTab(activeSection)
  }

  const handleScrollDebounced = debounce(handleScroll, 50)

  const handleUndoAction = (event) => {
    if ((event.ctrlKey || event.metaKey) && event.key === 'z') {
      event.preventDefault()
    }
  }

  React.useEffect(() => {
    window.addEventListener('scroll', handleScrollDebounced)

    window.addEventListener('keydown', handleUndoAction)

    // Cleanup
    return () => {
      window.removeEventListener('keydown', handleUndoAction)
    }
  }, [])

  // const getAuditVendorValue = () => {
  //   if (scenario.auditVendor) {
  //     return valueOrDefault(scenario.auditVendor, '--');
  //   }
  //   return valueOrDefault(opportunity.auditVendor, '--');
  // };
  const opportunityDetailItems: Array<Object> = React.useMemo(
    () => [
      { label: 'Site Id', value: site.id },
      { label: 'Address', value: site.shippingStreet },
      { label: 'City', value: site.shippingCity },
      { label: 'State', value: site.shippingState },
      { label: 'Zip', value: site.shippingPostalCode },
      { label: 'Square Footage', value: site.squareFeet },
      { label: 'ECM Type', value: 'Lighting' },
      // { label: 'Audit Vendor', value: getAuditVendorValue() },
      { label: 'Currency', value: currencyCode },
    ],
    [opportunity, scenario, site],
  )

  const renderOpportunityDetailsFields = (isAddForm = false) => (
    <>
      <PrimaryFieldsWrapper col={PrimaryFieldsGridColCount}>
        <ViewInputField
          label='Opportunity ID'
          value={opportunity.redaptiveOpportunityId}
          readOnly
        />
        <div ref={inputNameRef}>
          <FormField
            error={touched.name ? errors.name : ''}
            name='name'
            autoFocus={isClone}
            title={renderFieldTitle(scenarioFieldInfo.name.label, true)}
            className='scenario-name'
          />
        </div>
      </PrimaryFieldsWrapper>
      <FieldGridStyled col={FieldsGridColCount}>
        <ViewFieldGrid items={opportunityDetailItems} />
        <FormField
          error={touched.name ? errors.name : ''}
          name='auditVendor'
          title={renderFieldTitle(scenarioFieldInfo.auditVendor.label, false)}
        />
        <div>
          {scenario?.vendorProposalScenarioId && !isAddForm ?
            <CustomFormFieldStyled>
              <ViewFieldLabelStyled>Vendor Proposal</ViewFieldLabelStyled>
              <DownloadVendorFile
                fileIds={scenario.vendorProposalScenarioId}
                name={scenario.vendorProposalScenarioFileName}
                isFormField
              />
            </CustomFormFieldStyled>
          : <ViewFieldLabelStyled>
              <ViewInputField label='Vendor Proposal' readOnly />
            </ViewFieldLabelStyled>
          }
        </div>
      </FieldGridStyled>
    </>
  )

  const renderScenarioFields = (scenarioCategory: string) => (
    <div col={FieldsGridColCount}>
      {scenarioFieldCategories[scenarioCategory].length > 0 &&
        (Array.isArray(scenarioFieldCategories[scenarioCategory][0]) ?
          scenarioFieldCategories[scenarioCategory].map(
            (fieldGroup: Array<string>) => (
              <FieldGridStyled col={FieldsGridColCount}>
                {fieldGroup.length > 0 &&
                  fieldGroup.map((fieldName: string) => (
                    <ScenarioField
                      currencyCode={currencyCode}
                      error={errors[fieldName]}
                      fieldName={fieldName}
                      handleNumericFieldChange={handleNumericFieldChange}
                      initialValues={updatedInitialValues}
                      key={`${fieldName}ScenarioField`}
                      locale={locale}
                      overiddenCalculatedValues={overiddenCalculatedValues}
                      scenarioCalculations={scenarioCalculations}
                      setFieldValue={setFieldValue}
                      setUpdatedField={setUpdatedField}
                      site={site}
                      touched={touched[fieldName]}
                      value={values[fieldName]}
                      values={values}
                      opportunityId={opportunity.id}
                      actions={actions}
                      refetchedSalesTaxRate={refetchedSalesTaxRate}
                    />
                  ))}
              </FieldGridStyled>
            ),
          )
        : <FieldGridStyled col={FieldsGridColCount}>
            {scenarioFieldCategories[scenarioCategory].map(
              (fieldName: string) => (
                <ScenarioField
                  currencyCode={currencyCode}
                  error={errors[fieldName]}
                  fieldName={fieldName}
                  handleNumericFieldChange={handleNumericFieldChange}
                  initialValues={updatedInitialValues}
                  key={`${fieldName}ScenarioField`}
                  locale={locale}
                  overiddenCalculatedValues={overiddenCalculatedValues}
                  scenarioCalculations={scenarioCalculations}
                  setFieldValue={setFieldValue}
                  setUpdatedField={setUpdatedField}
                  site={site}
                  touched={touched[fieldName]}
                  value={values[fieldName]}
                  values={values}
                  opportunityId={opportunity.id}
                  actions={actions}
                  refetchedSalesTaxRate={refetchedSalesTaxRate}
                />
              ),
            )}
          </FieldGridStyled>)}
    </div>
  )

  const renderEditForm = () => (
    <>
      <EditFormTabsWrapperStyles>
        <ModalScenarioTabsStyles>
          <Tabs
            forceRenderTabPanel
            onSelect={setSelectedEditTab}
            selectedIndex={selectedEditTab}
          >
            <TabList>
              <Tab>
                Opportunity Details
                <TabHighlightStyled />
              </Tab>
              {Object.keys(scenarioFieldCategories).map((scenarioCategory) => (
                <Tab key={`${scenarioCategory}Tab`}>
                  {scenarioCategory}
                  <TabHighlightStyled />
                </Tab>
              ))}
            </TabList>
            <TabPanelsStyled>
              <TabPanel>{renderOpportunityDetailsFields()}</TabPanel>
              {Object.keys(scenarioFieldCategories).map((scenarioCategory) => (
                <TabPanel key={`${scenarioCategory}TabPanel`}>
                  {renderScenarioFields(scenarioCategory)}
                </TabPanel>
              ))}
            </TabPanelsStyled>
          </Tabs>
        </ModalScenarioTabsStyles>
      </EditFormTabsWrapperStyles>
      <ButtonsStyled>
        <CancelButtonStyled type='button' onClick={onCancel}>
          Cancel
        </CancelButtonStyled>
        <SubmitButtonStyled disabled={!dirty || !isValid} primary type='submit'>
          Update Scenario
        </SubmitButtonStyled>
      </ButtonsStyled>
    </>
  )

  const isCategoryValid = React.useCallback(
    (category: string): boolean => {
      if (category === 'Opportunity Details') {
        return !(touched.name && errors.name)
      }

      const categoryFields = scenarioFieldCategories[category]
      if (!categoryFields) {
        return false
      }

      return !categoryFields.some(
        (categoryField) => touched[categoryField] && errors[categoryField],
      )
    },
    [errors],
  )

  const isCategoryCompleted = React.useCallback(
    (category: string): boolean => {
      if (category === 'Opportunity Details') {
        return !!values.name
      }

      const categoryFields = scenarioFieldCategories[category]
      if (!categoryFields) {
        return false
      }

      // For nested category
      let nestedFieldSet = true
      if (categoryFields.length && Array.isArray(categoryFields[0])) {
        categoryFields.forEach((fieldGroup: Array<string>) =>
          fieldGroup.forEach((categoryField) => {
            if (
              scenarioFieldInfo[categoryField].required &&
              !isValueSet(values[categoryField])
            ) {
              nestedFieldSet = false
              return false
            }
            return true
          }),
        )
        return nestedFieldSet
      }

      return !categoryFields.some(
        (categoryField: string) =>
          scenarioFieldInfo[categoryField].required &&
          !isValueSet(values[categoryField]),
      )
    },
    [values],
  )

  const renderCreateForm = () => (
    <CreateFormStyled>
      <CreateFormSubmitButtonStickyStyled>
        <CreateFormSubmitButtonWrapperStyled>
          <SubmitButtonStyled
            disabled={!dirty || !isValid}
            loading={loading}
            primary
            type='submit'
          >
            Add Scenario
          </SubmitButtonStyled>
        </CreateFormSubmitButtonWrapperStyled>
      </CreateFormSubmitButtonStickyStyled>
      <CreateFormTabsWrapperStyled>
        <CreateFormTabsStyled>
          <CreateFormTabStyled
            selected={selectedCreateTab === 'Opportunity Details'}
            valid={isCategoryValid('Opportunity Details')}
          >
            <CreateFormTabInnerStyled
              data-tab-id='Opportunity Details'
              onClick={handleScenarioTabClick}
            >
              <TabCountStyled
                selected={selectedCreateTab === 'Opportunity Details'}
                valid={isCategoryValid('Opportunity Details')}
                completed={isCategoryCompleted('Opportunity Details')}
              >
                <TabCountInnerStyled>1</TabCountInnerStyled>
              </TabCountStyled>
              Opportunity Details
              <TabHighlightStyled />
            </CreateFormTabInnerStyled>
          </CreateFormTabStyled>
          {Object.keys(scenarioFieldCategories).map(
            (scenarioCategory, index) => (
              <CreateFormTabStyled
                key={`${scenarioCategory}Tab`}
                selected={selectedCreateTab === scenarioCategory}
                valid={isCategoryValid(scenarioCategory)}
              >
                <CreateFormTabInnerStyled
                  data-tab-id={scenarioCategory}
                  onClick={handleScenarioTabClick}
                >
                  <TabCountStyled
                    selected={selectedCreateTab === scenarioCategory}
                    valid={isCategoryValid(scenarioCategory)}
                    completed={isCategoryCompleted(scenarioCategory)}
                  >
                    <TabCountInnerStyled>{index + 2}</TabCountInnerStyled>
                  </TabCountStyled>
                  {scenarioCategory}
                  <TabHighlightStyled />
                </CreateFormTabInnerStyled>
              </CreateFormTabStyled>
            ),
          )}
        </CreateFormTabsStyled>
      </CreateFormTabsWrapperStyled>
      <CreateFormBodyStyled>
        <CreateFormSectionStyled
          data-section='Opportunity Details'
          ref={sectionRefs['Opportunity Details']}
        >
          {renderOpportunityDetailsFields(true)}
        </CreateFormSectionStyled>
        {Object.keys(scenarioFieldCategories).map((scenarioCategory) => (
          <CreateFormSectionStyled
            data-section={scenarioCategory}
            key={`${scenarioCategory}TabPanel`}
            ref={sectionRefs[scenarioCategory]}
          >
            {renderScenarioFields(scenarioCategory)}
          </CreateFormSectionStyled>
        ))}
      </CreateFormBodyStyled>
    </CreateFormStyled>
  )
  function checkEmissionPresent() {
    return Boolean(emission?.country || emission?.postalCode)
  }
  return (
    <ScenarioFormStyled>
      <Form>
        {editMode && checkEmissionPresent() && renderEditForm()}
        {!editMode && renderCreateForm()}
      </Form>
    </ScenarioFormStyled>
  )
}

const mapDispatchToProps = (dispatch) => ({
  actions: {
    ...bindActionCreators(scenarioActions, dispatch),
    ...bindActionCreators(modalActions, dispatch),
    ...bindActionCreators(emissionActions, dispatch),
    ...bindActionCreators(recActions, dispatch),
  },
})

const mapStateToProps = (state) => {
  const scenariosEntity = selectProposalScenarioEntity(state)
  const recDataEntity = selectRECDataEntity(state)
  const {
    meta: { salesTaxFromAvalara },
  } = scenariosEntity
  const { recData, meta: recDataLoading } = recDataEntity
  return {
    refetchedSalesTaxRate: salesTaxFromAvalara,
    recData,
    recDataLoading,
  }
}

export const getSalesTaxWarningPopupMsg = () => (
  <div>
    <p>
      The Avalara call to fetch <strong>Estimated Sales Tax %</strong> was not
      successful. Please go back and hit Retry in the respective field to update
      or enter the % manually.
    </p>
    <p>
      You can still create this scenario without the{' '}
      <strong>Estimated Sales Tax%</strong>
    </p>
  </div>
)

export const getWarningPopupBody = () => (
  <div>
    <p>
      Confirm if you want to create this <br />
      scenario without the Estimated Sales Tax %?
    </p>
  </div>
)

export default withFormik({
  validationSchema,

  handleSubmit(values, { props }: { props: FTProps }) {
    const { onSubmit, actions, source } = props
    const { estimatedSalesTaxInPercentage } = values
    if (
      estimatedSalesTaxInPercentage === salesTaxWarningValue &&
      source === 'add'
    ) {
      actions.showModalAddScenarioWithoutSalesTax({
        closeModal: actions.hideModal,
        warningMsg: getSalesTaxWarningPopupMsg(),
        body: getWarningPopupBody(),
        onSubmit: () => {
          actions.hideModal()
          onSubmit(values)
        },
      })
    } else onSubmit(values)
  },
  mapDispatchToProps,
  mapStateToProps,
  mapPropsToValues: ({ opportunity, scenario, site }: FTProps) => ({
    ...defaultScenario,
    ...Object.keys(scenario).reduce(
      (acc, cur) => ({
        ...acc,
        [cur]:
          (
            siteDependentDisabledFields[cur] &&
            siteDependentDisabledFields[cur]({ scenario, site })
          ) ?
            0
          : valueSetOrDefault(scenario[cur], defaultFieldsMapping[cur]),
      }),
      {},
    ),
    opportunityId: opportunity.id,
    salesforceSiteId: opportunity.salesforceSiteId,
  }),
})(ScenarioForm)
