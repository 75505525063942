import Tippy from '@tippyjs/react'
import { errorMap } from '../../helpers/utils'
import { InfoCircleOutlineIconStyled } from '../../styles'

const renderErrorToolTip = (error: null | keyof typeof errorMap) => {
  if (!error) return null
  return (
    <Tippy content={errorMap[error] || errorMap[400]} delay={500}>
      <InfoCircleOutlineIconStyled />
    </Tippy>
  )
}

export default renderErrorToolTip
