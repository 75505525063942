import { CellProps } from 'react-table'
import { ReactElement } from 'react'
import { TSEnergyStarSubmissions } from '../../../../ducks/energyStarIntegration/types'
import {
  columnIds,
  DataSourceValueMap,
  ResourceValueMap,
  StatusColorMap,
  statusEnum,
  StatusViewValueMap,
} from '../../helpers/utils'
import renderErrorToolTip from '../helpers/renderErrorTooltip'
import {
  DataCaptureStatusStatusIndicatorStyled,
  StatusColor,
  TableCellColumn,
  TableCellRow,
} from '../../styles'
import getColorForDelta from '../helpers/getColorForDelta'
import { formatHealthStatus, isValueSet } from '../../../../utils'
import getColorForDataAvailability from '../helpers/getColorForDataAvailabilty'
import RenderMeterListCell from './RenderMeterListCell'
import getFormattedCaseStartDate from '../helpers/getFormattedCaseStartDate'

const RenderCustomCell = ({
  value,
  row: { original },
  column: { id },
}: CellProps<TSEnergyStarSubmissions>) => {
  if (value === null) return '-'
  if (id === columnIds.Status) {
    let statusViewValue: string | ReactElement =
      StatusViewValueMap[value as keyof typeof StatusViewValueMap]
    const color = StatusColorMap[value as keyof typeof StatusColorMap]

    if (value === statusEnum.FAILED) {
      statusViewValue = (
        <>
          {StatusViewValueMap.FAILED}
          {original.errorStatusCode !== 201 &&
            renderErrorToolTip(original.errorStatusCode)}
        </>
      )
    }

    return <StatusColor color={color}>{statusViewValue}</StatusColor>
  }

  if (id === columnIds.ResourceType) {
    return ResourceValueMap[value] ?? value
  }

  if (id === columnIds.DataSource) {
    return DataSourceValueMap[value] ?? value
  }

  if (id === columnIds.DeltaEnergyConsumption) {
    return (
      <>
        <DataCaptureStatusStatusIndicatorStyled
          color={getColorForDelta(value)}
          type='line'
        />
        {typeof value !== 'undefined' && value != null ?
          `${Math.round(value)}%`
        : '-'}
      </>
    )
  }

  if (id === columnIds.DataAvailability) {
    return (
      <>
        <DataCaptureStatusStatusIndicatorStyled
          color={getColorForDataAvailability(
            isValueSet(value) ? Math.floor(value * 100) : 0,
          )}
          type='line'
        />
        {formatHealthStatus(value)}
      </>
    )
  }

  if (id === columnIds.MeterList) {
    if (!value || value?.length === 0) return '-'
    return (
      <TableCellColumn>
        {value?.map((item: string[]) => (
          <TableCellRow>
            <RenderMeterListCell list={item} />
          </TableCellRow>
        ))}
      </TableCellColumn>
    )
  }

  if (
    [
      columnIds.CaseId,
      columnIds.CaseStatus,
      columnIds.CaseStartDate,
      columnIds.CaseAssignedTo,
      columnIds.OpportunityId,
    ].includes(id)
  ) {
    return (
      <TableCellColumn>
        {value?.map((item: string) => (
          <TableCellRow>
            {id === columnIds.CaseStartDate ?
              getFormattedCaseStartDate(item)
            : item}
          </TableCellRow>
        ))}
      </TableCellColumn>
    )
  }

  if (id === columnIds.PreviousMonthEnergyConsumption && !value) {
    return <>-</>
  }

  return value ?? '-'
}

export default RenderCustomCell
