import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import OrganizationGraph from '../../components/HighCharts/OrganizationGraph'
import {
  actions as panelActions,
  selectPanelListEntity,
} from '../../ducks/panels'
import { FTAllPanelEntities } from '../../ducks/types'
import { FTRouterMatch } from '../../types'

type FTProps = {
  match: FTRouterMatch
}

type PanelData = {
  from: string
  to: string
}

export const mapPanelToData = (
  panelListEntity: FTAllPanelEntities['allItems'],
): PanelData[] => {
  const panelData: PanelData[] = []

  panelListEntity.forEach((panel) => {
    if (panel.parentPanelId) {
      panelData.push({
        from: panel.parentPanelName,
        to: panel.name,
      })
    } else {
      panelData.push({
        from: panel.name,
        to: panel.name,
      })
    }
  })
  return panelData
}

export const mapPanelToChartNode = (
  panelListEntity: FTAllPanelEntities['allItems'],
) =>
  panelListEntity.map((panel) => ({
    id: panel.id,
    parent: panel.parentPanelId || null,
    name: panel.name,
    panelLevel: panel.panelLevel,
    parentPanelName: panel.parentPanelName,
  }))

export const mapPanelToLevels = (
  panelListEntity: FTAllPanelEntities['allItems'],
) => {
  const uniqueLevels = [
    ...new Set(panelListEntity.map((panel) => panel.panelLevel)),
  ]
  const panelLevels = uniqueLevels.map((_, index) => ({
    level: index,
  }))
  return panelLevels
}

const PanelHierarchyChart = (props: FTProps) => {
  const {
    match: {
      params: { siteId },
    },
  } = props

  const panelList = useSelector(selectPanelListEntity)
  const dispatch = useDispatch()
  const panelData = mapPanelToData(panelList.allItems)
  const panelLevels = mapPanelToLevels(panelList.allItems)
  const panelNodes = mapPanelToChartNode(panelList.allItems)

  React.useEffect(() => {
    dispatch(
      panelActions.fetchAllPanels({
        siteId,
      }),
    )
  }, [siteId, dispatch])

  return (
    <OrganizationGraph
      data={panelData}
      levels={panelLevels}
      nodes={panelNodes}
      title='Panel Hierarchy'
      exportFilename='Panel Hierarchy'
      exportOptions={[
        'viewFullscreen',
        'downloadPNG',
        'downloadJPEG',
        'downloadPDF',
      ]}
    />
  )
}

export default PanelHierarchyChart
