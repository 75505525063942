import Highcharts, { Options } from 'highcharts'
import HighchartsReact, {
  HighchartsReactProps,
} from 'highcharts-react-official'
import Accessibility from 'highcharts/modules/accessibility'
import Exporting from 'highcharts/modules/exporting'
import HighchartsSankey from 'highcharts/modules/sankey'

Exporting(Highcharts)
HighchartsSankey(Highcharts)
Accessibility(Highcharts)

interface HighchartsComponentProps {
  options: Options
}

const HighchartsComponent = ({
  options,
  ...props
}: HighchartsComponentProps & HighchartsReactProps) => {
  return (
    <HighchartsReact highcharts={Highcharts} options={options} {...props} />
  )
}

export default HighchartsComponent
