import styled from 'styled-components'
import { Label } from '../VerticalTable/Base'
import VerifiedIcon from '../Icons/VerifiedIcon'
import NotVerifiedIcon from '../Icons/NotVerifiedIcon'

export const InlineVerifiedIcon = styled(VerifiedIcon)`
  font-size: 16px;
  vertical-align: text-top;
`
export const InlineNotVerifiedIcon = styled(NotVerifiedIcon)`
  font-size: 16px;
  vertical-align: text-top;
`
export const HeaderTablesStyles = styled.div`
  display: flex;

  > * {
    width: 50%;

    &:first-child {
      padding-right: 20px;
    }
  }

  ${Label} {
    float: none;
    min-width: 210px;

    @media (min-width: 1276px) {
      float: left;
    }
  }
`
export const GasFirmwareRow = styled.div`
  display: flex;
`
