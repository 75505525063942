import styled from 'styled-components'
import Checkbox from '../Checkbox'
import ReportProblemIcon from '../Icons/ReportProblemIcon'

export const PlainCellStyles = styled.div`
  padding: 0 10px;
`
export const ExpandedMeterChannelStyles = styled(PlainCellStyles)`
  font-size: 16px;
  padding: 0 10px 0 20px;
`
export const ExpandedMeterChannelLabelStyles = styled.span`
  display: inline-block;
  font-weight: 600;
  padding-right: 10px;
`
export const TableStyles = styled.div`
  .ReactTable {
    .rt-tbody .rt-tr-group.rt-tr-group-expanded {
      margin: 12px 0;

      &:first-child {
        margin-top: 0;
      }

      + .rt-tr-group-expanded {
        margin-top: 0;
      }
    }

    &.-highlight .rt-tbody {
      .rt-tr-group-expanded {
        > .rt-tr,
        > .rt-tr:not(.-padRow):hover {
          background: #f7f7f7;
        }
      }
    }
  }

  .rt-tr-group-expanded {
    > .rt-tr > .rt-td {
      align-items: center;
      display: flex;
      height: 55px;
      overflow: visible;

      &.rt-expandable {
        justify-content: center;
      }
    }
  }

  ${Checkbox} {
    &.rc-checkbox-checked .rc-checkbox-inner:after {
      border-color: #6c6d6e;
    }
  }
`

export const ReportProblemIconStyled = styled(ReportProblemIcon)`
  font-size: 18px;
  margin-right: 2px;
  position: relative;
  top: 4px;
`

export const CTOrientationCellStyles = styled.span<{ warning: boolean }>`
  color: ${({ warning }) => (warning ? '#C70D08' : 'inherit')};
`
