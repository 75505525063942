import styled from 'styled-components'
import { colors } from '../../../utils/themes'
import {
  DropzoneStyles,
  FilePathStyled,
  UploadAssetRecordsListStyled,
} from '../../ToolsAndReports/LightingAsBuiltsManagerPage'
import { ThInnerStyled } from '../../../components/RedaptiveReactTable7'

export const ModalHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 4px;
`

export const ISRFileDropZone = styled(DropzoneStyles)<{ isError: boolean }>`
  border-color: ${({ isError }: { isError: boolean }) => isError && colors.red};
`
export const FileNameStyled = styled(FilePathStyled)<{ isError: boolean }>`
  color: ${({ isError }: { isError: boolean }) => isError && colors.red};
`
export const UploadAssetListItems = styled(UploadAssetRecordsListStyled)`
  padding-left: 20px;
`

export const modalCustomStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(94, 94, 94, 0.75)',
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    margin: '1rem',
    width: '40rem',
    height: '24rem',
    transform: 'translate(-50%, -50%)',
    backgroundColor: colors.gray5,
  },
}

export const ErrorMessageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${colors.red};
`
export const ErrorText = styled.div`
  font-family: 'Avenir Next';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  padding-left: 4px;
`

export const StatusDisplayText = styled.div`
  color: ${({ statusColor }) => statusColor};
  font-family: 'Public Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.28px;
  padding-right: 8px;
  z-index: 0;
`
export const UploadTimeDisplayText = styled.div`
  font-family: 'Public Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.28px;
  padding-left: 8px;
  z-index: 0;
`

export const TooltipContainer = styled.div`
  position: relative;
  display: inline-block;
`

export const TooltipText = styled.div`
  visibility: ${({ isHovered }) => (isHovered ? 'visible' : 'hidden')};
  background-color: ${colors.blue5};
  color: ${colors.white};
  text-align: center;
  border-radius: 5px;
  padding: 8px;
  position: absolute;
  z-index: 1;
  bottom: 125%; /* Position the tooltip above the icon */
  left: 50%;
  transform: translateX(-50%);
  width: 200px;

  /* Tooltip arrow */
  &:after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: ${colors.blue5} transparent transparent transparent;
  }
`

export const TableWrapper = styled.div`
  #fileUploadStatus {
    ${ThInnerStyled} {
      position: relative;
    }
  }
`

export const InfoIcon = styled.div`
  cursor: pointer;
  width: 16;
`

export const LinkStyled = styled.div`
  color: #337ab7;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
  font-family: Public Sans;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
`
