import axios from 'axios'
import { takeLatest, put, call } from 'redux-saga/effects'
import { consoleApiUrl, defaultHeaders } from '../../../api'
import { handleAxiosError } from '../../../api/utils'

// Types
export type TSISRDataItemType = {
  opportunityId:string;
  s3FileId:string;
  fileUploadStatus:string;
  billableCircuitsCount:number;
  uploadedBy:string;
  uploadedDate:string;
}

export type TSISRDataState={
  isrData: Array<TSISRDataItemType>;
  isrDataMeta: {loading: boolean, error: string | null};
}
export type TSISRDataStateRoot = {
  isrData: TSISRDataState;
};

// Action Types
export const types = {
  FETCH_ISR_DATA_REQUEST: 'FETCH_ISR_DATA_REQUEST',
  FETCH_ISR_DATA_SUCCESS: 'FETCH_ISR_DATA_SUCCESS',
  FETCH_ISR_DATA_FAILURE: 'FETCH_ISR_DATA_FAILURE',
}

type FetchISRDataRequestAction = { type: typeof types.FETCH_ISR_DATA_REQUEST };
type FetchISRDataSuccessAction = { type: typeof types.FETCH_ISR_DATA_SUCCESS, payload: Array<TSISRDataItemType> };
type FetchISRDataFailureAction = { type: typeof types.FETCH_ISR_DATA_FAILURE, payload: string };

type ISRDataActionTypes = FetchISRDataRequestAction | FetchISRDataSuccessAction | FetchISRDataFailureAction;

// Selectors
export const selectISRData = (state: TSISRDataStateRoot): TSISRDataState => state.isrData;

// Initial State
export const initialState: TSISRDataState = {
  isrDataMeta: {loading: false, error: null},
  isrData: [] as Array<TSISRDataItemType>,
};

// Reducer
const isrDataReducer = (state: TSISRDataState = initialState, action: ISRDataActionTypes): TSISRDataState => {
  switch (action.type) {
    case types.FETCH_ISR_DATA_REQUEST:
      return { ...state, isrDataMeta: {loading: true, error: null} };

    case types.FETCH_ISR_DATA_SUCCESS:
      return { ...state, isrDataMeta: {loading: false, error: null}, isrData: action.payload };

    case types.FETCH_ISR_DATA_FAILURE:
      return { ...state, isrDataMeta: {loading: false, error: action.payload} };

    default:
      return state;
  }
}

// Action Creators
export const actions = {
  fetchISRData: () => ({
    type: types.FETCH_ISR_DATA_REQUEST,
  }),
  fetchISRDataSuccess: (data: Array<TSISRDataItemType>) => ({
    type: types.FETCH_ISR_DATA_SUCCESS,
    payload: data,
  }),
  fetchISRDataFailure: (error: string) => ({
    type: types.FETCH_ISR_DATA_FAILURE,
    payload: error,
  }),
}

// API Call
export const API = {
  fetchISRData: async () => {
    try {
      const { data } = await axios.get(`${consoleApiUrl()}/billing/isr-processing/meta-data-records`, { headers: defaultHeaders() });
      return data;
    } catch (error) {
      throw handleAxiosError(error);
    }
  },
};
//Download from s3 id method
export const onDownload = (fieldId:string) => {
  const url = `${consoleApiUrl()}/files/download/generate-url`
  const body = {
    fileIds: [fieldId],
  }
  axios
    .post(url, body, {
      headers: defaultHeaders(),
    })
    .then(({ data }) => {
      const { preSignedUrl } = data?.[0] || {}
      window.location.assign(preSignedUrl)
    })
    .catch(handleAxiosError)
}
// Saga
function* fetchISRDataSaga() {
  try {
    const data = yield call(API.fetchISRData);
    yield put(actions.fetchISRDataSuccess(data));
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message || 'Failed to fetch ISR data';
    yield put(actions.fetchISRDataFailure(errorMessage));
  }
}

export const sagas = [
  takeLatest(types.FETCH_ISR_DATA_REQUEST, fetchISRDataSaga),
];

export default isrDataReducer;
